import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import { View } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { Grid, Row, Col } from '../Components/Grid';
import { isRequired, getPersonId, hasDocument } from '../Lib/Utils';
import ApplicationActions, {
  checkRentalProgram
} from '../Redux/ApplicationRedux';

import ErrorMessage from '../Components/ErrorMessage';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import Footer from '../Components/Footer';
import { getDocumentType } from '../Redux/DocumentRedux';

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE_APPLICANT = DATA_STRUCTURE.applicant;
const BASE = 'isPrimaryResidenceCode';
const DOCUMENT_TYPE = 'proofOfPrimaryResidence';

export const PrimaryResidenceScreenComplete = (application, documents) => {
  const applicantPersonId = getPersonId(application, BASE_APPLICANT);

  const isRentalProgram = checkRentalProgram(application);

  if (R.path(['isPrimaryResidenceCode'], application) === YES_OPTION) {
    if (isRentalProgram) {
      return true;
    } else {
      const documentType = getDocumentType(application, DOCUMENT_TYPE);
      return hasDocument(documents, applicantPersonId, documentType.code);
    }
  } else if (R.path(['isPrimaryResidenceCode'], application) === NO_OPTION) {
    return true;
  } else {
    return false;
  }
};

class PrimaryResidenceScreen extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    const isRentalProgram = checkRentalProgram(application);

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      console.log('returning', BASE, application[BASE_DAMAGED_ADDRESS][BASE]);
      if (errorText) {
        this.setState({ errorText });
      } else if (application[BASE] === YES_OPTION) {
        if (isRentalProgram) {
          history.push('InsuranceScreen');
        } else {
          history.push('PrimaryResidenceProofScreen');
        }
      } else if (application[BASE] === NO_OPTION) {
        if (isRentalProgram) {
          history.push('InsuranceScreen');
        } else {
          history.push('FloodWaterEnterHomeScreen');
        }
      } else {
        this.setState({ errorText: 'Error saving application' });
      }
    }
  }

  render() {
    const {
      initilized,
      handleSubmit,
      errorText,
      fetching,
      isValid
    } = this.props;

    if (!initilized) return null;

    const disabled = false;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.PrimaryResidence"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="DamagedAddress.PrimaryResidenceDescription"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <ErrorMessage errorText={errorText} />

                <FormGroupRadio
                  field={BASE}
                  optionsKey="yes_no"
                  reverse
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  disabled={!isValid || fetching}
                  onPress={handleSubmit}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

PrimaryResidenceScreen = withFormik({
  mapPropsToValues: props => {
    const { application } = props;

    return application;
  },
  isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;
    // console.log('updatingApplication', values);
    updateApplication(values);
    setStatus('updatingApplication');
  }
})(PrimaryResidenceScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error },
  persist: { options, application }
}) => ({
  options,
  initilized,
  fetching,
  errorText: error,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryResidenceScreen);
