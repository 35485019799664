import React, { Component } from "react";
import * as R from "ramda";
import { TouchableOpacity, View, Text } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withFormik, Field } from "formik";
import * as yup from "yup";

// import Icon from 'react-native-vector-icons/dist/MaterialIcons';

import { Grid, Row, Col } from "../Components/Grid";

import FormattedMessage from "../Components/FormattedMessage";
import FormGroupText from "../Components/FormGroupText";
import FormGroupSelect from "../Components/FormGroupSelect";
import FormGroupRadio from "../Components/FormGroupRadio";
import FormGroupCheckbox from "../Components/FormGroupCheckbox";
import FullButton from "../Components/FullButton";
import AddressForm from "../Components/AddressForm";

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  TRUE_OPTION,
  FALSE_OPTION,
  MOBILE_HOME_CODE,
} from "../Config/ApplicationConfig";
import {
  isInteger,
  getPersonId,
  hasDocument,
  phoneFormat,
  isPhoneNumber,
} from "../Lib/Utils";

// Styles
import { Fonts, Colors, Metrics } from "../Themes";
import styles from "./Styles/ScreenStyles";
import formStyles from "../Components/Styles/FormStyles";

import { isRequired, isZipcode, isPastYear, zipcodeFormat } from "../Lib/Utils";

import ApplicationActions, {
  checkRentalProgram,
} from "../Redux/ApplicationRedux";

import DATA_STRUCTURE from "../Config/DataStructureConfig";
import DOCUMENT_TYPES from "../Config/DocumentTypesConfig";

import Footer from "../Components/Footer";
import SelectUploadDocumentType from "../Components/SelectUploadDocumentType";

// TODO: Update Dcoument Type
const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfIdentification.code;

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE_CONTACT = DATA_STRUCTURE.contact;
const APPLICANT_BASE = DATA_STRUCTURE.applicant;

export const RentalInformationScreenComplete = (application, documents) => {
  return (
    R.pathOr("N/A", [BASE_DAMAGED_ADDRESS, "propertyTypeCode"], application) !==
    "N/A"
  );
};

class RentalInformationScreen extends Component {
  static navigationOptions = {
    title: I18n.t("menu.RentalInformation"),
    drawerLabel: I18n.t("menu.RentalInformation"),
  };

  initilizedForm = false;
  updatingApplication = false;

  componentDidMount() {
    const { initilized, currentForm } = this.props;

    if (initilized && R.path(["values"], currentForm) && !this.formInitilized) {
      this.initilizeForm(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      application,
      isSubmitting,
      fetching,
      errorText,
      status,
      history,
      setStatus,
      setSubmitting,
    } = nextProps;

    const isRentalProgram = checkRentalProgram(application);

    if (isSubmitting && status === "updatingApplication" && !fetching) {
      setStatus("");
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        if (isRentalProgram) {
          history.push("/TenantsScreen");
        } else {
          history.push("/LienOnPropertyScreen");
        }
      }
    }
  }

  render() {
    const {
      initilized,
      handleSubmit,
      options,
      fetching,
      isValid,
      isSubmitting,
      dirty,
      values,
      errors,
      history,
      application,
    } = this.props;

    if (!initilized) return null;

    const copyCheckbox =
      values[BASE_DAMAGED_ADDRESS].copyCheckbox === TRUE_OPTION;

    const singleFamilyHomeOptions = options.is_single_family_home;
    const propertyTypeOptions = options.property_type;

    const disabled = isSubmitting;
    const isRentalProgram = checkRentalProgram(application);

    const isUnderConstruction = R.path(
      [BASE_DAMAGED_ADDRESS, "isUnderConstruction"],
      values
    );
    const haveProofOfDamageCode = R.path(["haveProofOfDamageCode"], values);
    const didPropertySufferDamageFromEventCode = R.path(
      ["didPropertySufferDamageFromEventCode"],
      values
    );
    const rentalWereTenantsDisplacedAfterStormCode = R.path(
      ["rentalWereTenantsDisplacedAfterStormCode"],
      values
    );
    const rentalWereUnitsHUDAssistedCode = R.path(
      ["rentalWereUnitsHUDAssistedCode"],
      values
    );

    // console.log(isValid, JSON.stringify(errors, null, 2));

    // console.log(JSON.stringify(values[BASE_DAMAGED_ADDRESS], null, 2));

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="menu.RentalInformation"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="DamagedAddress.subtitle"
                  style={Fonts.style.subtitle}
                />
                <Text />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={(ref) => {
                    this._rentalAvgTermOfLeasePerUnit = ref;
                  }}
                  field={`rentalAvgTermOfLeasePerUnit`}
                  labelId="DamagedAddress.rentalAvgTermOfLeasePerUnit"
                  placeholderId="DamagedAddress.rentalAvgTermOfLeasePerUnit_placeholder"
                  returnKeyType="next"
                  onSubmitEditing={(e) => {
                    this._rentalAvgRentPerUnit.focus();
                  }}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={(ref) => {
                    this._rentalAvgRentPerUnit = ref;
                  }}
                  field={`rentalAvgRentPerUnit`}
                  labelId="DamagedAddress.rentalAvgRentPerUnit"
                  placeholderId="DamagedAddress.rentalAvgRentPerUnit_placeholder"
                  returnKeyType="next"
                  disabled={disabled}
                />
              </Col>
            </Row>

            {/* <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`rentalWereUnitsHUDAssistedCode`}
                  labelId="DamagedAddress.rentalWereUnitsHUDAssistedCode"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            {rentalWereUnitsHUDAssistedCode === YES_OPTION && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={(ref) => {
                      this._rentalHUDProgram = ref;
                    }}
                    field={`rentalHUDProgram`}
                    labelId="DamagedAddress.rentalHUDProgram"
                    placeholderId="DamagedAddress.rentalHUDProgram_placeholder"
                    returnKeyType="next"
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )} */}

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`rentalWereTenantsDisplacedAfterStormCode`}
                  labelId="DamagedAddress.rentalWereTenantsDisplacedAfterStormCode"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            {rentalWereTenantsDisplacedAfterStormCode === YES_OPTION && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={(ref) => {
                      this._rentalNumTenantsDisplacedAfterStorm = ref;
                    }}
                    field={`rentalNumTenantsDisplacedAfterStorm`}
                    labelId="DamagedAddress.rentalNumTenantsDisplacedAfterStorm"
                    placeholderId="DamagedAddress.number_placeholder"
                    returnKeyType="next"
                    required
                    keyboardType="numeric"
                    validate={isInteger}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`rentalAreDamagedUnitsOccupiedNowCode`}
                  labelId="DamagedAddress.rentalAreDamagedUnitsOccupiedNowCode"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t("form.continueNextStep")}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   damagedAddress: yup.object().shape({
//     address1: yup.string().required('error.required'),
//     city: yup.string().required('error.required'),
//     stateCode: yup.string().required('error.required'),
//     zip: yup.string().required('error.required'),
//     singleFamilyHome: yup.string().required('error.required'),
//     repairsMadeCode: yup.string().required('error.required')
//   })
// });

RentalInformationScreen = withFormik({
  mapPropsToValues: (props) => {
    const { application, options } = props;

    const initialApplication = JSON.parse(JSON.stringify(application));
    if (!initialApplication[BASE_DAMAGED_ADDRESS]) {
      initialApplication[BASE_DAMAGED_ADDRESS] = {};
    }
    if (!initialApplication[BASE_DAMAGED_ADDRESS].stateCode) {
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode = DEFAULT_STATE_CODE;
    }

    console.log(
      "setting initialApplication",
      JSON.stringify(initialApplication, null, 2)
    );
    return initialApplication;
  },
  isInitialValid: () => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication, options } = props;

    const newValues = JSON.parse(JSON.stringify(values));

    console.log("updateApplication", newValues);
    updateApplication(newValues);
    setStatus("updatingApplication");
  },
})(RentalInformationScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, errorText, data },
  persist: { options, application },
}) => ({
  options,
  initilized,
  fetching,
  errorText,
  data,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalInformationScreen);
