import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import { View } from 'react-native';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import { RENTAL_PROGRAM_CODE } from '../Config/ApplicationConfig';

import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';
import Footer from '../Components/Footer';

export default class WhyDontIQualifyScreen extends Component {
  render() {
    const {
      initilized,
      invalid,
      screeningQuestions,
      history,
      location: { search }
    } = this.props;
    // if (!initilized) return null;
    // const isRentalProgram = application.programCode.toString() === RENTAL_PROGRAM_CODE.toString();

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="WhyDontIQualify.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={'WhyDontIQualify.subtitle'}
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('buttons.BackToPreviousScreen')}
                  onPress={() => history.push('/YouDontQualifyScreen' + search)}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}
