// @flow

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as R from 'ramda';

import {
  parseProgramCodesJson,
  parsePickerJson,
  parseDocTypesJson,
  parseScreeningQuestionsJson
} from './OptionsRedux';

import { DEFAULT_LANGUAGE, PROGRAM_CODE } from '../Config/ApplicationConfig';

import DefaultProgramCodesEn from './DefaultProgramCodesEn.json';
import DefaultProgramCodesEs from './DefaultProgramCodesEs.json';

import DefaultPicker_5_En from './DefaultPicker_5_En.json';
import DefaultPicker_5_Es from './DefaultPicker_5_Es.json';
import DefaultDocTypes_5_En from './DefaultDocTypes_5_En.json';
import DefaultDocTypes_5_Es from './DefaultDocTypes_5_Es.json';
import DefaultScreeningQuestions_5_En from './DefaultScreeningQuestions_5_En.json';
import DefaultScreeningQuestions_5_Es from './DefaultScreeningQuestions_5_Es.json';

import DefaultPicker_6_En from './DefaultPicker_6_En.json';
import DefaultPicker_6_Es from './DefaultPicker_6_Es.json';
import DefaultDocTypes_6_En from './DefaultDocTypes_6_En.json';
import DefaultDocTypes_6_Es from './DefaultDocTypes_6_Es.json';
import DefaultScreeningQuestions_6_En from './DefaultScreeningQuestions_6_En.json';
import DefaultScreeningQuestions_6_Es from './DefaultScreeningQuestions_6_Es.json';

const languageCode = DEFAULT_LANGUAGE;
const allProgramCodes = parseProgramCodesJson(
  DefaultProgramCodesEn,
  DefaultProgramCodesEs
);
const allOptions = {};
allOptions[5] = parsePickerJson(DefaultPicker_5_En, DefaultPicker_5_Es);
allOptions[6] = parsePickerJson(DefaultPicker_6_En, DefaultPicker_6_Es);
const allDocTypes = {};
allDocTypes[5] = parseDocTypesJson(DefaultDocTypes_5_En, DefaultDocTypes_5_Es);
allDocTypes[6] = parseDocTypesJson(DefaultDocTypes_6_En, DefaultDocTypes_6_Es);
const allScreeningQuestions = {};
allScreeningQuestions[5] = parseScreeningQuestionsJson(
  DefaultScreeningQuestions_5_En,
  DefaultScreeningQuestions_5_Es
);
allScreeningQuestions[6] = parseScreeningQuestionsJson(
  DefaultScreeningQuestions_6_En,
  DefaultScreeningQuestions_6_Es
);

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  PersistSetApplications: ['applications'],
  PersistSetApplication: ['application'],
  PersistSetAccount: ['account'],
  PersistSetLocale: ['locale'],
  PersistSetOfflineDocuments: ['offlineDocuments'],

  PersistSetKiosk: ['isKiosk'],
  PersistSetToken: ['tokenId', 'token'],
  PersistSetScreen: ['screen'],
  PersistSetTorch: ['torch'],
  PersistSetProgramCode: ['programCode'],
  PersistSetApplicationId: ['applicationId'],

  PersistSetAllProgramCodes: ['allProgramCodes'],
  PersistSetAllOptions: ['allOptions'],
  PersistSetAllDocTypes: ['allDocTypes'],
  PersistSetAllScreeningQuestions: ['allScreeningQuestions'],

  PersistSetProgramCodes: ['programCodes'],
  PersistSetOptions: ['options'],
  PersistSetDocTypes: ['docTypes'],
  PersistSetScreeningQuestions: ['screeningQuestions'],

  PersistSetLogs: ['logs'],

  PersistReset: null
});

export const PersistTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  applications: {},
  application: null,
  isKiosk: false,
  locale: null,
  account: null,
  screen: null,
  torch: false,
  token: null,
  tokenId: null,
  programCode: null, // This needs to be saved before we are logged in or have an application.
  applicationId: null,
  options: allOptions[PROGRAM_CODE][languageCode],
  allOptions,
  programCodes: allProgramCodes[languageCode],
  allProgramCodes,
  docTypes: allDocTypes[PROGRAM_CODE][languageCode],
  allDocTypes,
  screeningQuestions: allScreeningQuestions[PROGRAM_CODE][languageCode],
  allScreeningQuestions,
  offlineDocuments: {},
  logs: {}
});

/* ------------- Reducers ------------- */

export const setApplications = (state, { applications }) =>
  state.merge({ applications });
export const setApplication = (state, { application }) => {
  if (R.path(['id'], application)) {
    return state
      .merge({ application, applicationId: R.path(['id'], application) })
      .setIn(['applications', application.id], application);
  }
  return state.merge({
    application,
    applicationId: R.path(['id'], application)
  });
};

export const setAccount = (state, { account }) => state.merge({ account });
export const setKiosk = (state, { isKiosk }) => state.merge({ isKiosk });

export const setOfflineDocuments = (state, { offlineDocuments }) =>
  state.merge({ offlineDocuments });

export const setToken = (state, { tokenId, token }) =>
  state.merge({ tokenId, token });
export const setLocale = (state, { locale }) => state.merge({ locale });
export const setScreen = (state, { screen }) => state.merge({ screen });
export const setTorch = (state, { torch }) => state.merge({ torch });

export const setApplicationId = (state, { applicationId }) =>
  state.merge({ applicationId });

export const setAllProgramCodes = (state, { allProgramCodes }) =>
  state.merge({ allProgramCodes });
export const setAllOptions = (state, { allOptions }) =>
  state.merge({ allOptions });
export const setAllDocTypes = (state, { allDocTypes }) =>
  state.merge({ allDocTypes });
export const setAllScreeningQuestions = (state, { allScreeningQuestions }) =>
  state.merge({ allScreeningQuestions });

export const setProgramCodes = (state, { programCodes }) =>
  state.merge({ programCodes });
export const setOptions = (state, { options }) => state.merge({ options });
export const setDocTypes = (state, { docTypes }) => state.merge({ docTypes });
export const setScreeningQuestions = (state, { screeningQuestions }) =>
  state.merge({ screeningQuestions });

export const setLogs = (state, { logs }) => state.merge({ logs });

// Save Applications State for Kiosk Mode
export const reset = state =>
  INITIAL_STATE.merge({
    applications: state.applications || {},
    offlineDocuments: state.offlineDocuments || {},
    logs: state.logs || {}
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PERSIST_SET_APPLICATIONS]: setApplications,
  [Types.PERSIST_SET_APPLICATION]: setApplication,
  [Types.PERSIST_SET_ACCOUNT]: setAccount,
  [Types.PERSIST_SET_LOCALE]: setLocale,
  [Types.PERSIST_SET_KIOSK]: setKiosk,
  [Types.PERSIST_SET_OFFLINE_DOCUMENTS]: setOfflineDocuments,

  [Types.PERSIST_SET_TOKEN]: setToken,
  [Types.PERSIST_SET_SCREEN]: setScreen,
  [Types.PERSIST_SET_TORCH]: setTorch,
  // [Types.PERSIST_SET_PROGRAM_CODE]: setProgramCode,
  [Types.PERSIST_SET_APPLICATION_ID]: setApplicationId,

  [Types.PERSIST_SET_ALL_PROGRAM_CODES]: setAllProgramCodes,
  [Types.PERSIST_SET_ALL_OPTIONS]: setAllOptions,
  [Types.PERSIST_SET_ALL_DOC_TYPES]: setAllDocTypes,
  [Types.PERSIST_SET_ALL_SCREENING_QUESTIONS]: setAllScreeningQuestions,
  [Types.PERSIST_SET_PROGRAM_CODES]: setProgramCodes,
  [Types.PERSIST_SET_OPTIONS]: setOptions,
  [Types.PERSIST_SET_DOC_TYPES]: setDocTypes,
  [Types.PERSIST_SET_SCREENING_QUESTIONS]: setScreeningQuestions,

  [Types.PERSIST_SET_LOGS]: setLogs,

  [Types.PERSIST_RESET]: reset
});

/* ------------- Selectors ------------- */
