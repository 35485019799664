import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';
import { Text, View } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import schema from '../Lib/ValidationSchema';

import ApplicationActions from '../Redux/ApplicationRedux';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FormGroupRadio from '../Components/FormGroupRadio';
import FormGroupText from '../Components/FormGroupText';
import FullButton from '../Components/FullButton';

import { isRequired } from '../Lib/Utils';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import { REFERRAL_VOAD, REFERRAL_OTHER } from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

class ProgramReferralScreen extends Component {
  state = {
    errorText: null
  };

  componentWillReceiveProps(nextProps) {
    const {
      setSubmitting,
      setStatus,
      status,
      data,
      errorText,
      isKiosk,
      fetching,
      token,
      isSubmitting,
      history
    } = nextProps;

    const kiosk = isKiosk;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setSubmitting(false);
      setStatus('');
      if (errorText) {
        // Returned a specific error
        this.setState({ errorText });
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        // Successful Application Update
        if (kiosk || token) {
          history.push('/ApplicantIdentificationScreen');
        } else {
          history.push('/ApplicantIdentificationScreen/CreateAccountScreen');
        }
      }
    }
  }

  render() {
    const {
      initilized,
      handleSubmit,
      isSubmitting,
      isValid,
      fetching,
      values
    } = this.props;
    const { errorText } = this.state;

    const isVoadCodeSelected =
      R.path(['referralCode'], values) === REFERRAL_VOAD;
    const isOtherSelected = R.path(['referralCode'], values) === REFERRAL_OTHER;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="ProgramReferral.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="ProgramReferral.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="referralCode"
                  labelId=""
                  optionsKey="referral"
                  validate={isRequired}
                  disabled={fetching}
                />

                {isOtherSelected && (
                  <FormGroupText
                    field="referralOther"
                    labelId="ProgramReferral.OtherLabel"
                    placeholderId="ProgramReferral.referralOther_placeholder"
                    required
                    validate={isRequired}
                    disabled={fetching}
                  />
                )}
              </Col>
            </Row>
            {isVoadCodeSelected && (
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    component={FormGroupRadio}
                    field="voadCode"
                    labelId="ProgramReferral.VOADLabel"
                    optionsKey="voad"
                    required
                    validate={isRequired}
                    disabled={fetching}
                  />
                  <FormGroupCheckbox
                    component={FormGroupCheckbox}
                    field="voadNotify"
                    labelId="ProgramReferral.VOADNotify"
                    disabled={fetching}
                  />

                  <FormattedMessage
                    id="ProgramReferral.VOADNotifyExtra"
                    style={styles.p}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={isSubmitting || !isValid}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// Pull out the fields we want to validate
const { referralCode, referralOther, voadCode, voadNotify } = schema;
// Create the yum validationSchema
const validationSchema = yup.object().shape({
  referralCode: yup.string().required('error.required'),
  referralOther: yup.string(),
  voadCode: yup.string(),
  voadNotify: yup.string()
});

ProgramReferralScreen = withFormik({
  mapPropsToValues: props => {
    // console.log('mapPropsToValues', JSON.stringify(props.application, null, 2));
    return props.application;
  },
  // validationSchema,
  isInitialValid: props => true,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    // const isVoadCodeSelected =
    //   R.path(['referralCode'], values) === REFERRAL_VOAD;
    const isOtherSelected = R.path(['referralCode'], values) === REFERRAL_OTHER;

    // if (!isVoadCodeSelected) {
    //   values.voadCode = '';
    //   values.voadNotify = '0';
    // }
    if (!isOtherSelected) {
      values.referralOther = '';
    }

    console.log('handleSubmit', JSON.stringify(values, null, 2));

    props.updateApplication(values);
    setStatus('updatingApplication');
  }
})(ProgramReferralScreen);

const mapStateToProps = ({
  application: { fetching, error, data },
  persist: { options, application, isKiosk, token }
}) => ({
  options,
  fetching,
  errorText: error,
  data,
  application,
  isKiosk,
  token
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramReferralScreen);
