import { Fonts, Colors, Metrics } from '../../Themes/';

export default {
  formLabel: {
    marginTop: Metrics.doubleBaseMargin,
    fontFamily: Fonts.type.base,
    fontWeight: Fonts.weight.bold,
    fontSize: 18
  },

  textInput: {
    // flex: 1,
    fontSize: 16,
    padding: Metrics.baseMargin,
    // letterSpacing: 5,
    margin: 0,
    // color: Colors.black,  // Breaks Android
    borderWidth: 1,
    backgroundColor: Colors.fieldBackground,
    borderColor: Colors.grey
  },
  textInputDisabled: {
    // flex: 1,
    fontSize: 16,
    padding: Metrics.baseMargin,
    margin: 0,
    borderWidth: 1,
    color: Colors.grey, // Breaks Android
    borderColor: Colors.grey,
    backgroundColor: Colors.lightestGrey
  },
  column: {},
  selectInputDisabled: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Metrics.baseMargin,
    margin: 0,
    borderWidth: 1,
    borderColor: Colors.grey,
    backgroundColor: Colors.lightestGrey,
    width: null // Overwide Specific Width
  },
  selectInput: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Metrics.baseMargin,
    margin: 0,
    borderWidth: 1,
    borderColor: Colors.grey,
    backgroundColor: Colors.fieldBackground,
    width: null // Overwide Specific Width
  },
  selectText: {
    flex: 1,
    minHeight: 23,
    fontSize: Fonts.size.pt40
  },
  selectTextDisabled: {
    flex: 1,
    minHeight: 23,
    color: Colors.grey,
    fontSize: Fonts.size.pt40
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGrey
  },
  optionTextItem: {
    ...Fonts.style.normal,
    fontSize: Fonts.size.pt40
  },
  radioButton: {
    marginLeft: 0 // Metrics.doubleBaseMargin * 2
  },
  radioButtonContainer: {},
  radioLabelText: {
    flex: 0.9,
    fontSize: 18,
    padding: Metrics.baseMargin
  },
  radioLabelTextDisabled: {
    flex: 0.9,
    color: Colors.grey,
    fontSize: 18,
    padding: Metrics.baseMargin
  },
  radioDescription: {
    fontSize: 16,
    marginLeft: 20,
    padding: Metrics.baseMargin,
    paddingTop: 0
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Metrics.doubleBaseMargin
  },
  checkboxStyle: {
    marginLeft: 0 // Metrics.doubleBaseMargin
  },
  checkboxLabel: {
    flex: 0.85,
    ...Fonts.style.normal,
    marginLeft: Metrics.baseMargin,
    fontSize: Fonts.size.pt40
  },

  button: {
    margin: 20,
    marginBottom: 0,
    borderTopColor: Colors.fire,
    borderBottomColor: Colors.bloodOrange,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    backgroundColor: Colors.ember
  },
  buttonText: {
    margin: 18,
    textAlign: 'center',
    color: Colors.snow,
    fontSize: Fonts.size.medium,
    fontFamily: Fonts.type.bold
  },
  p: {
    fontSize: Fonts.size.medium
  },

  dropzoneContainer: {
    borderWidth: 2,
    borderColor: 'black'
  },
  dropzoneElement: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    minHeight: 200
  },
  dropzoneOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 5,
    borderColor: '#CCCCCC',
    borderStyle: 'solid',
    backgroundColor: '#DDDDDD'
  },

  iconButton: { marginTop: 0 },
  iconButtonText: { marginLeft: 10, marginRight: 10 },
  errorContainer: {
    margin: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: 'rgba(255,0,0,1)',
    backgroundColor: 'rgba(255,0,0,0.1)'
  },
  errorText: {
    ...Fonts.style.normal,
    color: Colors.black
  },
  successContainer: {
    margin: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: 'rgba(0,128,0,1)',
    backgroundColor: 'rgba(0,128,0,0.1)'
  },
  successText: {
    ...Fonts.style.normal,
    color: Colors.black
  }
};
