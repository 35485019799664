import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';
import { connect } from 'react-redux';

import CaptureSignature from '../../Components/CaptureSignature';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../../Components/Grid';

import ApplicationActions from '../../Redux/ApplicationRedux';
import DocumentActions, {
  getDocumentURL,
  getDocumentType
} from '../../Redux/DocumentRedux';

import FormattedMessage from '../../Components/FormattedMessage';
import FullButton from '../../Components/FullButton';

import { getPersonId } from '../../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../../Themes/';
import styles from '../Styles/ScreenStyles';

import DOCUMENT_TYPES from '../../Config/DocumentTypesConfig';
import Footer from '../../Components/Footer';

const DOCUMENT_TYPE = 'signature4506T';
const DOCUMENT_KEYWORD_VALUE = '';

class IRS4506TSignature extends Component {
  componentDidMount() {
    // this.props.loadDocuments(DOCUMENT_TYPE);
  }

  render() {
    const {
      application,
      base,
      index,
      userId,
      tokenId,
      token,
      initilized,
      fetching,
      errorText,
      onComplete,
      documents
    } = this.props;

    if (!initilized) return null;

    const documentType = getDocumentType(application, DOCUMENT_TYPE);

    return (
      <KeyboardAwareScrollView
        ref={scroll => {
          this._scroll = scroll;
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="IRS4506TSignature.title"
                style={Fonts.style.title}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="IRS4506TSignature.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="IRS4506TSignature.declare"
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id={
                  Platform.OS === 'web'
                    ? 'form.SignWithFingerWeb'
                    : 'form.SignWithFinger'
                }
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>

          <CaptureSignature
            base={base}
            index={index}
            documentType={documentType.code}
            onComplete={() => {
              onComplete('OtherIncome');
            }}
            labelId="form.continueNextStep"
          />
          {/* <Row>
            <Col sm={12}>
              <TouchableOpacity
                style={{
                  marginTop: Metrics.doubleBaseMargin,
                  alignItems: 'center'
                }}
                onPress={onComplete}
              >
                <FormattedMessage
                  id="buttons.SkipThisStep"
                  style={[Fonts.style.subtitle, { marginTop: 0 }]}
                />
              </TouchableOpacity>
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row> */}
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, error, fetching }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  documents,
  error,
  fetching,
  options,
  docTypes,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadSignature: (personId, signature, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentUploadSignature(
        personId,
        signature,
        documentTypeCode,
        keywordValueCode
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IRS4506TSignature);
