import React, { Component } from "react";
import { Platform, Linking, Alert } from "react-native";
import { I18n } from "react-redux-i18n";
import FullButton from "./FullButton";
import { PRIVACY_POLICY_LINK } from "../Config/ApplicationConfig";

export default class AlertMessage extends Component {
  handleLink = () => {
    if (Platform.OS === "web") {
      window.location = PRIVACY_POLICY_LINK;
    } else {
      Linking.canOpenURL(PRIVACY_POLICY_LINK)
        .then((supported) => {
          if (!supported) {
            console.log("Can't handle url: " + PRIVACY_POLICY_LINK);
            Alert.alert(
              "Error",
              "Can't handle url: " + PRIVACY_POLICY_LINK,
              [{ text: "OK", onPress: () => console.log("OK Pressed") }],
              { cancelable: false }
            );
          } else {
            return Linking.openURL(PRIVACY_POLICY_LINK);
          }
        })
        .catch((err) => {
          console.error("An error occurred", err);
          Alert.alert(
            "Error",
            err.message,
            [{ text: "OK", onPress: () => console.log("OK Pressed") }],
            { cancelable: false }
          );
        });
    }
  };
  render() {
    return (
      <FullButton
        type="link"
        text={I18n.t("menu.PrivacyPolicy")}
        onPress={this.handleLink}
      />
    );
  }
}
