import React, { Component } from "react";
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { connect } from "react-redux";

import * as R from "ramda";
import moment from "moment";
import { withFormik, Field } from "formik";

import { I18n } from "react-redux-i18n";

import Icon from "react-native-vector-icons/dist/Ionicons";

import { Grid, Row, Col } from "../Components/Grid";

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from "../Components/FormattedMessage";
import FormGroupRadio from "../Components/FormGroupRadio";
import ApplicationActions from "../Redux/ApplicationRedux";
import DocumentActions, { getDocumentType } from "../Redux/DocumentRedux";

// import TopNav from '../Components/TopNav';
import FullButton from "../Components/FullButton";

import {
  getBase,
  getPersonId,
  hasDocument,
  personHasIncome,
  isNumeric,
} from "../Lib/Utils";

// Styles
import styles from "./Styles/ScreenStyles";
import { Fonts, Colors, Metrics } from "../Themes";

import { RENTAL_PROGRAM_CODE, YES_OPTION } from "../Config/ApplicationConfig";

import DOCUMENT_TYPES from "../Config/DocumentTypesConfig";
import DATA_STRUCTURE from "../Config/DataStructureConfig";
import Footer from "../Components/Footer";

const PROOF_OF_INSURANCE_DOCUMENT_TYPE = "proofOfInsurance";
const PROOF_OF_INSURANCE_KEYWORD_VALUE = ""; // Proof of Insurance

const PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE = "proofOfPropertyDamage";
const PROOF_OF_PROPERTY_DAMAGE_KEYWORD = "proofOfInsurancePayout";

const BASE = DATA_STRUCTURE.insurance;

export const InsuranceScreenComplete = (application, documents) => {
  console.log("InsuranceScreenComplete -> application", application);
  let insuranceGood = true;

  if (application[BASE]) {
    application[BASE].map((member, idx) => {
      if (!member.companyName) insuranceGood = false;
    });
  }

  // Don't check uploads
  // if (R.path([BASE], application)) {
  //   application[BASE].map((member, idx) => {
  //     const personId = getPersonId(application, BASE, idx);

  //     const proofOfInsuranceDocumentType = getDocumentType(
  //       application,
  //       PROOF_OF_INSURANCE_DOCUMENT_TYPE
  //     );
  //     const proofOfPropertyDamageDocumentType = getDocumentType(
  //       application,
  //       PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE
  //     );

  //     const hasProofOfInsurance = hasDocument(
  //       documents,
  //       personId,
  //       proofOfInsuranceDocumentType.code
  //     );
  //     const hasProofOfPropertyDamage = hasDocument(
  //       documents,
  //       personId,
  //       proofOfPropertyDamageDocumentType.code
  //     );
  //     if (!hasProofOfInsurance && insuranceGood === true) insuranceGood = false;
  //     if (!hasProofOfPropertyDamage && insuranceGood === true)
  //       insuranceGood = false;
  //   });
  // }
  return insuranceGood;
};

class InsuranceScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null,
  };

  addingInsurance = false;
  deletingInsurance = false;

  componentDidMount() {
    this.props.loadDocuments();
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
    } = nextProps;

    if (isSubmitting && status === "addingInsurance" && !fetching) {
      setStatus("");
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        console.log("DONE");
        // history.push('CoapplicantIdentificationScreen');
        this.handleEdit(application[BASE].length - 1);
      }
    }

    if (isSubmitting && status === "updatingApplication" && !fetching) {
      setStatus("");
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        if (Platform.OS === "web") {
          history.push("/OtherFundsScreen");
        } else {
          history.push("/NoInsuranceScreen");
        }
      }
    }

    // if (this.addingInsurance && !fetching) {
    //   this.addingInsurance = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //   } else if (application && application[BASE]) {
    //     console.log(application);
    //     this.setState({ editingIndex: application[BASE].length - 1 });
    //   } else {
    //     this.setState({ errorText: 'Error Adding Household Member' });
    //   }
    // } else if (this.deletingInsurance && !fetching) {
    //   this.deletingInsurance = false;
    // }
  }

  getNextIndex() {
    const { application } = this.props;

    if (application[BASE] && Object.keys(application[BASE]).length) {
      console.log("next", Object.keys(application[BASE]).length);
      return Object.keys(application[BASE]).length;
    }
    return 0;
  }

  // handleCompleteScan = () => {
  //   this.setState({ currentScreen: 'ContactInfo' });
  // };

  // onComplete = () => {
  //   console.log('onComplete');
  //   this.setState({ editingIndex: null, currentScreen: null });
  // };
  // onCancel = () => {
  //   this.setState({ currentScreen: 'RetrieveTaxReturn' });
  // };

  // onScanComplete = () => {
  //   console.log('onScanComplete');
  // };

  // handleGoToScreen = (currentScreen) => {
  //   this.setState({ currentScreen });
  // };

  // handleContactInfoComplete = () => {
  //   const { application } = this.props;
  //   const { editingIndex } = this.state;
  //   // Check to See if we need to go to Income

  //   const householdMember = getBase(application, BASE, editingIndex);
  //   const dateOfBirth = moment(householdMember.dateOfBirth, 'M/D/YYYY');
  //   const age = householdMember.dateOfBirth ? moment().diff(dateOfBirth, 'years') : 0;

  //   if (age < 18 || application.programCode.toString() === RENTAL_PROGRAM_CODE.toString()) {
  //     this.setState({ editingIndex: null, currentScreen: null });
  //   } else {
  //     this.setState({ currentScreen: 'UploadTaxReturn' });
  //   }
  // };

  handleAddInsurance = () => {
    const { application, updateApplication } = this.props;

    this.addingInsurance = true;
    const newApplication = JSON.parse(JSON.stringify(application));

    if (!newApplication[BASE]) {
      newApplication[BASE] = [];
    }
    newApplication[BASE].push({ id: "-1" });
    console.log("handleAddInsurance", JSON.stringify(newApplication));
    updateApplication(newApplication);
  };

  handleEdit(editingIndex) {
    console.log("editingIndex", editingIndex, typeof editingIndex);

    this.props.history.push(`/Insurance/${editingIndex}/Information`);

    // // Force Int
    // if (typeof editingIndex === 'string') {
    //   this.setState({ editingIndex: parseInt(editingIndex) });
    // } else {
    //   this.setState({ editingIndex });
    // }
  }

  handleDelete(personId, key) {
    const { deleteRecord } = this.props;
    console.log(personId, key);
    // Remove application.BASE[idx]
    this.deletingInsurance = true;
    deleteRecord(personId, key);
  }

  renderInsurancePolicies = () => {
    const { application, documents } = this.props;

    let idx = 1;
    const members = [];
    const iconStyle = {
      color: "red",
      marginHorizontal: Metrics.baseMargin,
    };

    const proofOfInsuranceDocumentType = getDocumentType(
      application,
      PROOF_OF_INSURANCE_DOCUMENT_TYPE
    );
    const proofOfPropertyDamageDocumentType = getDocumentType(
      application,
      PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE
    );

    Object.keys(application[BASE]).forEach((key) => {
      if (isNumeric(key)) {
        const member = application[BASE][key] || {};
        const personId = getPersonId(application, BASE, key);

        const hasProofOfInsurance = hasDocument(
          documents,
          personId,
          proofOfInsuranceDocumentType.code
        );
        const hasProofOfPropertyDamage = hasDocument(
          documents,
          personId,
          proofOfPropertyDamageDocumentType.code
        );

        const documentUploaded =
          hasProofOfInsurance && hasProofOfPropertyDamage;

        // const documentUploaded = hasDocument(
        //   documents,
        //   personId,
        //   DOCUMENT_TYPE
        // );

        members.push(
          <View
            key={`member_${key}`}
            style={{
              flex: 1,
              flexDirection: "column",
              borderWidth: 1,
              padding: Metrics.baseMargin,
              marginHorizontal: 0,
              borderColor: Colors.lightGrey,
              marginBottom: Metrics.baseMargin,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={[Fonts.style.normal, { fontWeight: "bold", flex: 1 }]}
              >
                {member.insuranceCarrierName} {member.policyNumber}{" "}
                {member.insurancePhoneNumber}
              </Text>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: "#aaa",
                }}
                onPress={this.handleEdit.bind(this, key)}
              >
                <Icon name="md-create" size={20} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: "#aaa",
                }}
                onPress={this.handleDelete.bind(this, member.id, key)}
              >
                <Icon name="md-trash" size={20} />
              </TouchableOpacity>
            </View>
            {!documentUploaded && (
              <TouchableOpacity
                onPress={this.handleEdit.bind(this, key)}
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Icon name="md-close-circle" size={20} style={iconStyle} />
                <FormattedMessage
                  id="error.uploadMissing"
                  style={Fonts.style.normal}
                />
              </TouchableOpacity>
            )}
          </View>
        );
        idx++;
      }
    });

    return members;
  };

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      handleSubmit,
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    if (!initilized) return null;

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    if (
      application &&
      application[BASE] &&
      Object.keys(application[BASE]).length > 0
    ) {
      return (
        <View style={styles.flex}>
          <ScrollView
            ref={(scroll) => {
              this._scroll = scroll;
            }}
            style={styles.scrollViewContainer}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <Grid>
              <Row>
                <Col>
                  <FormattedMessage
                    id="Insurance.title"
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id="Insurance.subtitle"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {errorView}
                  {this.renderInsurancePolicies()}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t(`Insurance.AddAnother`)}
                    onPress={this.handleAddInsurance}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t("form.continueNextStep")}
                    onPress={() => history.push("/OtherFundsScreen")}
                  />
                </Col>
              </Row>
            </Grid>
            <View style={{ flex: 1 }} />
            <Footer />
          </ScrollView>
        </View>
      );
    }

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="Insurance.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="Insurance.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="hadInsuranceCode"
                  labelId={`Insurance.DidYouHaveInsurance`}
                  optionsKey="yes_no"
                  reverse
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t("form.continueNextStep")}
                  onPress={handleSubmit}
                  disabled={fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

InsuranceScreen = withFormik({
  mapPropsToValues: (props) => {
    return props.application;
  },
  isInitialValid: (props) =>
    props.application[BASE] && props.application[BASE].length > 0,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { application, history, updateApplication } = props;

    const newApplication = JSON.parse(JSON.stringify(application));
    newApplication.hadInsuranceCode = values.hadInsuranceCode;

    if (values.hadInsuranceCode && values.hadInsuranceCode === YES_OPTION) {
      // Yes
      if (!newApplication[BASE]) {
        newApplication[BASE] = [];
      }
      newApplication[BASE].push({ id: "-1" });
      console.log("handleAddInsurance", JSON.stringify(newApplication));
      updateApplication(newApplication);
      setStatus("addingInsurance");
    } else {
      // No
      updateApplication(newApplication);
      setStatus("updatingApplication");
    }
  },
})(InsuranceScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document,
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocuments: (docType) =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  deleteHouseholdMember: (memberId) =>
    dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuranceScreen);
