import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Containers/App';
//import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { Platform } from 'react-native';

import faFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import evilFont from 'react-native-vector-icons/Fonts/EvilIcons.ttf';
import iconicFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import materialIconsFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();

if (Platform.OS === 'web') {
  // Support Internet Explorer
  // require('formdata-polyfill-on-demand')();

  if (window.document) {
    // Inject react-native-vector-icons fonts
    const iconFontStyles = `
  @font-face {
    src: url(${faFont});
    font-family: FontAwesome;
  }
  @font-face {
    src: url(${materialIconsFont});
    font-family: MaterialIcons;
  }
  @font-face {
    src: url(${evilFont});
    font-family: EvilIcons;
  }
  @font-face {
    src: url(${iconicFont});
    font-family: Ionicons;
  }`;

    // Create stylesheet
    const style = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = iconFontStyles;
    } else {
      style.appendChild(document.createTextNode(iconFontStyles));
    }

    // Inject stylesheet
    document.head.appendChild(style);
  }
}
