import React, { Component } from 'react';
import { TouchableOpacity, TextInput, Button, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId,
  getBase,
  getBaseString,
  getUrlVars
} from '../Lib/Utils';

import FormGroupText from '../Components/FormGroupText';
import ErrorMessage from '../Components/ErrorMessage';
import FormattedMessage from '../Components/FormattedMessage';

import FullButton from '../Components/FullButton';

import AccountActions from '../Redux/AccountRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Metrics } from '../Themes/';
import Footer from '../Components/Footer';

class ChangePasswordScreen extends Component {
  state = {
    errorText: null
  };

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setSubmitting
    } = nextProps;

    if (isSubmitting && status === 'updatingPassword' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('/LoginScreen/changedPassword');
      }
    }
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      isValid,
      history,
      fetching
    } = this.props;
    const { errorText } = this.state;
    const disabled = fetching;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="ChangePassword.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="ChangePassword.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <ErrorMessage errorText={errorText} />
                <FormGroupText
                  inputRef={ref => {
                    this._password = ref;
                  }}
                  field="password"
                  labelId="Login.password"
                  placeholderId="Login.password"
                  autoCapitalize="none"
                  required
                  validate={isRequired}
                  secureTextEntry
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._password = ref;
                  }}
                  field="password2"
                  labelId="Login.repeatPassword"
                  placeholderId="Login.password"
                  autoCapitalize="none"
                  required
                  validate={isRequired}
                  secureTextEntry
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('ChangePassword.sendPassword')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TouchableOpacity
                  onPress={() => history.push('LoginScreen')}
                  style={{ marginTop: Metrics.doubleBaseMargin }}
                >
                  <FormattedMessage
                    id="Login.returnToLogin"
                    style={[
                      Fonts.style.base,
                      {
                        textAlign: 'center',
                        textDecorationLine: 'underline'
                      }
                    ]}
                  />
                </TouchableOpacity>
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

ChangePasswordScreen = withFormik({
  mapPropsToValues: props => ({
    password: '',
    password2: ''
  }),
  // validationSchema,
  validate: (values, props) => {
    const { password, password2 } = values;
    let errors = {};
    if (password !== password2) {
      errors['password2'] = 'ChangePassword.passwordsDontMatch';
    }

    return errors;
  },
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { password } = values;
    const token = getUrlVars()['token'];

    console.log('password', password, token);
    props.changePassword(password, token);
    setStatus('updatingPassword');
  }
})(ChangePasswordScreen);

const mapStateToProps = ({
  persist: { account },
  app: { initilized },
  account: { data, fetching, error }
}) => ({
  initilized,
  account,
  data,
  fetching,
  errorText: error
});

const mapDispatchToProps = dispatch => ({
  changePassword: (password, token) =>
    dispatch(AccountActions.AccountChangePassword(password, token))
});

ChangePasswordScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordScreen);

export default ChangePasswordScreen;
