export default {
  proofOfIdentification: {
    5: {
      code: '6010',
      keywords: {
        DriversLicense: '6001'
      }
    },
    6: {
      code: '7010',
      keywords: {
        DriversLicense: '7001'
      }
    }
  },
  primaryResidence: {
    5: {
      code: '6040'
      // keywords: {
      //   DriversLicense: '5045' // Unused for USVI
      // }
    }
  },
  proofOfInsurance: {
    5: {
      code: '6020'
    },
    6: { code: '7020' }
  },
  proofOfOwnership: {
    5: {
      code: '6030',
      traditionalDocumentsCodes: ['6021', '6022', '6023', '6024'],
      nonTraditionalDocumentsCodes: ['6025', '6026', '6027']
    },
    6: {
      code: '7030',
      traditionalDocumentsCodes: ['7021', '7022', '7023', '7024'],
      nonTraditionalDocumentsCodes: ['7025', '7026', '7027']
    }
  },
  proofOfCitizenship: {
    5: {
      code: '6015'
    },
    6: {
      code: '7015'
    }
  },
  proofOfDisability: {
    5: {
      code: '6065'
    }
    // 6: {
    //   code: '6065'
    // }
  },
  proofOfLandOwnership: {
    6: {
      code: '7410'
    }
  },
  tenantLeaseAgreement: {
    6: {
      code: '7400'
    }
  },
  // proofOfMobileHomeOwnership: {
  //   code: '4008'
  // },
  proofOfPrimaryResidence: {
    5: {
      code: '6040'
    }
    // keywords: {
    //   DriversLicense: '5045' // Unused for USVI
    // }
  },
  proofOfPropertyDamage: {
    5: {
      code: '6050',
      keywords: {
        proofOfInsurancePayout: '6065'
      }
    },
    6: {
      code: '7050',
      keywords: {
        proofOfInsurancePayout: '7065'
      }
    }
  },
  proofOfIncome: {
    5: {
      code: '6060'
    }
  },
  stateTaxReturn: {
    5: {
      code: '6070'
    },
    6: {
      code: '7070'
    }
  },
  federalTaxReturn: {
    5: {
      code: '6080'
    },
    6: {
      code: '7080'
    }
  },
  signatureNoIncome: {
    5: {
      code: '6210'
    },
    6: {
      code: '7210'
    }
  },
  signature4506T: {
    5: {
      code: '6230'
    },
    6: {
      code: '7230'
    }
  },
  signatureLawfulPresence: {
    5: {
      code: '6200'
    },
    6: {
      code: '7200'
    }
  },
  signatureSubrogationAgreement: {
    5: {
      code: '6250'
    },
    6: {
      code: '7250'
    }
  },
  signatureNoInsurance: {
    5: {
      code: '6220'
    },
    6: {
      code: '7220'
    }
  },
  communicationDesigneeAuthorization: {
    5: {
      code: '6240'
    },
    6: {
      code: '7240'
    }
  },
  powerOfAttorney: {
    5: {
      code: '6100'
    },
    6: {
      code: '7100'
    }
  },
  lienDocument: {
    5: {
      code: '6090'
    },
    6: {
      code: '7090'
    }
  },
  docusignNoIncome: {
    5: {
      code: '6310'
    },
    6: {
      code: '7310'
    }
  },
  docusign4506T: {
    5: {
      code: '6330'
    },
    6: {
      code: '7330'
    }
  },
  docusignLawfulPresence: {
    5: {
      code: '6300'
    },
    6: {
      code: '7300'
    }
  },
  docusignSubrogationAgreement: {
    5: {
      code: '6350'
    },
    6: {
      code: '7350'
    }
  },
  docusignNoInsurance: {
    5: {
      code: '6320'
    },
    6: {
      code: '7320'
    }
  },
  docusignCommunicationDesignee: {
    5: {
      code: '6340'
    },
    6: {
      code: '7340'
    }
  }
};
