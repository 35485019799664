import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';
import { connect } from 'react-redux';
// import SignatureCapture from 'react-native-signature-capture';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, {
  getDocumentURL,
  getDocumentType
} from '../Redux/DocumentRedux';

import CaptureSignature from '../Components/CaptureSignature';
import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';

import { getPersonId } from '../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import Footer from '../Components/Footer';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const DOCUMENT_TYPE = 'signatureNoInsurance';
const DOCUMENT_KEYWORD_VALUE = '';

class NoInsuranceScreen extends Component {
  state = {
    errorText: null,
    signed: false
  };

  render() {
    const {
      application,
      userId,
      tokenId,
      token,
      initilized,
      fetching,
      history,
      errorText,
      onComplete,
      documents
    } = this.props;

    const { signed } = this.state;

    if (!initilized) return null;

    const documentType = getDocumentType(application, DOCUMENT_TYPE);

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView
        ref={scroll => {
          this._scroll = scroll;
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="NoInsurance.title"
                style={Fonts.style.title}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="NoInsurance.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col>{errorView}</Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id={
                  Platform.OS === 'web'
                    ? 'form.SignWithFingerWeb'
                    : 'form.SignWithFinger'
                }
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>

          <CaptureSignature
            base={APPLICANT_BASE}
            documentType={documentType.code}
            onComplete={() => history.push('/OtherFundsScreen')}
            labelId="form.continueNextStep"
          />
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { application, account, tokenId, token },
  document: { options, documents, error, fetching }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  documents,
  error,
  fetching,
  options,
  initilized,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadSignature: (personId, signature, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentUploadSignature(
        personId,
        signature,
        documentTypeCode,
        keywordValueCode
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoInsuranceScreen);
