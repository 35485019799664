import React, { Component } from "react";
import PropTypes from "prop-types";
import { Platform, View, Text, Dimensions } from "react-native";
import Icon from "react-native-vector-icons/dist/MaterialIcons";

import styles from "./Styles/FormStyles";
import FormattedMessage from "./FormattedMessage";

import { Colors } from "../Themes";

// const wide = Platform.OS === "web" ? 768 : 375;
const wide = Platform.OS === "web" ? 994 : 668;
const VERTICAL_HEIGHT = 60;
const BULLET_HEIGHT = 30;
const BORDER_WIDTH = 2;
const LARGE_BORDER_WIDTH = 25;
const INNER_BORDER_WIDTH = 15;
const SMALL_BORDER_WIDTH = 10;

export default class ApplicationStatusProgress extends Component {
  static propTypes = {
    vertical: PropTypes.bool,
    lineRight: PropTypes.bool,
    index: PropTypes.number,
    active: PropTypes.bool,
    complete: PropTypes.bool
  };
  static defaultProps = {
    vertical: false,
    lineRight: false,
    index: 1,
    active: false,
    complete: false
  };

  renderComplete = () => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: BULLET_HEIGHT
      }}
    >
      <View
        style={{
          width: 25,
          height: 25,
          backgroundColor: "white",
          // borderRadius: "100%",
          position: "absolute"
        }}
      />
      <Icon
        name="radio-button-unchecked"
        size={30}
        color={Colors.green}
        style={{ position: "absolute" }}
      />
      <Icon
        name="check"
        size={18}
        color={Colors.green}
        style={{ position: "absolute" }}
      />
    </View>
  );
  renderActive = () => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: BULLET_HEIGHT
      }}
    >
      <View
        style={{
          width: 25,
          height: 25,
          backgroundColor: "white",
          // borderRadius: "100%",
          position: "absolute"
        }}
      />
      <Icon
        name="radio-button-checked"
        size={30}
        style={{ position: "absolute" }}
      />
    </View>
  );
  renderError = () => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: BULLET_HEIGHT
      }}
    >
      <View
        style={{
          width: 25,
          height: 25,
          backgroundColor: "white",
          // borderRadius: "100%",
          position: "absolute"
        }}
      />
      <Icon
        name="radio-button-checked"
        color={Colors.error}
        size={30}
        style={{ position: "absolute" }}
      />
    </View>
  );
  renderInactive = () => (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: BULLET_HEIGHT
      }}
    >
      <View
        style={{
          width: 25,
          height: 25,
          backgroundColor: "white",
          // borderRadius: "100%",
          position: "absolute"
        }}
      />
      <Icon
        name="radio-button-unchecked"
        size={30}
        color={Colors.lightestGrey}
        style={{ position: "absolute" }}
      />
    </View>
  );

  render() {
    const {
      index,
      lineRight,
      complete,
      active,
      vertical,
      error,
      isWide
    } = this.props;

    let indexColor = Colors.lightestGrey;
    let lineColor = Colors.lightestGrey;
    let textColor = Colors.lightestGrey;

    let bullet = this.renderInactive();
    if (error) {
      indexColor = Colors.error;
      // lineColor = Colors.error;
      textColor = Colors.black;
      bullet = this.renderError();
    }
    if (complete) {
      indexColor = Colors.green;
      lineColor = Colors.green;
      textColor = Colors.green;
      bullet = this.renderComplete();
    }
    if (active) {
      bullet = this.renderActive();
    }
    return (
      <View
        onLayout={this._handleLayout}
        style={{
          // width: "100%",
          // borderWidth: 1,
          // borderColor: "red",
          height: isWide ? "auto" : VERTICAL_HEIGHT,
          flexDirection: isWide ? "column" : "row",
          justifyContent: isWide ? "center" : "flex-start",
          alignItems: "center"
        }}
      >
        {isWide && (
          <View style={{ flex: 1, marginRight: isWide ? 0 : 25 }}>
            <Text
              style={{
                color: indexColor,
                textAlign: isWide ? "center" : "right"
              }}
            >
              {index}
            </Text>
          </View>
        )}
        {!isWide && <View style={{ width: 50 }}></View>}
        <View
          style={{
            width: isWide ? "100%" : "auto",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {lineRight && isWide && (
            <View
              style={{
                position: "absolute",
                left: "50%",
                borderBottomWidth: BORDER_WIDTH,
                borderColor: lineColor,
                height: 0,
                width: "100%"
              }}
            />
          )}
          {lineRight && !isWide && (
            <View
              style={{
                position: "absolute",
                top: "10%",
                borderLeftWidth: BORDER_WIDTH,
                borderColor: lineColor,
                height: VERTICAL_HEIGHT * 1.2,
                width: 0
              }}
            />
          )}
          {bullet}
        </View>
        <View style={{ flex: 1, marginLeft: isWide ? 0 : 25 }}>
          <FormattedMessage
            id={`ApplicationStatus.state${index}`}
            style={{ color: textColor, textAlign: isWide ? "center" : "left" }}
          />
        </View>
      </View>
    );
  }
}
