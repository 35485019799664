import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';

import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { Grid, Row, Col } from '../Components/Grid';

import { isRequired, floatFormat } from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';
import { isKiosk } from '../Redux/AccountRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

import { getBase, getBaseString, formatCurrency } from '../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { YES_OPTION } from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

const BASE = DATA_STRUCTURE.additionalFunds;

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
// const DOCUMENT_TYPE = '3003'; // Proof of Insurance
// const PROOF_OF_INSURANCE_KEYWORD_VALUE = ''; // Proof of Insurance

class OtherFunds extends Component {
  state = {
    editingIndex: null,
    documentErrorText: null,
    errorText: null
  };

  formInitilized = false;
  formInitilized = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;
  savingApplication = false;

  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('/OtherFundsScreen');
      }
    }
  }

  // initilizeForm(props) {
  //   const { match: {
  //     params: { index }
  //   },, application, change } = props;

  //   const baseString = getBaseString(base, index);

  //   this.formInitilized = true;

  //   // Format Amount as Currency
  //   if (R.path([base, index, 'amount'], application)) {
  //     change(
  //       `${baseString}.amount`,
  //       formatCurrency(R.path([base, index, 'amount'], application))
  //     );
  //   }
  // }

  // handleUploadFiles = (photos) => {
  //   console.log(photos);

  //   const { application, uploadDocuments } = this.props;

  //   const personId =
  //     R.pathOr(0, [APPLICANT_BASE, 'id'], application) > 0
  //       ? R.path([APPLICANT_BASE, 'id'], application)
  //       : APPLICANT_BASE;

  //   // if (token) {

  //   this.uploadDocuments = true;
  //   console.log(personId, photos, DOCUMENT_TYPE, PROOF_OF_INSURANCE_KEYWORD_VALUE);
  //   uploadDocuments(personId, photos, DOCUMENT_TYPE, PROOF_OF_INSURANCE_KEYWORD_VALUE);
  // };

  // handleDeleteFiles = () => {
  //   const { application, deleteDocuments } = this.props;

  //   const personId =
  //     R.pathOr(0, [APPLICANT_BASE, 'id'], application) > 0
  //       ? R.path([APPLICANT_BASE, 'id'], application)
  //       : APPLICANT_BASE;

  //   this.deletingDocuments = true;
  //   console.log('deleteDocuments', personId, DOCUMENT_TYPE, PROOF_OF_INSURANCE_KEYWORD_VALUE);
  //   deleteDocuments(personId, DOCUMENT_TYPE, PROOF_OF_INSURANCE_KEYWORD_VALUE);
  // };

  // onSubmit = (values) => {
  //   const { updateApplication, base, index } = this.props;
  //   console.log(base, index, JSON.stringify(values, null, 2));

  //   // let newValues = Immutable.asMutable(values) // Does not work
  //   const newValues = JSON.parse(JSON.stringify(values));

  //   // Concat Address
  //   if (newValues[base][index].address1) {
  //     if (newValues[base][index].address2) {
  //       newValues[base][index].address = `${newValues[base][index].address1}\n${
  //         newValues[base][index].address2
  //       }`;
  //     } else {
  //       newValues[base][index].address = newValues[base][index].address1;
  //     }
  //     delete newValues[base][index].address1;
  //     delete newValues[base][index].address2;
  //   }

  //   this.savingApplication = true;
  //   updateApplication(values);
  // };

  // renderDocuments(documents) {
  //   const { docTypes } = this.props;

  //   const filteredDocuments = {};
  //   documents &&
  //     documents.forEach((ele, idx) => {
  //       if (ele.documentType === DOCUMENT_TYPE) {
  //         if (!filteredDocuments[ele.keywordValue]) {
  //           filteredDocuments[ele.keywordValue] = {
  //             keywordValue: ele.keywordValue,
  //             fileDownloadURL: ele.fileDownloadURL,
  //             count: 1,
  //           };
  //         } else {
  //           filteredDocuments[ele.keywordValue].count++;
  //         }
  //       }
  //     });

  //   const rows = Object.keys(filteredDocuments).map((key, idx) => {
  //     const ele = filteredDocuments[key];
  //     const plusCount = ele.count - 1;
  //     const plusText = ele.count > 1 && (
  //       <Text
  //         style={[
  //           Fonts.style.normal,
  //           {
  //             fontFamily: Fonts.type.bold,
  //             color: Colors.white,
  //             backgroundColor: 'rgba(0,0,0,0.5)',
  //             padding: Metrics.baseMargin,
  //           },
  //         ]}
  //       >
  //         + {plusCount}
  //       </Text>
  //     );

  //     return (
  //       <Row
  //         key={idx}
  //         style={{
  //           height: 90,
  //           borderWidth: 1,
  //           padding: Metrics.baseMargin,
  //           marginHorizontal: 0,
  //           borderColor: Colors.lightGrey,
  //           marginBottom: Metrics.baseMargin,
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Column span={{ xs: 3 }} style={{ justifyContent: 'center', alignItems: 'center' }}>
  //           {ele.fileDownloadURL && (
  //             <Image
  //               source={{ uri: ele.fileDownloadURL }}
  //               style={{
  //                 position: 'absolute',
  //                 width: 90,
  //                 height: 90,
  //                 resizeMode: 'contain',
  //               }}
  //             />
  //           )}
  //           {plusText}
  //         </Column>
  //         <Column span={{ xs: 6 }} style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
  //           <Text numberOfLines={2} style={Fonts.style.normal}>
  //             {docTypes[DOCUMENT_TYPE][ele.keywordValue]}
  //           </Text>
  //         </Column>
  //         <Column span={{ xs: 3 }} style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
  //           <TouchableOpacity
  //             style={{
  //               padding: Metrics.baseMargin,
  //               marginRight: Metrics.baseMargin,
  //               borderRadius: 5,
  //               backgroundColor: '#aaa',
  //             }}
  //             onPress={this.handleDeleteFiles.bind(this, key)}
  //           >
  //             <Icon name="md-trash" size={20} />
  //           </TouchableOpacity>
  //         </Column>
  //       </Row>
  //     );
  //     // }
  //   });

  //   return (
  //     rows && (
  //       <View>
  //         <Row style={{ padding: Metrics.baseMargin, marginHorizontal: 0 }}>
  //           <Column span={{ xs: 3 }} style={{ justifyContent: 'center', alignItems: 'center' }}>
  //             <FormattedMessage id="Image" style={[Fonts.style.normal, { fontWeight: 'bold' }]} />
  //           </Column>
  //           <Column span={{ xs: 9 }} style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
  //             <FormattedMessage id="Type" style={[Fonts.style.normal, { fontWeight: 'bold' }]} />
  //           </Column>
  //         </Row>
  //         {rows}
  //       </View>
  //     )
  //   );
  // }

  render() {
    const {
      match: {
        params: { index }
      },
      initilized,
      handleSubmit,
      isValid,
      fetching,
      application,
      documents,
      values
    } = this.props;

    const appBase = getBase(values, BASE, index);
    const baseString = getBaseString(BASE, index);

    const { documentErrorText, errorText } = this.state;

    if (!initilized) return null;

    const personId =
      R.pathOr(0, [APPLICANT_BASE, 'id'], application) > 0
        ? R.path([APPLICANT_BASE, 'id'], application)
        : APPLICANT_BASE;

    // const photos = [];
    // documents &&
    //   documents.forEach(ele => {
    //     if (ele.personId === personId && ele.documentType === DOCUMENT_TYPE) {
    //       photos.push(ele.fileDownloadURL);
    //     }
    //   });

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="OtherFunds.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="OtherFunds.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>{errorView}</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.typeCode`}
                  labelId="OtherFunds.OtherSource"
                  optionsKey="additional_funds_type"
                  disabled={fetching}
                  validate={isRequired}
                />
              </Col>
            </Row>
            {appBase.typeCode === '4' && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    field={`${baseString}.typeOther`}
                    labelId="OtherFunds.Other"
                    placeholderId="OtherFunds.Other_placeholder"
                    disabled={fetching}
                    validate={isRequired}
                  />
                </Col>
              </Row>
            )}

            {/* <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.appliedCode`}
                  labelId="OtherFunds.appliedCode"
                  optionsKey="yes_no"
                  disabled={fetching}
                  reverse
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.approvedCode`}
                  labelId="OtherFunds.approvedCode"
                  optionsKey="yes_no"
                  disabled={fetching}
                  reverse
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.assistanceTypeCode`}
                  labelId="OtherFunds.approvedCode"
                  optionsKey="additional_funds_assistance_type"
                  disabled={fetching}
                  validate={isRequired}
                />
              </Col>
            </Row>
            {appBase.assistanceTypeCode === '4' && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    field={`${baseString}.assistanceTypeOther`}
                    labelId="OtherFunds.assistanceTypeOther"
                    // placeholderId="OtherFunds.Other_placeholder"
                    placeholderId=""
                    disabled={fetching}
                    validate={isRequired}
                  />
                </Col>
              </Row>
            )} */}

            {/* <Row>
              <Col sm={12}>
                <FormGroupText
                  field={`${baseString}.amountApproved`}
                  labelId="OtherFunds.amountApproved"
                  placeholderId=""
                  keyboardType="numeric"
                  format={floatFormat}
                  disabled={fetching}
                  // validate={isRequired}
                />
              </Col>
            </Row> */}
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._amountReceived = ref;
                  }}
                  field={`${baseString}.amountReceived`}
                  labelId="OtherFunds.amountReceived"
                  placeholderId=""
                  keyboardType="numeric"
                  format={floatFormat}
                  onSubmitEditing={() => this._purpose.focus()}
                  disabled={fetching}
                  // validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._purpose = ref;
                  }}
                  field={`${baseString}.purpose`}
                  labelId="OtherFunds.purpose"
                  // placeholderId="OtherFunds.Other_placeholder"
                  onSubmitEditing={() => {
                    appBase.typeCode === '1' &&
                      this._FEMARegistrationNumber &&
                      this._FEMARegistrationNumber.focus();
                    (appBase.typeCode === '3' || appBase.typeCode === '7') &&
                      this._SBAApplicationNumber &&
                      this._SBAApplicationNumber.focus();
                  }}
                  placeholderId=""
                  disabled={fetching}
                  // validate={isRequired}
                />
              </Col>
            </Row>

            {appBase.typeCode === '1' && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._FEMARegistrationNumber = ref;
                    }}
                    field={`${baseString}.FEMARegistrationNumber`}
                    labelId="OtherFunds.FEMARegistrationNumber"
                    // placeholderId="OtherFunds.Other_placeholder"
                    placeholderId=""
                    disabled={fetching}
                    // validate={isRequired}
                  />
                </Col>
              </Row>
            )}
            {(appBase.typeCode === '3' || appBase.typeCode === '7') && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._SBAApplicationNumber = ref;
                    }}
                    field={`${baseString}.SBAApplicationNumber`}
                    labelId="OtherFunds.SBAApplicationNumber"
                    // placeholderId="OtherFunds.Other_placeholder"
                    onSubmitEditing={() => this._SBALoanNumber.focus()}
                    placeholderId=""
                    disabled={fetching}
                    // validate={isRequired}
                  />
                </Col>
              </Row>
            )}
            {(appBase.typeCode === '3' || appBase.typeCode === '7') && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._SBALoanNumber = ref;
                    }}
                    field={`${baseString}.SBALoanNumber`}
                    labelId="OtherFunds.SBALoanNumber"
                    // placeholderId="OtherFunds.Other_placeholder"
                    placeholderId=""
                    disabled={fetching}
                    // validate={isRequired}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

OtherFunds = withFormik({
  mapPropsToValues: props => {
    const { application } = props;

    return application;
  },
  isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      updateApplication,
      match: {
        params: { index }
      }
    } = props;

    let newValues = JSON.parse(JSON.stringify(values));

    // Remove Non Digits
    if (newValues[BASE][index].amountReceived) {
      newValues[BASE][index].amountReceived = newValues[BASE][
        index
      ].amountReceived.replace(/[^\d.-]/g, '');
    }

    console.log('updatingApplication', newValues);
    updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(OtherFunds);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application },
  document
}) => ({
  options,
  data,
  initilized,
  fetching,
  errorText: error,

  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,

  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherFunds);
