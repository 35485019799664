import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';

// import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from '../Components/Grid';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  zipcodeFormat,
  getPersonId,
  getBase,
  getBaseString,
  floatFormat,
  formatCurrency,
  isEinNumber,
  einFormat
} from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentType } from '../Redux/DocumentRedux';
import { isKiosk } from '../Redux/AccountRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupRadio from '../Components/FormGroupRadio';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FullButton from '../Components/FullButton';
import UploadDocumentType from '../Components/UploadDocumentType';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';
import formStyles from '../Components/Styles/FormStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import {
  YES_OPTION,
  NO_OPTION,
  TRUE_OPTION,
  FALSE_OPTION
} from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

const PROOF_OF_INSURANCE_DOCUMENT_TYPE = 'proofOfInsurance';
const PROOF_OF_INSURANCE_KEYWORD_VALUE = ''; // Proof of Insurance

const PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE = 'proofOfPropertyDamage';
const PROOF_OF_PROPERTY_DAMAGE_KEYWORD = 'proofOfInsurancePayout';

const BASE = DATA_STRUCTURE.propertyOwner;

class PropertyOwnerInformationScreen extends Component {
  state = {
    editingIndex: null,
    documentErrorText: null,
    errorText: null
  };

  formInitilized = false;
  formInitilized = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;
  savingApplication = false;

  // componentDidMount() {
  //   const { initilized, currentForm } = this.props;

  //   if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
  //     this.formInitilized = true;
  //     this.initilizeForm(this.props);
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('/PropertyOwnersScreen');
      }
    }
  }

  render() {
    const {
      base,
      match: {
        params: { index }
      },
      initilized,
      handleSubmit,
      isValid,
      fetching,
      application,
      documents,
      documentsFetching,
      values,
      isSubmitting,
      errors
    } = this.props;

    const appBase = getBase(values, BASE, index);
    const baseString = getBaseString(BASE, index);

    const { documentErrorText, errorText } = this.state;

    if (!initilized) return null;

    const personId = getPersonId(application, base, index);
    const proofOfInsuranceDocumentType = getDocumentType(
      application,
      PROOF_OF_INSURANCE_DOCUMENT_TYPE
    );
    const proofOfPropertyDamageDocumentType = getDocumentType(
      application,
      PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE
    );
    const insurancePayoutKeywordValue =
      proofOfPropertyDamageDocumentType.keywords[
        PROOF_OF_PROPERTY_DAMAGE_KEYWORD
      ];

    const isIndividualOrCompanyOptions = {
      1: {
        code: TRUE_OPTION,
        value: I18n.t('LienHolderInformation.IsCompany')
      },
      0: {
        code: FALSE_OPTION,
        value: I18n.t('LienHolderInformation.IsIndividual')
      }
    };
    // const otherNames = R.path(['YesNo'], values) === YES_OPTION;
    const isCompany =
      R.path([BASE, index, 'isCompany'], values) === TRUE_OPTION;
    const isIndividual =
      R.path([BASE, index, 'isCompany'], values) === FALSE_OPTION;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    const documentErrorView = documentErrorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage
          id="error.submissionError"
          style={styles.documentErrorText}
        />
        <Text style={styles.documentErrorText}>{documentErrorText}</Text>
      </View>
    );

    const disabled = isSubmitting;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={`${BASE}.subtitle`}
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>{errorView}</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.isCompany`}
                  labelId={`${BASE}.IsIndividualOrCompany`}
                  options={isIndividualOrCompanyOptions}
                  disabled={fetching}
                />
              </Col>
            </Row>
            {isCompany && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._firstName = ref;
                      }}
                      field={`${baseString}.companyName`}
                      labelId={`${BASE}.companyName`}
                      autoCapitalize="words"
                      placeholderId={`${BASE}.companyName_placeholder`}
                      returnKeyType="next"
                      required
                      validate={isRequired}
                      onSubmitEditing={e => {
                        this._SSN.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._SSN = ref;
                      }}
                      field={`${baseString}.ssn`}
                      labelId={`${BASE}.ein`}
                      placeholderId={`${BASE}.ein_placeholder`}
                      format={einFormat}
                      maxLength={11}
                      onMaxLength={() =>
                        this._percentOwnership && this._percentOwnership.focus()
                      }
                      // keyboardType="numeric"
                      returnKeyType={'next'}
                      onSubmitEditing={() => {
                        this._percentOwnership &&
                          this._percentOwnership.focus();
                      }}
                      required
                      validate={isEinNumber}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </View>
            )}
            {isIndividual && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._firstName = ref;
                      }}
                      field={`${baseString}.firstName`}
                      labelId="ContactInfo.firstname"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.firstname_placeholder"
                      returnKeyType="next"
                      required
                      validate={isRequired}
                      onSubmitEditing={e => {
                        this._middleName.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._middleName = ref;
                      }}
                      field={`${baseString}.middleName`}
                      labelId="ContactInfo.middlename"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.middlename_placeholder"
                      returnKeyType="next"
                      onSubmitEditing={() => {
                        this._lastName.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._lastName = ref;
                      }}
                      field={`${baseString}.lastName`}
                      labelId="ContactInfo.lastname"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.lastname_placeholder"
                      returnKeyType="next"
                      onSubmitEditing={() => this._DateOfBirthMonth.focus()}
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </View>
            )}
            {(isCompany || isIndividual) && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._firstName = ref;
                    }}
                    field={`${baseString}.percentOwnership`}
                    labelId={`${BASE}.percentOwnership`}
                    autoCapitalize="words"
                    placeholderId={`${BASE}.percentOwnership_placeholder`}
                    keyboardType="numeric"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

PropertyOwnerInformationScreen = withFormik({
  mapPropsToValues: props => {
    const {
      application,
      match: {
        params: { index }
      }
    } = props;
    const initialApplication = JSON.parse(JSON.stringify(application));
    const appBase = getBase(application, BASE, index);

    // Format Amount as Currency
    if (appBase.amount) {
      initialApplication[BASE][index].amount = formatCurrency(appBase.amount);

      initialApplication[BASE][index].receivedFunds = YES_OPTION;
    } else if (R.path([BASE, index, 'phoneNumber'], application)) {
      // Existing Insurance Policy
      initialApplication[BASE][index].receivedFunds = NO_OPTION;
      // change(`${baseString}.receivedFunds`, '1');
    }

    if (appBase && appBase.dateOfExpiration) {
      const dob = moment(appBase.dateOfExpiration, 'M-D-YYYY');
      initialApplication.tmp = {
        Month: dob.format('M'),
        Day: dob.format('D'),
        Year: dob.format('YYYY')
      };
      // console.log('LOADING', initialApplication.tmp);
    } else {
      initialApplication.tmp = {
        Month: '',
        Day: '',
        Year: ''
      };
    }

    return initialApplication;
  },
  isInitialValid: props => true,

  validate: (values, props) => {
    const errors = {};

    const { Month, Day, Year } = values.tmp;
    if (Month && Day && Year) {
      const dob = `${Month}-${Day}-${Year}`;
      const dateOfExpiration = moment(dob, 'M-D-YYYY');
      if (!dateOfExpiration.isValid()) {
        errors.dateOfExpiration = 'error.invalidDate';
      }
    }

    return errors;
  },
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      updateApplication,
      match: {
        params: { index }
      }
    } = props;

    let newValues = JSON.parse(JSON.stringify(values));

    // Remove Non Digits
    if (newValues[BASE][index].amount) {
      newValues[BASE][index].amount = newValues[BASE][index].amount.replace(
        /[^\d.-]/g,
        ''
      );
    }

    const {
      tmp: { Month, Day, Year }
    } = values;

    const dob = `${Month}-${Day}-${Year}`;
    // console.log('SAVING ', dob);

    newValues[BASE][index].dateOfExpiration = dob;

    // Remove Temp Values
    delete newValues.tmp;

    // console.log('updatingApplication', newValues);
    updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(PropertyOwnerInformationScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application },
  document,
  form
}) => ({
  options,
  data,
  initilized,
  fetching,
  errorText: error,

  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,

  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyOwnerInformationScreen);
