import { Platform } from 'react-native';
// a library to wrap and simplify api calls
import apisauce from 'apisauce';

import {
  GROUP,
  PROGRAM_CODE,
  // API_VERSION,
  API_PREFIX,
  API_CREDENTIALS
} from '../Config/ApplicationConfig';

const { API_BASE_URL, AUTHORIZATION, WEB_BASE_URL } = API_CREDENTIALS;

// const API_PREFIX = API_VERSION > 1 ? 'v2/' : '';

export const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL: API_PREFIX ? `${API_BASE_URL}/${API_PREFIX}/` : API_BASE_URL,
  // here are some default headers
  headers: {
    'Content-Type': 'text/plain' // Required to avoid CORS for Web
  },
  // 3 minute timeout
  timeout: 1000 * 60 * 3
});

// our "constructor"
const create = baseURL => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const getProgramCodes = (locale = 'en') => {
    let body = {
      locale,
      group: GROUP
    };
    return api.post(
      `/GetProgramList;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const getPickList = (programCode, locale = 'en') => {
    let body = {
      locale,
      group: GROUP
    };
    if (programCode) body.programCode = programCode;
    return api.post(
      `/GetPickListByGroup;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const getKeywordsOptionsByDocType = (programCode, locale = 'en') => {
    const body = { programCode, locale };
    return api.post(
      `/GetDocumentTypes;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const getScreeningQuestions = (programCode, locale = 'en') => {
    const body = { programCode: programCode, locale };
    return api.post(
      `/GetScreeningQuestions;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const login = (account, locale = 'en') =>
    api.post(
      `/Authentication/User/validate;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(account)
    );

  const createAccount = (account, locale = 'en') =>
    api.post(
      `/Authentication/User/create;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(account)
    );

  // { "userId":"16" }
  const requestOTP = (userId, locale = 'en') =>
    api.post(
      `/Authentication/OTP/create;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify({ userId })
    );

  // { "userId":"16", "otpId":"10" }
  const verifyOTP = (userId, otpId, otp, locale = 'en') =>
    api.post(
      `/Authentication/Token/create;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify({
        userId: userId.toString(),
        otpId: otpId.toString(),
        otp: otp.toString()
      })
    );

  const forgotPassword = (email, locale = 'en') => {
    var getUrl = window && window.location;
    var baseUrl = getUrl && getUrl.protocol + '//' + getUrl.host;
    const resetPasswordURL =
      Platform.OS === 'web'
        ? `${baseUrl}/ChangePasswordScreen?token=`
        : `${WEB_BASE_URL}/ChangePasswordScreen?token=`;
    const body = {
      group: GROUP,
      emailAddress: email,
      locale,
      resetPasswordURL
    };
    // console.log(
    //   `POST ${API_BASE_URL}/Authentication/Password/reset;boomi_auth=${AUTHORIZATION}?locale=${
    //     locale
    //   }`,
    //   JSON.stringify(body, null, 2),
    // );
    return api.post(
      `/Authentication/Password/reset;boomi_auth=${AUTHORIZATION}`,
      JSON.stringify(body)
    );
  };

  const changePassword = (password, token, locale = 'en') => {
    const body = {
      newPassword: password,
      locale,
      resetPasswordToken: token
    };
    return api.post(
      `/Authentication/Password/update;boomi_auth=${AUTHORIZATION}?locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const getApplication = (
    userId,
    tokenId,
    token,
    applicationId,
    locale = 'en'
  ) =>
    // console.log(`GET ${API_BASE_URL}/Application/${API_PREFIX}${applicationId};boomi_auth=${
    //   AUTHORIZATION
    // }?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`);
    api.get(
      `/Application/${applicationId};boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}`
    );

  const getApplications = (userId, tokenId, token, locale = 'en') =>
    api.get(
      `/User/Applications;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`
    );

  const createApplication = (
    userId,
    tokenId,
    token,
    application,
    locale = 'en'
  ) => {
    if (userId) {
      return api.post(
        `/Application/upsert;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
        application
      );
    }
    return api.post(
      `/Application/upsert;boomi_auth=${AUTHORIZATION}?userId=-999`,
      application
    );
  };

  const updateApplication = (
    userId,
    tokenId,
    token,
    application,
    locale = 'en'
  ) =>
    // console.log(
    //   `POST ${API_BASE_URL}/Application/upsert;boomi_auth=${AUTHORIZATION}?userid=${
    //     userId
    //   }&authtokenid=${tokenId}&authtoken=${token}&locale=${locale}`,
    //   JSON.stringify(application),
    // );
    api.post(
      `/Application/upsert;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
      JSON.stringify(application)
    );
  const getDocuments = (
    userId,
    tokenId,
    token,
    applicationId,
    docTypeCode,
    locale = 'en'
  ) =>
    // console.log(`GET ${API_BASE_URL}/GetDocumentList;boomi_auth=${AUTHORIZATION}?userid=${
    //   userId
    // }&authtokenid=${tokenId}&authtoken=${token}&applicationid=${applicationId}&locale=${locale}`);
    docTypeCode
      ? api.get(
          `/GetDocumentList;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&applicationId=${applicationId}&doctypecode=${docTypeCode}&locale=${locale}`
        )
      : api.get(
          `/GetDocumentList;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&applicationId=${applicationId}&locale=${locale}`
        );

  const uploadDocuments = (
    userId,
    tokenId,
    token,
    applicationId,
    documentId,
    data,
    locale = 'en'
  ) => {
    if (userId) {
      if (!documentId || documentId === '-1' || documentId === -1) {
        return api.post(
          `/Document/${applicationId};boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
          data
        );
      }
      console.log(
        `GET ${API_BASE_URL}/Document/${applicationId};boomi_auth=${AUTHORIZATION}?documentId=${documentId}&userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
        data
      );
      return api.post(
        `/Document/${applicationId};boomi_auth=${AUTHORIZATION}?documentId=${documentId}&userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
        data
      );
    }
    console.log(
      `GET ${API_BASE_URL}/Document/${applicationId};boomi_auth=${AUTHORIZATION}?userId=-999&locale=${locale}`,
      data
    );
    return api.post(
      `/Document/${applicationId};boomi_auth=${AUTHORIZATION}?userId=-999&locale=${locale}`,
      data
    );
  };

  /**
   * Available Types:
   * 'Co-Applicant'
   * 'Land Owner'
   * 'Communication Designee'
   * 'Power of Attorney'
   * 'Lien Holder'
   * 'Household Member'
   * 'Insurance'
   * 'Additional Funds'
   * 'Document'
   */
  const deleteRecord = (
    type,
    userId,
    tokenId,
    token,
    applicationId,
    documentId,
    locale = 'en'
  ) => {
    const body = {
      applicationId: applicationId.toString(),
      id: documentId.toString(),
      type
    };

    console.log(
      `/Record/delete;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
      JSON.stringify(body)
    );

    return api.post(
      `/Record/delete;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
      JSON.stringify(body)
    );
  };

  const createDocusign = (userId, tokenId, token, body, locale = 'en') => {
    console.log(
      `/DocuSignEnvelope/upsert;boomi_auth=${AUTHORIZATION}??userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}&ts=${new Date().getTime()}`,
      JSON.stringify(body)
    );

    return api.post(
      `/DocuSignEnvelope/upsert;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}&ts=${new Date().getTime()}`,
      JSON.stringify(body)
    );
  };

  const sendLog = (userId, tokenId, token, body, locale = 'en') => {
    console.log(
      `/SendLog;boomi_auth=${AUTHORIZATION}??userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}&ts=${new Date().getTime()}`,
      JSON.stringify(body)
    );

    return api.post(
      `/SendLog;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}&ts=${new Date().getTime()}`,
      JSON.stringify(body)
    );
  };

  /* Status Tracker APIs */
  const getCases = (userId, tokenId, token, locale = 'en') => {
    console.log(
      `getCases: /Portal/getCases;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`
    );
    const body = {
      userId,
      locale
    };
    return api.post(
      `/Portal/getCases;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
      JSON.stringify(body)
    );
  };
  const verifyCaseLinkCode = (
    userPortalCaseLinkCode,
    userId,
    tokenId,
    token,
    locale = 'en'
  ) => {
    const body = {
      userId: userId.toString(),
      userPortalCaseLinkCode: userPortalCaseLinkCode.toString()
    };
    return api.post(
      `/Portal/verifyCaseLinkCode;boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}&locale=${locale}`,
      JSON.stringify(body)
    );
  };

  // TODO: Upload Document APIs

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    getProgramCodes,
    getPickList,
    getKeywordsOptionsByDocType,
    getScreeningQuestions,
    login,
    createAccount,
    requestOTP,
    verifyOTP,
    forgotPassword,
    changePassword,
    getApplication,
    getApplications,
    createApplication,
    updateApplication,

    getDocuments,
    uploadDocuments,
    deleteRecord,
    createDocusign,

    getCases,
    verifyCaseLinkCode,

    sendLog
  };
};

// let's return back our create method as the default.
export default {
  create
};
