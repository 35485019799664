import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';
import OptionsActions from '../Redux/OptionsRedux';
import PersistActions from '../Redux/PersistRedux';
import {
  parseProgramCodesJson,
  parsePickerJson,
  parseDocTypesJson,
  parseScreeningQuestionsJson
} from '../Redux/OptionsRedux';
import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

const getLocale = state => state.i18n.locale;
const getPersist = state => state.persist;
const getApplication = state => state.application;

export function* getProgramCodes(api) {
  const locale = yield select(getLocale);

  let enResults = '';
  let esResults = '';
  try {
    enResults = yield call(api.getProgramCodes, 'en');
    esResults = yield call(api.getProgramCodes, 'en'); // TODO: Change back to es

    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const allProgramCodes = parseProgramCodesJson(
        enResults.data,
        esResults.data
      );
      // console.log(
      //   'TCL: function*getProgramCodes -> allProgramCodes',
      //   allProgramCodes
      // );

      console.log('saving program codes', locale || DEFAULT_LANGUAGE);
      yield put(
        PersistActions.PersistSetProgramCodes(
          allProgramCodes[locale || DEFAULT_LANGUAGE]
        )
      );
      yield put(PersistActions.PersistSetAllProgramCodes(allProgramCodes));
      yield put(OptionsActions.OptionsSuccess(allProgramCodes));
    } else {
      yield put(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      yield put(OptionsActions.OptionsFailure(error));
    }
  }
}
export function* getOptions(api, { programCode }) {
  const locale = yield select(getLocale);
  const { allOptions } = yield select(getPersist);

  let enResults = '';
  let esResults = '';
  try {
    enResults = yield call(api.getPickList, programCode, 'en');
    // console.log('TCL: function*getOptions -> enResults', enResults);
    esResults = yield call(api.getPickList, programCode, 'es');
    // console.log('TCL: function*getOptions -> esResults', esResults);

    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newOptions = allOptions.set(
        programCode,
        parsePickerJson(enResults.data, esResults.data)
      );

      console.log('saving options', locale || DEFAULT_LANGUAGE);
      yield put(
        PersistActions.PersistSetOptions(
          newOptions[programCode][locale || DEFAULT_LANGUAGE]
        )
      );
      yield put(PersistActions.PersistSetAllOptions(newOptions));
      yield put(OptionsActions.OptionsSuccess(newOptions));
      yield put(OptionsActions.OptionsSetProgramCode(programCode));
    } else {
      yield put(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      yield put(OptionsActions.OptionsFailure(error));
    }
  }
}

export function* getDocTypes(api, { programCode }) {
  const locale = yield select(getLocale);
  const { allDocTypes } = yield select(getPersist);

  let enResults = '';
  let esResults = '';
  // console.log(api, programCode);
  try {
    enResults = yield call(api.getKeywordsOptionsByDocType, programCode, 'en');
    esResults = yield call(api.getKeywordsOptionsByDocType, programCode, 'es');
    // console.log(
    //   'getDocTypes results',
    //   JSON.stringify({ enResults, esResults }, null, 2)
    // );
    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newDocTypes = allDocTypes.set(
        programCode,
        parseDocTypesJson(enResults.data, esResults.data)
      );

      console.log('saving DocTypes', locale || DEFAULT_LANGUAGE);
      yield put(
        PersistActions.PersistSetDocTypes(
          newDocTypes[programCode][locale || DEFAULT_LANGUAGE]
        )
      );
      yield put(PersistActions.PersistSetAllDocTypes(newDocTypes));
      yield put(OptionsActions.OptionsSuccess(newDocTypes));
      yield put(OptionsActions.OptionsSetProgramCode(programCode));
    } else {
      yield put(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    console.log(error);
    if (R.path(['data', 'payload'], error)) {
      yield put(OptionsActions.OptionsFailure(error));
    }
  }
}

export function* getScreeningQuestions(api, { programCode }) {
  const locale = yield select(getLocale);
  const { allScreeningQuestions } = yield select(getPersist);

  let enResults = '';
  let esResults = '';
  try {
    enResults = yield call(api.getScreeningQuestions, programCode, 'en');
    esResults = yield call(api.getScreeningQuestions, programCode, 'es');
    // console.log(
    //   'getScreeningQuestions results',
    //   JSON.stringify({ enResults, esResults }, null, 2)
    // );
    if (
      R.path(['data', 'payload'], enResults) &&
      R.path(['data', 'payload'], esResults)
    ) {
      const newScreeningQuestions = allScreeningQuestions.set(
        programCode,
        parseScreeningQuestionsJson(enResults.data, esResults.data)
      );

      console.log('saving Screening Questions', locale || DEFAULT_LANGUAGE);
      yield put(
        PersistActions.PersistSetScreeningQuestions(
          newScreeningQuestions[programCode][locale || DEFAULT_LANGUAGE]
        )
      );
      yield put(
        PersistActions.PersistSetAllScreeningQuestions(newScreeningQuestions)
      );
      yield put(OptionsActions.OptionsSuccess(newScreeningQuestions));
    } else {
      yield put(OptionsActions.OptionsFailure('Unknown Error'));
    }
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      yield put(OptionsActions.OptionsFailure(error));
    }
  }
}
