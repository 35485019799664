import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, Modal, View, Text, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import moment from 'moment';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import Icon from 'react-native-vector-icons/dist/Ionicons';
// import Icon from 'react-native-vector-icons/dist/FontAwesome';
import FullButton from './FullButton';
import { Grid, Row, Col } from './Grid';

import CaptureUploadDocuments from './CaptureUploadDocuments';
import UploadDocumentType from './UploadDocumentType';

import FormattedMessage from './FormattedMessage';

import ScanBarcode from './ScanBarcode';
import TakePicture from './TakePicture';

import PersistActions from '../Redux/PersistRedux';

import DocumentActions, { getDocumentType } from '../Redux/DocumentRedux';

import IdentityProof from './IdentityProof';

import { getPersonId } from '../Lib/Utils';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from '../Containers/Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from './Footer';

const DOCUMENT_TYPE = 'proofOfIdentification';
const DRIVERS_LICENSE_KEYWORD = 'DriversLicense';

const BASE_HOMEOWNER = DATA_STRUCTURE.applicant;
const BASE_CONTACT = DATA_STRUCTURE.contact;

function getStateOptionByShortState(shortState, options) {
  const states = new Array(
    { name: 'Alabama', abbrev: 'AL' },
    { name: 'Alaska', abbrev: 'AK' },
    { name: 'Arizona', abbrev: 'AZ' },
    { name: 'Arkansas', abbrev: 'AR' },
    { name: 'California', abbrev: 'CA' },
    { name: 'Colorado', abbrev: 'CO' },
    { name: 'Connecticut', abbrev: 'CT' },
    { name: 'Delaware', abbrev: 'DE' },
    { name: 'Florida', abbrev: 'FL' },
    { name: 'Georgia', abbrev: 'GA' },
    { name: 'Hawaii', abbrev: 'HI' },
    { name: 'Idaho', abbrev: 'ID' },
    { name: 'Illinois', abbrev: 'IL' },
    { name: 'Indiana', abbrev: 'IN' },
    { name: 'Iowa', abbrev: 'IA' },
    { name: 'Kansas', abbrev: 'KS' },
    { name: 'Kentucky', abbrev: 'KY' },
    { name: 'Louisiana', abbrev: 'LA' },
    { name: 'Maine', abbrev: 'ME' },
    { name: 'Maryland', abbrev: 'MD' },
    { name: 'Massachusetts', abbrev: 'MA' },
    { name: 'Michigan', abbrev: 'MI' },
    { name: 'Minnesota', abbrev: 'MN' },
    { name: 'Mississippi', abbrev: 'MS' },
    { name: 'Missouri', abbrev: 'MO' },
    { name: 'Montana', abbrev: 'MT' },
    { name: 'Nebraska', abbrev: 'NE' },
    { name: 'Nevada', abbrev: 'NV' },
    { name: 'New Hampshire', abbrev: 'NH' },
    { name: 'New Jersey', abbrev: 'NJ' },
    { name: 'New Mexico', abbrev: 'NM' },
    { name: 'New York', abbrev: 'NY' },
    { name: 'North Carolina', abbrev: 'NC' },
    { name: 'North Dakota', abbrev: 'ND' },
    { name: 'Ohio', abbrev: 'OH' },
    { name: 'Oklahoma', abbrev: 'OK' },
    { name: 'Oregon', abbrev: 'OR' },
    { name: 'Pennsylvania', abbrev: 'PA' },
    { name: 'Rhode Island', abbrev: 'RI' },
    { name: 'South Carolina', abbrev: 'SC' },
    { name: 'South Dakota', abbrev: 'SD' },
    { name: 'Tennessee', abbrev: 'TN' },
    { name: 'Texas', abbrev: 'TX' },
    { name: 'Utah', abbrev: 'UT' },
    { name: 'Vermont', abbrev: 'VT' },
    { name: 'Virginia', abbrev: 'VA' },
    { name: 'Washington', abbrev: 'WA' },
    { name: 'West Virginia', abbrev: 'WV' },
    { name: 'Wisconsin', abbrev: 'WI' },
    { name: 'Wyoming', abbrev: 'WY' }
  );
  let found = null;
  states.forEach(ele => {
    if (ele.abbrev === shortState) {
      Object.keys(options.state).forEach(key => {
        if (options.state[key].toUpperCase() === ele.name.toUpperCase()) {
          found = key;
        }
      });
    }
  });
  return found.toString();
}

class ScanIDWeb extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    state: 'none',
    scanContents: null,
    photo: null,
    width: null,
    height: null
  };

  loadedDocuments = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  componentDidMount() {
    // this.props.loadDocuments(DOCUMENT_TYPE);
  }

  // renderInput = ({
  //   personId,
  //   applicationId,
  //   input: { value, onChange, ...restInput }
  // }) => {
  //   // const documentType = getDocumentType(application, DOCUMENT_TYPE);
  //   return(

  //   <CaptureUploadDocuments
  //     applicationId={applicationId}
  //     personId={personId}
  //     documentType={DOCUMENT_TYPE}
  //     documents={value}
  //     setDocuments={onChange}
  //     horiz
  //   />
  // )};

  handleScanBarcode = () => {
    this.setState({ state: 'scan' });
  };
  onBarcodeScan = data => {
    const { base, index, options, change } = this.props;

    const basePath = index >= 0 ? `${base}[${index}]` : base;

    // Force firstName to be data or blank
    change(`${basePath}.firstName`, (data && data.firstName) || '');

    if (data) {
      if (data.middleName) change(`${basePath}.middleName`, data.middleName);
      if (data.lastName) change(`${basePath}.lastName`, data.lastName);
      if (data.gender) change(`${basePath}.gender`, data.gender);
      if (data.dateOfBirth) {
        change(
          `${basePath}.dateOfBirth`,
          moment(data.dateOfBirth).format('MM-DD-YYYY')
        );
      }

      if (base === BASE_HOMEOWNER) {
        const contactPath =
          index >= 0 ? `${BASE_CONTACT}[${index}]` : BASE_CONTACT;
        if (data.address1) change(`${contactPath}.address1`, data.address1);
        if (data.city) change(`${contactPath}.city`, data.city);
        if (data.zip) change(`${contactPath}.zip`, data.zip.substring(0, 5));
        if (data.state) {
          const trimmedState = data.state.replace(/^\s+|\s+$/g, '');
          change(
            `${contactPath}.stateCode`,
            getStateOptionByShortState(trimmedState, options)
          );
        }
      }

      // saveApplication(newApplication)
      this.setState({ scanContents: data, state: 'done' });
    }
  };

  handleUploadFiles = photos => {
    console.log(photos);

    const { base, index, application, uploadDocuments } = this.props;

    const personId = getPersonId(application, base, index);

    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];

    this.uploadDocuments = true;
    console.log(
      'uploadDocuments',
      personId,
      photos,
      documentType,
      documentKeywordValue
    );
    uploadDocuments(personId, photos, documentType, documentKeywordValue);
  };

  handleDeleteFiles = () => {
    const { base, index, application, deleteDocuments } = this.props;

    const personId =
      index >= 0
        ? R.pathOr(0, [base, index, 'id'], application) > 0
          ? R.path([base, index, 'id'], application)
          : `${base}_${index}`
        : R.pathOr(0, [base, 'id'], application) > 0
        ? R.path([base, 'id'], application)
        : base;

    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];

    this.deletingDocuments = true;
    console.log(
      'deleteDocuments',
      personId,
      documentType,
      documentKeywordValue
    );
    deleteDocuments(personId, documentType, documentKeywordValue);
  };

  handleClose = () => {
    this.setState({ state: 'done' });
  };

  handleSkip = () => {
    this.setState({ scanContents: false });
  };
  handleAddOther = () => {
    this.setState({ state: 'other' });
  };

  onSubmit = values => {
    const { setApplication, onComplete, options, base, index } = this.props;

    const { scanContents } = this.state;

    if (scanContents) {
      if (index >= 0) {
        if (!values[base]) {
          values[base] = [];
        }
        if (!values[base][index]) {
          values[base][index] = {};
        }
        if (scanContents.firstName)
          values[base][index].firstName = scanContents.firstName;
        if (scanContents.middleName)
          values[base][index].middleName = scanContents.middleName;
        if (scanContents.lastName)
          values[base][index].lastName = scanContents.lastName;
        if (scanContents.gender)
          values[base][index].gender = scanContents.gender;
      } else {
        if (!values[base]) {
          values[base] = {};
        }
        if (scanContents.firstName)
          values[base].firstName = scanContents.firstName;
        if (scanContents.middleName)
          values[base].middleName = scanContents.middleName;
        if (scanContents.lastName)
          values[base].lastName = scanContents.lastName;
        if (scanContents.gender) values[base].gender = scanContents.gender;
      }

      if (scanContents.dateOfBirth) {
        if (index >= 0) {
          values[base][index].dateOfBirth = moment(
            scanContents.dateOfBirth
          ).format('MM-DD-YYYY');
        } else {
          values[base].dateOfBirth = moment(scanContents.dateOfBirth).format(
            'MM-DD-YYYY'
          );
        }
      }

      if (base === BASE_HOMEOWNER) {
        if (!values[BASE_CONTACT]) {
          values[BASE_CONTACT] = {};
        }
        if (scanContents.address1)
          values[BASE_CONTACT].address = scanContents.address1;
        if (scanContents.city) values[BASE_CONTACT].city = scanContents.city;
        if (scanContents.zip)
          values[BASE_CONTACT].zip = scanContents.zip.substring(0, 5);

        if (scanContents.state) {
          const trimmedState = scanContents.state.replace(/^\s+|\s+$/g, '');
          values[BASE_CONTACT].state = getStateOptionByShortState(
            trimmedState,
            options
          );
        }
      }
    }

    console.log('ScanID Saving', base, values);
    setApplication(values);
    onComplete();
  };

  render() {
    const {
      application,
      initilized,
      base,
      index,
      onComplete,
      documents,
      documentsFetching
    } = this.props;

    const { state, scanContents } = this.state;

    if (state === 'other') {
      return (
        <IdentityProof
          base={base}
          index={index}
          onComplete={this.props.onComplete}
        />
      );
    }

    const personId = getPersonId(application, base, index);
    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    const documentKeywordValue = documentType.keywords[DRIVERS_LICENSE_KEYWORD];

    const identityDocuments = [];
    documents &&
      documents.forEach(ele => {
        if (
          ele &&
          ele.personId === personId &&
          ele.documentType === documentType &&
          ele.keywordValue === documentKeywordValue
        ) {
          identityDocuments.push({ uri: ele.fileDownloadURL });
        }
      });

    return (
      <View style={styles.flex}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id={`${base}.scanTitle`}
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="scanid.subtitleWeb"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row style={{ alignItems: 'flex-end' }}>
              <Col xs={12} style={{ paddingTop: Metrics.doubleBaseMargin }}>
                <FormattedMessage
                  id="scanid.subtitle3"
                  style={[Fonts.style.normal, { textAlign: 'center' }]}
                />
                <View>
                  <UploadDocumentType
                    base={base}
                    index={index}
                    documentType={documentType.code}
                    documentKeywordValue={documentKeywordValue}
                    single
                    horiz
                  />
                  {/* <CaptureUploadDocuments
                    personId={personId}
                    documentType={DOCUMENT_TYPE}
                    documents={identityDocuments}
                    setDocuments={this.handleUploadFiles}
                    deleteDocuments={this.handleDeleteFiles}
                    horiz
                    disabled={documentsFetching}
                  /> */}
                </View>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={onComplete}
                />

                <FullButton
                  text={I18n.t('scanid.dontHaveDriversLicence')}
                  type="small"
                  onPress={this.handleAddOther}
                />

                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized, fetching, error },
  persist: { application },
  options: { options },
  document
}) => ({
  options,
  initilized,
  fetching,
  errorText: error,

  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,

  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  setApplication: application =>
    dispatch(PersistActions.PersistSetApplication(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

// ScanID = reduxForm({ destroyOnUnmount: true, form: 'ScanID' })(ScanID);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanIDWeb);
