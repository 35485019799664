export const API_VERSION = 2;

// export const API_PREFIX = 'PR';
export const API_PREFIX = "VI";

export const API_ENVIROMENT = "QA"; // DEV | QA | PROD

export const GOOGLE_ANALYTIC_CODE = "UA-138239120-1";
export const PRIVACY_POLICY_LINK = "https://onbase.hornellp.com/privacy/";

// export const GROUP = 'North Carolina - Hurricane Matthew';
// export const GROUP = 'Puerto Rico - Hurricanes Irma/Maria';
export const GROUP = "Virgin Islands - Hurricanes Irma/Maria";

// export const PROGRAM_CODE = '2'; // North Carolina
// export const PROGRAM_CODE = '4'; // PR
export const PROGRAM_CODE = "5"; // US Virgin Islands
export const RENTAL_PROGRAM_CODE = "6";

export const YES_OPTION = "2";
export const NO_OPTION = "1";

export const TRUE_OPTION = "1";
export const FALSE_OPTION = "0";

export const MOBILE_HOME_CODE = "2";

// Address Types  UNUSED USVI
export const ADDRESS_TYPE_PO_BOX = "1";
export const ADDRESS_TYPE_RURAL_ROUTE = "2";
export const ADDRESS_TYPE_HIGHWAY_CONTRACT = "3";
export const ADDRESS_TYPE_RESIDENTIAL = "4";
export const ADDRESS_TYPE_APARTMENT = "5";

// export const DEFAULT_STATE_CODE = '33'; // North Carolina
export const DEFAULT_STATE_CODE = "52"; // Virgin Islands

export const REFERRAL_VOAD = "10"; // Unused USVI
export const REFERRAL_OTHER = "12";

export const JOINT_TAX_RETURN = "2";

export const ORGIN_CODE_WEB = "10";
export const ORGIN_CODE_MOBILE = "11";

export const DEFAULT_LANGUAGE = "en";
// export const DEFAULT_LANGUAGE = 'es';

export const LANGUAGE_CODE_EN = "1";
export const LANGUAGE_CODE_ES = "2";

export const VENDOR_NAME = "Contravent";

export const STATUS_IN_PROGRESS = "In Progress";
export const STATUS_SUBMITTED = "Submitted";

export const STATUS_SUBMITTED_PENDING_DOCUSIGN = "Submitted Pending Docusign";

const ALL_API_CREDENTIALS = {
  PROD: {
    API_BASE_URL: "https://api-canopy.hornellp.com/ws/rest/GS/Intake",
    AUTHORIZATION:
      "Z3NpbnRha2VAaG9ybmVsbHAtQkY2WUJTOmFjMTgyZDczLTA1ZmItNDg2NC05Y2RmLWY3MjMwMzY1OGI3MQ==",
    WEB_BASE_URL: "https://horne-pr-dev.psg-labs.com",
  },
  QA: {
    API_BASE_URL: "https://api-canopy-qa.hornellp.com/ws/rest/GS/Intake",
    AUTHORIZATION:
      "Z3NpbnRha2UtcWFAaG9ybmVsbHAtQkY2WUJTOjg4NmJkOTgwLTcyNDctNGQ1ZS05ZmE1LTQzMGJmNzE0NjYzOQ==",
    WEB_BASE_URL: "https://horne-pr-dev.psg-labs.com",
  },
  DEV: {
    API_BASE_URL: "https://api-canopy-dev.hornellp.com/ws/rest/GS/Intake",
    AUTHORIZATION:
      "Z3NpbnRha2UtZGV2QGhvcm5lbGxwLUJGNllCUzpiODg4MjA3Zi1mMDM5LTRiMjYtOGM0MS1kNzk4MDVhZmYxMmE=",
    WEB_BASE_URL: "https://horne-pr-dev.psg-labs.com",
  },
};

export const API_CREDENTIALS = ALL_API_CREDENTIALS[API_ENVIROMENT];

export const IMAGE_RESIZE_MAX = 3000;
export const IMAGE_RESIZE_QUALITY = 60;
