import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';

import { View } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import { Grid, Row, Col } from '../Components/Grid';
import { isRequired } from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE = 'enterHome';

export const FloodWaterEnterHomeScreenComplete = application => {
  return R.pathOr('N/A', ['didWaterEnterHomeCode'], application) !== 'N/A';
};

class FloodWaterEnterHomeScreen extends Component {
  state = {
    errorText: null
  };

  updatingApplication = false;

  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('InsuranceScreen');
      }
    }
  }

  render() {
    const {
      initilized,
      handleSubmit,
      application,
      values,
      isValid,
      fetching
    } = this.props;

    if (!initilized) return null;

    // const isMobileHome =
    //   R.path([BASE_DAMAGED_ADDRESS, 'propertyType'], application) ===
    //   MOBILE_HOME_CODE;
    const floodWaterEnterHome =
      R.path(['didWaterEnterHomeCode'], values) === YES_OPTION;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.WaterEnterHome"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="DamagedAddress.WaterEnterHomeDescription"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field="didWaterEnterHomeCode"
                  optionsKey="yes_no"
                  validate={isRequired}
                  reverse
                  disabled={fetching}
                />
              </Col>
            </Row>

            {floodWaterEnterHome && (
              <View>
                <Row>
                  <Col>
                    <FormattedMessage
                      id="DamagedAddress.ReachOutletsDescription"
                      style={Fonts.style.subtitle}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field="didWaterReachOutletsCode"
                      labelId=""
                      optionsKey="yes_no"
                      reverse
                      validate={isRequired}
                      disabled={fetching}
                    />
                  </Col>
                </Row>
              </View>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  disabled={!isValid || fetching}
                  onPress={handleSubmit}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

FloodWaterEnterHomeScreen = withFormik({
  mapPropsToValues: props => {
    const { application } = props;

    return application;
  },
  isInitialValid: props => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;
    console.log('updatingApplication', values);
    updateApplication(values);
    setStatus('updatingApplication');
  }
})(FloodWaterEnterHomeScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error },
  persist: { options, application }
}) => ({
  options,
  initilized,
  fetching,
  errorText: error,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloodWaterEnterHomeScreen);
