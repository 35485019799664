// leave off @2x/@3x
const images = {
  logo: require('../Images/usvi-logo-blue.png'),
  hudWhite: require('../Images/hud-white.png'),
  vihfaWhite: require('../Images/vihfa-white.png'),
  docIcon: require('../Images/Icons/doc-icon.png'),
  docxIcon: require('../Images/Icons/docx-icon.png'),
  pdfIcon: require('../Images/Icons/pdf-icon.png')
};

export default images;
