import React, { Component } from 'react';
import * as R from 'ramda';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

// import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from '../../Components/Grid';

import { isRequired } from '../../Lib/Utils';

import DocumentActions, {
  getDocumentURL,
  getDocumentType
} from '../../Redux/DocumentRedux';

import FormattedMessage from '../../Components/FormattedMessage';
import FormGroupRadio from '../../Components/FormGroupRadio';
import FullButton from '../../Components/FullButton';
import SelectUploadDocumentType from '../../Components/SelectUploadDocumentType';

// import { getPersonId } from '../../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../../Themes/';
import styles from '../Styles/ScreenStyles';

import DOCUMENT_TYPES from '../../Config/DocumentTypesConfig';
import Footer from '../../Components/Footer';

const DOCUMENT_TYPE = 'proofOfIncome';
// const TAX_RETURN_KEYWORD_VALUE =
//   DOCUMENT_TYPES.proofOfIncome.keywords.TaxReturn;

// const PROOF_OF_INCOME_TAX_RETURN_KEYWORD_VALUE =
//   DOCUMENT_TYPES.proofOfIncome.keywords.TaxReturn;

class OtherIncome extends Component {
  render() {
    const { application, base, index, onComplete } = this.props;

    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    // if (!initilized) return null;

    return (
      <KeyboardAwareScrollView
        ref={scroll => {
          this._scroll = scroll;
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id="OtherIncome.title"
                style={Fonts.style.title}
              />
              <FormattedMessage
                id="OtherIncome.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>
          <SelectUploadDocumentType
            documentType={documentType.code}
            // filter={TAX_RETURN_KEYWORD_VALUE}
            scrollToTop={() => this._scroll.scrollTo({ x: 0, y: 0 })}
            base={base}
            index={index}
            onComplete={onComplete}
          />
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    );
  }
}

// OtherIncome = withFormik({
//   // mapPropsToValues: props => props.application,
//   mapPropsToValues: props => {
//     return {
//       Documents: [],
//       KeywordValue: null
//     };
//   },
//   handleSubmit: (values, { props }) => {
//     // props.updateApplication(values);
//     const { application, uploadDocuments, base, index } = props;

//     const personId = getPersonId(application, base, index);

//     const { Documents, KeywordValue } = values;

//     // this.uploadingDocuments = true;
//     console.log(personId, Documents, DOCUMENT_TYPE, KeywordValue);
//     uploadDocuments(personId, Documents, DOCUMENT_TYPE, KeywordValue);
//   }
// })(OtherIncome);

const mapStateToProps = ({ persist: { application } }) => ({
  application
});

OtherIncome = connect(mapStateToProps)(OtherIncome);

export default OtherIncome;
