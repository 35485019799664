import { StyleSheet } from 'react-native';
import { Metrics, Fonts, Colors } from '../../Themes/';

export default StyleSheet.create({
  background: {
    width: '100%',
    backgroundColor: Colors.headerBackground,
    flexDirection: 'row',
    paddingTop: Metrics.doubleBaseMargin * 2,
    paddingBottom: Metrics.doubleBaseMargin * 2
  },
  logoContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: 100,
    height: 100,
    resizeMode: 'contain'
  }
});
