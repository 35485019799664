import React, { Component } from 'react';
import { TouchableOpacity, TextInput, Button, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId,
  getBase,
  getBaseString
} from '../Lib/Utils';

import FormGroupText from '../Components/FormGroupText';
import ErrorMessage from '../Components/ErrorMessage';
import SuccessMessage from '../Components/SuccessMessage';
import FormattedMessage from '../Components/FormattedMessage';

import FullButton from '../Components/FullButton';

import AccountActions from '../Redux/AccountRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Metrics } from '../Themes/';
import Footer from '../Components/Footer';

class ForgotPasswordScreen extends Component {
  state = {
    errorText: null,
    resetSuccess: false
  };

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setSubmitting
    } = nextProps;

    if (isSubmitting && status === 'resetPassword' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        this.setState({ errorText: null, resetSuccess: true });
      }
    }
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      isValid,
      history,
      fetching
    } = this.props;
    const { errorText, resetSuccess } = this.state;
    const disabled = fetching;

    const successText = resetSuccess && I18n.t('ForgotPassword.successMessage');

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="ForgotPassword.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="ForgotPassword.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <SuccessMessage text={successText} />
                <ErrorMessage errorText={errorText} />
                <FormGroupText
                  inputRef={ref => {
                    this._email = ref;
                  }}
                  field="email"
                  labelId="ContactInfo.Email"
                  placeholderId="ContactInfo.Email_placeholder"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  spellCheck={false}
                  required
                  validate={isRequiredEmail}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t(
                    resetSuccess
                      ? 'ForgotPassword.resendPassword'
                      : 'ForgotPassword.sendPassword'
                  )}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TouchableOpacity
                  onPress={() => history.push('LoginScreen')}
                  style={{ marginTop: Metrics.doubleBaseMargin }}
                >
                  <FormattedMessage
                    id="Login.returnToLogin"
                    style={[
                      Fonts.style.base,
                      {
                        textAlign: 'center',
                        textDecorationLine: 'underline'
                      }
                    ]}
                  />
                </TouchableOpacity>
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

ForgotPasswordScreen = withFormik({
  mapPropsToValues: props => ({
    email: ''
  }),
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { email } = values;
    props.forgotPassword(email);
    setStatus('resetPassword');
  }
})(ForgotPasswordScreen);

const mapStateToProps = ({
  persist: { account },
  app: { initilized },
  account: { data, fetching, error }
}) => ({
  initilized,
  account,
  data,
  fetching,
  errorText: error
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(AccountActions.AccountForgotPassword(email))
});

ForgotPasswordScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);

export default ForgotPasswordScreen;
