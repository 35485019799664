import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Text, View } from 'react-native';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Grid, Row, Col } from '../../Components/Grid';

import ApplicationActions from '../../Redux/ApplicationRedux';

import FormattedMessage from '../../Components/FormattedMessage';
import FormGroupText from '../../Components/FormGroupText';
import FormGroupRadio from '../../Components/FormGroupRadio';
import FormGroupSelect from '../../Components/FormGroupSelect';
import FullButton from '../../Components/FullButton';
import {
  isRequired,
  isSsnNumber,
  ssnFormat,
  isZipcode,
  zipcodeFormat
} from '../../Lib/Utils';

import { getBase, getBaseString } from '../../Lib/Utils';

// Styles
import styles from '../Styles/ScreenStyles';
import { Fonts, Metrics } from '../../Themes/';

import {
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  JOINT_TAX_RETURN,
  YES_OPTION,
  TRUE_OPTION
} from '../../Config/ApplicationConfig';
import Footer from '../../Components/Footer';

class IRS4506T extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    onComplete: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired
  };

  state = {
    showWhy: false,
    errorText: null
  };

  updatingApplication = false;
  formInitilized = false;

  componentDidMount() {
    const { initilized, application } = this.props;

    // if (initilized && application && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(this.props);
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      status,
      setStatus,
      initilized,
      application,
      onComplete,
      isSubmitting,
      setSubmitting
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);

      if (errorText) {
        this.setState({ errorText });
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        console.log('onComplete', data);
        onComplete('IRS4506TSignature');
      }
    }
    // if (isSubmitting && !fetching && errorText) {
    //   setSubmitting(false);
    //   this.setState({ errorText });
    //   // Scroll to top
    //   this._scroll.scrollTo({ x: 0, y: 0 });
    // } else if (isSubmitting && !fetching && data) {
    //   // setSubmitting(false);
    //   console.log('onComplete', data);
    //   onComplete('IRS4506TSignature');
    // }

    // if (this.updatingApplication && !fetching) {
    //   this.updatingApplication = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //     // Scroll to top
    //     this._scroll.scrollTo({ x: 0, y: 0 });
    //   } else {
    //   }
    // }
  }

  // initilizeForm(props) {}

  // onSubmit = values => {
  //   this.updatingApplication = true;
  //   this.props.updateApplication(values);
  // };

  renderAddress(addressTypeCode) {
    const { fetching, base, index } = this.props;

    const baseString = getBaseString(base, index);

    let addressHeader = null;
    addressTypeCode = ADDRESS_TYPE_RESIDENTIAL;
    switch (addressTypeCode) {
      // default:
      // case ADDRESS_TYPE_PO_BOX:
      //   addressHeader = (
      //     <Row>
      //       <Col sm={12}>
      //         <FormGroupText
      //           inputRef={ref => {
      //             this._boxNumber = ref;
      //           }}
      //           field={`${baseString}.taxReturn.previousAddressBoxNumber`}
      //           labelId="ContactInfo.boxNumber"
      //           placeholderId="ContactInfo.boxNumber_placeholder"
      //           autoCapitalize="words"
      //           returnKeyType="next"
      //           onSubmitEditing={() => this._City.focus()}
      //           required
      //           validate={isRequired}
      //           disabled={fetching}
      //         />
      //       </Col>
      //     </Row>
      //   );
      //   break;
      // case ADDRESS_TYPE_RURAL_ROUTE:
      // case ADDRESS_TYPE_HIGHWAY_CONTRACT:
      //   addressHeader = (
      //     <View>
      //       <Row>
      //         <Col sm={12}>
      //           <FormGroupText
      //             inputRef={ref => {
      //               this._Address1 = ref;
      //             }}
      //             field={`${baseString}.taxReturn.previousAddressRRHCNumber`}
      //             labelId="ContactInfo.RRHCNumber"
      //             placeholderId="ContactInfo.RRHCNumber_placeholder"
      //             autoCapitalize="words"
      //             returnKeyType="next"
      //             onSubmitEditing={() => this._boxNumber.focus()}
      //             required
      //             validate={isRequired}
      //             disabled={fetching}
      //           />
      //         </Col>
      //       </Row>
      //       <Row>
      //         <Col sm={12}>
      //           <FormGroupText
      //             inputRef={ref => {
      //               this._boxNumber = ref;
      //             }}
      //             field={`${baseString}.taxReturn.previousAddressBoxNumber`}
      //             labelId="ContactInfo.boxNumber"
      //             placeholderId="ContactInfo.boxNumber_placeholder"
      //             autoCapitalize="words"
      //             returnKeyType="next"
      //             onSubmitEditing={() => this._City.focus()}
      //             required
      //             validate={isRequired}
      //             disabled={fetching}
      //           />
      //         </Col>
      //       </Row>
      //     </View>
      //   );
      //   break;
      case ADDRESS_TYPE_RESIDENTIAL:
      case ADDRESS_TYPE_APARTMENT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._Address = ref;
                  }}
                  field={`${baseString}.taxReturn.previousAddressAddress`}
                  labelId="ContactInfo.address1"
                  placeholderId="ContactInfo.address1_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address2.focus()}
                  required
                  validate={isRequired}
                  disabled={fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._Address2 = ref;
                  }}
                  field={`${baseString}.taxReturn.previousAddressAddress2`}
                  labelId="ContactInfo.address2"
                  placeholderId="ContactInfo.address2_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  disabled={fetching}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._Address3 = ref;
                  }}
                  field={`${baseString}.taxReturn.previousAddressAddress3`}
                  labelId="ContactInfo.address3"
                  placeholderId="ContactInfo.address3_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  disabled={fetching}
                />
              </Col>
            </Row> */}
          </View>
        );
        break;
    }

    if (addressTypeCode)
      return (
        <View>
          {addressHeader}

          <Row>
            <Col sm={12}>
              <FormGroupText
                inputRef={ref => {
                  this._City = ref;
                }}
                field={`${baseString}.taxReturn.previousAddressCity`}
                labelId="ContactInfo.city"
                placeholderId="ContactInfo.city_placeholder"
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroupSelect
                inputRef={ref => (this._State = ref)}
                field={`${baseString}.taxReturn.previousAddressStateCode`}
                labelId="ContactInfo.state"
                placeholderId="ContactInfo.state_placeholder"
                optionsKey="state"
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
            <Col sm={6}>
              <FormGroupText
                inputRef={ref => {
                  this._Zip = ref;
                }}
                field={`${baseString}.taxReturn.previousAddressZip`}
                labelId="ContactInfo.zipcode"
                placeholderId="ContactInfo.zipcode_placeholder"
                returnKeyType="next"
                maxLength={10}
                keyboardType="numeric"
                format={zipcodeFormat}
                required
                validate={isZipcode}
                disabled={fetching}
              />
            </Col>
          </Row>
        </View>
      );
    return null;
  }

  render() {
    const {
      invalid,
      handleSubmit,
      base,
      application,
      index,
      fetching,
      onComplete,
      onSkip,
      values
    } = this.props;

    const { showWhy, errorText } = this.state;

    if (showWhy) {
      return (
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id="IRS4506T.whyTitle"
                style={Fonts.style.title}
              />
              <FormattedMessage
                id="IRS4506T.whySubtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FullButton
                type="link"
                text={I18n.t('buttons.BackToDeclaration')}
                onPress={() => this.setState({ showWhy: false })}
              />

              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      );
    }

    const appBase = getBase(application, base, index);
    const currentValues = values && getBase(values, base, index);
    const baseString = getBaseString(base, index);

    const isJointReturn =
      R.path(['taxReturn', 'filingTypeCode'], currentValues) ===
      JOINT_TAX_RETURN;

    const filedUnderPreviousAddressOptions = {
      '0': { code: '0', value: I18n.t('noCaps'), sort_order: '1' },
      '1': { code: '1', value: I18n.t('yesCaps'), sort_order: '0' }
    };

    const showAddress =
      R.path(['taxReturn', 'filedUnderPreviousAddress'], currentValues) ===
      TRUE_OPTION;

    const addressTypeCode = R.path(
      ['taxReturn', 'previousAddressAddressTypeCode'],
      currentValues
    );
    console.log('addressTypeCode', addressTypeCode);

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        inputRef={scroll => {
          this._scroll = scroll;
        }}
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage id="IRS4506T.title" style={Fonts.style.title} />
              <FormattedMessage
                id="IRS4506T.subtitle"
                style={Fonts.style.subtitle}
              />
              {errorView}
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field={`${baseString}.taxReturn.filingTypeCode`}
                labelId="IRS4506T.filingTypeCode"
                optionsKey="tax_return_filing_type"
                reverse
                validate={isRequired}
                required
                disabled={disabled}
              />
            </Col>
          </Row>

          {isJointReturn && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="IRS4506T.secondName"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    ref="firstName"
                    field={`${baseString}.taxReturn.spouseFirstName`}
                    labelId="ContactInfo.firstname"
                    autoCapitalize="words"
                    placeholderId="ContactInfo.firstname_placeholder"
                    returnKeyType="next"
                    onSubmitEditing={e => {
                      this.refs.lastName.focus();
                    }}
                    required
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    ref="lastName"
                    field={`${baseString}.taxReturn.spouseLastName`}
                    labelId="ContactInfo.lastname"
                    autoCapitalize="words"
                    placeholderId="ContactInfo.lastname_placeholder"
                    returnKeyType="next"
                    onSubmitEditing={() => this.refs.SSN.focus()}
                    required
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="IRS4506T.secondSSN"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    ref="SSN"
                    field={`${baseString}.taxReturn.spouseSSN`}
                    labelId="ContactInfo.ssn"
                    placeholderId="ContactInfo.ssn_placeholder"
                    format={ssnFormat}
                    maxLength={11}
                    keyboardType="numeric"
                    returnKeyType="default"
                    validate={isSsnNumber}
                    required
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </View>
          )}
          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field={`${baseString}.taxReturn.filedUnderPreviousAddress`}
                labelId="IRS4506T.ReturnFiledUnderPreviousAddress"
                options={filedUnderPreviousAddressOptions}
                validate={isRequired}
                required
                disabled={disabled}
              />
            </Col>
          </Row>

          {showAddress && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="IRS4506T.LastAddress"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FormGroupSelect
                    field={`${baseString}.taxReturn.previousAddressAddressTypeCode`}
                    labelId="ContactInfo.addressTypeCode"
                    placeholderId="ContactInfo.addressTypeCode_placeholder"
                    optionsKey="address_type"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {this.renderAddress(addressTypeCode)}
            </View>
          )}
          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={handleSubmit}
                disabled={invalid || fetching}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('buttons.SkipThisStep')}
                onPress={onSkip}
                type="small"
                disabled={fetching}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('buttons.WhyDoINeedThis')}
                onPress={() => this.setState({ showWhy: true })}
                type="link"
                disabled={fetching}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    );
  }
}

IRS4506T = withFormik({
  mapPropsToValues: props => {
    const { base, index, application, token, account } = props;
    const appBase = getBase(application, base, index);

    let newApplication = JSON.parse(JSON.stringify(application));
    if (index >= 0) {
      // console.log(
      //   'mapPropsToValues',
      //   JSON.stringify(newApplication[base][index], null, 2)
      // );
      if (!newApplication[base][index].taxReturn) {
        newApplication[base][index].taxReturn = {};
      }
      if (!newApplication[base][index].taxReturn.previousAddressStateCode) {
        newApplication[base][
          index
        ].taxReturn.previousAddressStateCode = DEFAULT_STATE_CODE;
      }
    } else {
      // console.log(
      //   'mapPropsToValues',
      //   JSON.stringify(newApplication[base], null, 2)
      // );
      if (!newApplication[base].taxReturn) {
        newApplication[base].taxReturn = {};
      }
      if (!newApplication[base].taxReturn.previousAddressStateCode) {
        newApplication[
          base
        ].taxReturn.previousAddressStateCode = DEFAULT_STATE_CODE;
      }
    }

    // console.log('loaded application', JSON.stringify(newApplication, null, 2));
    return newApplication;
  },
  handleSubmit: (values, { setStatus, props }) => {
    props.updateApplication(values);
    setStatus('updatingApplication');
  }
})(IRS4506T);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { application },
  form
}) => ({
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IRS4506T);
