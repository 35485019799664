import React, { Component } from "react";

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import { View, Text } from "react-native";
import { connect } from "react-redux";
import { withFormik, Field, setNestedObjectValues } from "formik";
import * as yup from "yup";
import { I18n } from "react-redux-i18n";

import schema from "../Lib/ValidationSchema";

import { isRequired } from "../Lib/Utils";

import ApplicationActions from "../Redux/ApplicationRedux";
import PersistActions from "../Redux/PersistRedux";
import { Grid, Row, Col } from "../Components/Grid";
import ErrorMessage from "../Components/ErrorMessage";
import FormattedMessage from "../Components/FormattedMessage";
import FormGroupRadio from "../Components/FormGroupRadio";
import FullButton from "../Components/FullButton";

// Styles
import { Fonts, Metrics } from "../Themes/";
import styles from "./Styles/ScreenStyles";
import Footer from "../Components/Footer";

class ChooseProgramScreen extends Component {
  state = {
    errorText: "",
  };
  // isCreatingApplication = false;

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setSubmitting,
    } = nextProps;

    if (isSubmitting && status === "creatingApplication" && !fetching) {
      setStatus("");
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push("DoIQualifyScreen"); // 'GetStartedDocListScreen';
      }
    }
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      isValid,
      fetching,
      history,
      programCodes,
    } = this.props;
    const { errorText } = this.state;

    const options = {
      5: { code: 5, value: I18n.t("ChooseAProgram.HomeownerProgram") },
      6: { code: 6, value: I18n.t("ChooseAProgram.RentalProgram") },
    };

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={(scroll) => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="always"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="ChooseAProgram.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="ChooseAProgram.subtitle"
                  style={Fonts.style.subtitle}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`ChooseAProgram.bullet1`}
                    style={[
                      styles.liText,
                      {
                        fontFamily: Fonts.type.base,
                        fontWeight: Fonts.weight.ultralight,
                      },
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`ChooseAProgram.bullet2`}
                    style={[
                      styles.liText,
                      {
                        fontFamily: Fonts.type.base,
                        fontWeight: Fonts.weight.ultralight,
                      },
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`ChooseAProgram.bullet3`}
                    style={[
                      styles.liText,
                      {
                        fontFamily: Fonts.type.base,
                        fontWeight: Fonts.weight.ultralight,
                      },
                    ]}
                  />
                </View>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <ErrorMessage errorText={errorText} />
                <FormGroupRadio
                  field="programCode"
                  labelId="ChooseAProgram.ChooseAProgram"
                  // optionsKey="program"
                  options={options}
                  validate={isRequired}
                  disabled={fetching}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t("buttons.LinkApplication")}
                  onPress={() => {
                    history.push("/SubmitCaseLinkCodeScreen");
                  }}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t("form.continueNextStep")}
                  onPress={handleSubmit}
                  disabled={fetching || !isValid}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// // Create the yum validationSchema
// const validationSchema = yup.object().shape({
//   application: yup.object().shape({
//     programCode: schema.programCode
//   })
// });

ChooseProgramScreen = withFormik({
  mapPropsToValues: (props) => ({
    programCode: "",
  }),
  isInitialValid: (props) => false,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    // console.log(props);
    console.log("values", values);
    props.createApplication(values.programCode);
    setStatus("creatingApplication");
    // props.history.push('DoIQualifyScreen');
  },
})(ChooseProgramScreen);

const mapStateToProps = ({
  app: { initilized },
  persist: { application, programCodes },
  application: { fetching, error },
}) => ({
  initilized,
  fetching,
  errorText: error,
  application,
  programCodes,
});

const mapDispatchToProps = (dispatch) => ({
  createApplication: (programCode) =>
    dispatch(ApplicationActions.ApplicationCreate(programCode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseProgramScreen);
