import { combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import { i18nReducer } from 'react-redux-i18n';
import { connectRouter } from 'connected-react-router';
import configureStore from './CreateStore';
import rootSaga from '../Sagas/';
import ReduxPersist from '../Config/ReduxPersist';

// import createBrowserHistory from 'history/createBrowserHistory';
// import createMemoryHistory from 'history/createMemoryHistory';

/* ------------- Assemble The Reducers ------------- */
export const reducers = history =>
  combineReducers({
    app: require('./AppRedux').reducer,
    account: require('./AccountRedux').reducer,
    application: require('./ApplicationRedux').reducer,
    case: require('./CaseRedux').reducer,
    document: require('./DocumentRedux').reducer,
    // network: require('./NetworkRedux').reducer,
    options: require('./OptionsRedux').reducer,
    persist: require('./PersistRedux').reducer,

    router: connectRouter(history),
    i18n: i18nReducer
  });

export default history => {
  let finalReducers = reducers(history);
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers(history));
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(
    history,
    finalReducers,
    rootSaga
  );

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers;
      store.replaceReducer(nextRootReducer(history));

      const newYieldedSagas = require('../Sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return store;
};
