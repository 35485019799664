import { StyleSheet, Platform } from "react-native";
import { ApplicationStyles, Colors, Fonts, Metrics } from "../../Themes/";

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  centeredBox: {
    borderColor: "black",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  formLabel: {
    fontFamily: Fonts.style.bold,
    fontSize: Fonts.size.pt40,
  },
  formBorder: {
    justifyContent: "center",
    borderWidth: 2,
    padding: Metrics.baseMargin,
  },
  center: {
    ...Fonts.style.normal,
    marginTop: Metrics.baseMargin,
    textAlign: "center",
  },
  block: {
    ...Fonts.style.normal,
    display: Platform.OS === "web" ? "block" : "flex",
  },
  p: {
    ...Fonts.style.normal,
    marginTop: Metrics.baseMargin,
    display: Platform.OS === "web" ? "block" : "flex",
  },
  li: {
    ...Fonts.style.normal,
    marginTop: Metrics.baseMargin,
  },
  doubleLiWrapper: {
    paddingLeft: Metrics.doubleBaseMargin * 2,
    flexDirection: "row",
  },
  doubleLiWrapperSpaced: {
    marginTop: Metrics.baseMargin,
    paddingLeft: Metrics.doubleBaseMargin * 2,
    flexDirection: "row",
  },
  liWrapper: {
    paddingLeft: Metrics.doubleBaseMargin,
    flexDirection: "row",
  },
  liWrapperSpaced: {
    marginTop: Metrics.baseMargin,
    paddingLeft: Metrics.doubleBaseMargin,
    flexDirection: "row",
  },
  liBullet: {
    ...Fonts.style.normal,
  },
  liText: {
    ...Fonts.style.normal,
    flex: 1,
    marginLeft: Metrics.baseMargin,
  },
  liBulletBold: {
    ...Fonts.style.normal,
    fontWeight: Fonts.weight.bold,
  },
  liTextBold: {
    ...Fonts.style.normal,
    flex: 1,
    marginLeft: Metrics.baseMargin,
    fontWeight: Fonts.weight.bold,
  },
  liIndent: {
    marginLeft: Metrics.doubleBaseMargin,
  },
  i: {
    fontStyle: "italic",
  },
  underline: {
    textDecorationLine: "underline",
  },
  bold: {
    fontWeight: Fonts.weight.bold,
  },
  errorContainer: {
    margin: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: "rgba(255,0,0,1)",
    backgroundColor: "rgba(255,0,0,0.1)",
  },
  errorText: {
    ...Fonts.style.normal,
    color: Colors.black,
  },
  readyToSubmitButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    padding: 2,
    borderWidth: 1,
    borderColor: "rgba(245,166,35,1)",
    backgroundColor: "rgba(245,166,35,0.1)",
  },
  uploadingButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    padding: 2,
    borderWidth: 1,
    borderColor: "rgba(119,119,119,1)",
    backgroundColor: "rgba(119,119,119,0.1)",
  },
  submissionCompleteButton: {
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    padding: 2,
    borderWidth: 1,
    borderColor: "rgba(126,211,33,1)",
    backgroundColor: "rgba(126,211,33,0.1)",
  },
});
