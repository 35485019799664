import React, { Component } from 'react';
import {
  Image,
  ScrollView,
  View,
  Platform,
  Alert,
  Modal,
  Text,
  Clipboard,
  TouchableOpacity,
  TouchableHighlight,
  TextInput,
  ProgressBarAndroid,
  ProgressViewIOS
} from 'react-native';
import { connect } from 'react-redux';
import * as R from 'ramda';
import moment from 'moment';

import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import AppActions from '../Redux/AppRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';

import FullButton from '../Components/FullButton';
import FormattedMessage from '../Components/FormattedMessage';

import { Images, Fonts, Colors } from '../Themes/';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
// Styles
import formStyles from '../Components/Styles/FormStyles';
import styles from './Styles/ScreenStyles';
import {
  STATUS_SUBMITTED,
  STATUS_SUBMITTED_PENDING_DOCUSIGN
} from '../Config/ApplicationConfig';
import { getSyncProgress } from '../Sagas/AppSagas';
import Footer from '../Components/Footer';

const BASE = 'SyncStatus';
const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const DATE_FORMAT = 'MMM D, YY HH:mm:ss';

class SyncStatusScreen extends Component {
  state = {
    pin: '',
    submittedPin: false,
    showLogs: false,
    errorText: null
  };

  isSubmitting = false;

  componentDidMount() {
    const { token, account, history } = this.props;
    console.log('TCL: SyncStatusScreen -> componentDidMount -> token', token);
    if (!token) {
      history.push('/SyncStatusScreen/VerifyOtpScreen');
    }
    this.props.resetStatus();
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      initilized,
      application,
      loadDocuments,
      onComplete,
      currentForm
    } = nextProps;

    if (this.isSubmitting && !fetching) {
      this.isSubmitting = false;
      console.log(errorText);
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      }
    }
  }

  handleRemoveApplication(applicationId) {
    const {
      applications,
      offlineDocuments,
      setApplications,
      setDocuments
    } = this.props;

    Alert.alert(
      'Delete Application',
      `Confirm Delete ${applicationId}?`,
      [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel'
        },
        {
          text: 'OK',
          onPress: () => {
            setApplications(applications.without(applicationId));
            setDocuments(offlineDocuments.without(applicationId));
          }
        }
      ],
      { cancelable: true }
    );
  }
  sendLogs = () => {
    const { sendLogs } = this.props;
    sendLogs();
  };

  handleSubmitApplication(applicationKey) {
    const { fetching } = this.props;
    // const hasError = status && status.status && status.status.substring(0, 5) === 'Error';
    // console.log(status, hasError);
    if (!fetching) {
      // console.log(JSON.stringify(application, null, 2));
      this.props.resetStatus();
      this.isSubmitting = true;
      this.props.submitApplication(applicationKey);
    }
  }

  handleShowLogs = () => {
    const { log } = this.props;
    Clipboard.setString(JSON.stringify(log, null, 2));
    this.setState({ showLogs: true });
  };
  handleHideLogs = () => {
    this.setState({ showLogs: false });
  };

  renderApplications() {
    const { status, applications, offlineDocuments } = this.props;

    const hasError =
      status && status.status && status.status.substring(0, 5) === 'Error';

    return Object.keys(applications).map(key => {
      const application = applications[key];
      // const isOffline = application.id.substring(0, 3) === 'OFF';
      const isOffline =
        application.status !== STATUS_SUBMITTED_PENDING_DOCUSIGN;

      const completeButton = (
        <View style={styles.submissionCompleteButton}>
          <FormattedMessage
            id={`${BASE}.submissionComplete`}
            style={[Fonts.style.normal, { textAlign: 'center' }]}
          />
        </View>
      );

      const { progress, progressMax } = getSyncProgress(
        application,
        offlineDocuments[key]
      );
      const percent = progressMax > 0 && progress / progressMax;
      const percentText = percent > 0 && `${Math.floor(percent * 100)}%`;

      const submitButton = (
        <TouchableOpacity
          style={styles.readyToSubmitButton}
          onPress={this.handleSubmitApplication.bind(this, key)}
        >
          <FormattedMessage
            id={`${BASE}.submit`}
            style={[Fonts.style.normal, { textAlign: 'center' }]}
          />
        </TouchableOpacity>
      );
      const resumeButton = (
        <TouchableOpacity
          style={styles.readyToSubmitButton}
          onPress={this.handleSubmitApplication.bind(this, key)}
        >
          <FormattedMessage
            id={`${BASE}.resume`}
            style={[Fonts.style.normal, { textAlign: 'center' }]}
          />
        </TouchableOpacity>
      );

      const uploadingButton = !hasError && status.applicationId === key && (
        <View style={styles.uploadingButton}>
          <FormattedMessage
            id={`${BASE}.uploading`}
            style={[Fonts.style.normal, { textAlign: 'center' }]}
          />
        </View>
      );

      const removeButton = (
        <TouchableOpacity
          style={styles.readyToSubmitButton}
          onPress={this.handleRemoveApplication.bind(this, key)}
        >
          <FormattedMessage
            id={`${BASE}.removeApplication`}
            style={[Fonts.style.normal, { textAlign: 'center' }]}
          />
        </TouchableOpacity>
      );

      return (
        <View
          key={key}
          style={{
            backgroundColor: Colors.white,
            padding: 10,
            marginBottom: 10
          }}
        >
          <Row>
            <Col xs={12}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={[Fonts.style.normal, { flex: 1 }]}>
                  {R.pathOr('N/A', [APPLICANT_BASE, 'firstName'], application)}
                </Text>
                <Text style={[Fonts.style.normal, { flex: 1 }]}>
                  {R.pathOr('N/A', [APPLICANT_BASE, 'lastName'], application)}
                </Text>
                <Text style={[Fonts.style.normal, { flex: 1 }]}>
                  {R.pathOr('N/A', [CONTACT_BASE, 'primaryPhone'], application)}
                </Text>
                <Text style={[Fonts.style.normal, { flex: 1 }]}>
                  {application.created
                    ? moment(application.created).format(DATE_FORMAT)
                    : 'N/A'}
                </Text>
                <Text style={[Fonts.style.normal, { flex: 1 }]}>
                  {application.updated
                    ? moment(application.updated).format(DATE_FORMAT)
                    : 'N/A'}
                </Text>
              </View>
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {percent > 0 && (
                  <Text style={Fonts.style.normal}>
                    {`(#${application.id}) Progress: ${percentText}`}
                  </Text>
                )}
              </View>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>{removeButton}</Col>
            <Col xs={4}>{resumeButton}</Col>
            <Col xs={4}>{submitButton}</Col>
          </Row>
          <Row>
            <Col xs={12}>
              {Platform.OS === 'android' && (
                <ProgressBarAndroid
                  styleAttr="Horizontal"
                  progress={percent}
                  indeterminate={false}
                />
              )}
              {Platform.OS === 'ios' && <ProgressViewIOS progress={percent} />}
            </Col>
          </Row>
        </View>
      );
    });
  }

  render() {
    const { initilized, navigation, applications, status, log } = this.props;

    const { pin, submittedPin, errorText, showLogs } = this.state;

    if (!initilized) return null;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.flex}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={[
            styles.scrollViewContainer,
            { backgroundColor: Colors.fieldBackground }
          ]}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={{ marginTop: 20 }} />

          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} style={{ alignItems: 'center' }}>
                <TouchableOpacity
                  onPress={this.handleShowLogs}
                  style={{ flexDirection: 'row' }}
                >
                  <Text
                    numberOfLines={2}
                    style={[Fonts.style.subtitle, { flex: 1 }]}
                  >
                    {status && status.status}
                  </Text>
                </TouchableOpacity>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={12} style={{ alignItems: 'flex-end' }}>
                <TouchableOpacity onPress={this.sendLogs}>
                  <FormattedMessage
                    id={`${BASE}.sendLogs`}
                    style={[
                      Fonts.style.normal,
                      {
                        color: '#9F8C52',
                        textDecorationLine: 'underline',
                        marginTop: 0
                      }
                    ]}
                  />
                </TouchableOpacity>
              </Col>
            </Row> */}
            <Row>
              <Col span={{ sm: 12 }} style={{ minHeight: 20 }}>
                {errorView}
              </Col>
            </Row>
            <Row>
              <Col xs={12} style={{ flex: 1, flexDirection: 'row' }}>
                <FormattedMessage
                  id="ContactInfo.firstname"
                  style={[Fonts.style.normal, { flex: 1 }]}
                />
                <FormattedMessage
                  id="ContactInfo.lastname"
                  style={[Fonts.style.normal, { flex: 1 }]}
                />
                <FormattedMessage
                  id="ContactInfo.PhoneNumber"
                  style={[Fonts.style.normal, { flex: 1 }]}
                />
                <FormattedMessage
                  id={`${BASE}.created`}
                  style={[Fonts.style.normal, { flex: 1 }]}
                />
                <FormattedMessage
                  id={`${BASE}.updated`}
                  style={[Fonts.style.normal, { flex: 1 }]}
                />
              </Col>
            </Row>
            {this.renderApplications()}
          </Grid>
          <View style={{ marginTop: 100 }} />
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
        <Modal
          animationType="slide"
          transparent={false}
          visible={showLogs}
          onRequestClose={this.handleHideLogs}
        >
          <View style={{ flex: 1, margin: 10, marginTop: 30 }}>
            <Text style={Fonts.style.title}>Logs</Text>
            <Text style={Fonts.style.subtitle}>
              Logs content has been copied to the clipboard
            </Text>
            <ScrollView>
              {log && <Text>{JSON.stringify(log, null, 2)}</Text>}
            </ScrollView>
            <TouchableHighlight
              onPress={this.handleHideLogs}
              style={{
                padding: 10,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: Colors.grey,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text style={Fonts.style.normal}>Close</Text>
            </TouchableHighlight>
          </View>
        </Modal>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized, status, log },
  persist: { applications, offlineDocuments, application, token, account },
  application: { fetching, error }
}) => ({
  initilized,
  fetching,
  errorText: error,
  status,
  log,
  applications,
  offlineDocuments,
  application,
  token,
  account
});

const mapDispatchToProps = dispatch => ({
  setApplications: applications =>
    dispatch(PersistActions.PersistSetApplications(applications)),
  setDocuments: documents =>
    dispatch(PersistActions.PersistSetOfflineDocuments(documents)),
  submitApplication: application =>
    dispatch(ApplicationActions.ApplicationSync(application, true)),
  // syncApplications: () => dispatch(AppActions.AppApplicationsSync()),
  resetStatus: () =>
    dispatch(AppActions.AppSetStatus({ status: 'Waiting to Submit' })),
  sendLogs: () => dispatch(AppActions.AppSendLogs())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncStatusScreen);
