import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Image, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

// import Icon from 'react-native-vector-icons/dist/Ionicons';
import { isRequired } from '../Lib/Utils';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions, {
  checkRentalProgram
} from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';
import SelectUploadDocumentType from '../Components/SelectUploadDocumentType';

import DocumentActions, {
  getDocumentURL,
  getDocumentType
} from '../Redux/DocumentRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE
} from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

const PROOF_OF_OWNERSHIP_DOCUMENT_TYPE = 'proofOfOwnership';

// const PROOF_OF_MOBILE_HOME_OWNERSHIP_DOCUMENT_TYPE =
//   DOCUMENT_TYPES.proofOfMobileHomeOwnership.code;

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
// const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

const BASE = 'ownHomeProof';

class OwnHomeProofScreen extends Component {
  onComplete = () => {
    const { application, history } = this.props;

    // const isRentalProgram = checkRentalProgram(application);
    // if (isRentalProgram) {
    //   // Skip Primary Residence and Water Enter Home
    //   history.push('/InsuranceScreen');
    // } else {
    //   history.push('/PrimaryResidenceScreen');
    // }
    history.push('/PrimaryResidenceScreen');
  };

  render() {
    const { application, documents, initilized } = this.props;

    // const isMobileHome =
    //   R.path([DAMAGED_ADDRESS_BASE, 'propertyType'], application) ===
    //   MOBILE_HOME_CODE;

    // const DOCUMENT_TYPE = isMobileHome
    //   ? PROOF_OF_MOBILE_HOME_OWNERSHIP_DOCUMENT_TYPE
    //   : PROOF_OF_OWNERSHIP_DOCUMENT_TYPE;

    const documentType = getDocumentType(
      application,
      PROOF_OF_OWNERSHIP_DOCUMENT_TYPE
    );

    // const hasDocument = R.path(
    //   [DAMAGED_ADDRESS_BASE, BASE, 'documents'],
    //   values
    // );
    const hasDocument = false;

    if (!initilized) return null;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.ProofOfOwnership"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage
                  id={'DamagedAddress.ProofOfOwnershipDescription'}
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <SelectUploadDocumentType
                  documentType={documentType.code}
                  scrollToTop={() => this._scroll.scrollTo({ x: 0, y: 0 })}
                  base={APPLICANT_BASE}
                  onComplete={this.onComplete}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, fetching, error },
  form
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnHomeProofScreen);
