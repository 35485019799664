import React, { Component } from "react";
import { Platform, Text, View, TouchableOpacity } from "react-native";
// import { Router as MyRouter, Route as MyRoute, Link as MyLink } from './Router';
// import Drawer from 'react-native-drawer';
import Drawer from "./Drawer";
import { ConnectedRouter } from "connected-react-router";
import { Router, Route, Link, Switch } from "./Router";
import { withGaTracker } from "./withGaTracker";

import DrawerContents from "./DrawerContents";

import SafeAera from "../Components/SafeAera";
import TopNav from "../Components/TopNav";

import HomeScreen from "../Containers/HomeScreen";
import LoginScreen from "../Containers/LoginScreen";

import ForgotPasswordScreen from "../Containers/ForgotPasswordScreen";
import ChangePasswordScreen from "../Containers/ChangePasswordScreen";

import VerifyOtpScreen from "../Containers/VerifyOtpScreen";
import CreateAccountScreen from "../Containers/CreateAccountScreen";

import SelectApplicationScreen from "../Containers/SelectApplicationScreen";

import GetStartedDocListScreen from "../Containers/GetStartedDocListScreen";
import ChooseProgramScreen from "../Containers/ChooseProgramScreen";
import DoIQualifyScreen from "../Containers/DoIQualifyScreen";
import YouDontQualifyScreen from "../Containers/YouDontQualifyScreen";
import NotifyMeOfOtherProgramsScreen from "../Containers/NotifyMeOfOtherProgramsScreen";
import WhyDontIQualifyScreen from "../Containers/WhyDontIQualifyScreen";

import ProgramReferralScreen from "../Containers/ProgramReferralScreen";

import ApplicantIdentificationScreen from "../Containers/ApplicantIdentificationScreen";
import ApplicantInformationScreen from "../Containers/ApplicantInformationScreen";
import ApplicantIncomeScreen from "../Containers/ApplicantIncomeScreen";
import ApplicantTaxReturnScreen from "../Containers/ApplicantTaxReturnScreen";

import CoapplicantExistsScreen from "../Containers/CoapplicantExistsScreen";

import CoapplicantIdentificationScreen from "../Containers/CoapplicantIdentificationScreen";
import CoapplicantInformationScreen from "../Containers/CoapplicantInformationScreen";
import CoapplicantIncomeScreen from "../Containers/CoapplicantIncomeScreen";
import CoapplicantTaxReturnScreen from "../Containers/CoapplicantTaxReturnScreen";

import HouseholdMembersScreen from "../Containers/HouseholdMembersScreen";
import HouseholdMembersIdentificationScreen from "../Containers/HouseholdMembersIdentificationScreen";
import HouseholdMemberInformationScreen from "../Containers/HouseholdMemberInformationScreen";
import HouseholdMemberIncomeScreen from "../Containers/HouseholdMemberIncomeScreen";
import HouseholdMemberTaxReturnScreen from "../Containers/HouseholdMemberTaxReturnScreen";

import DisabilityProofScreen from "../Containers/DisabilityProofScreen";

import TenantsScreen from "../Containers/TenantsScreen";
import TenantInformationScreen from "../Containers/TenantInformationScreen";

import PropertyOwnersScreen from "../Containers/PropertyOwnersScreen";
import PropertyOwnerInformationScreen from "../Containers/PropertyOwnerInformationScreen";

import DamagedAddressScreen from "../Containers/DamagedAddressScreen";
import DamagedAddressImagesScreen from "../Containers/DamagedAddressImagesScreen";

import LienOnPropertyScreen from "../Containers/LienOnPropertyScreen";
import LienHolderInformation from "../Containers/LienHolderInformation";

import OwnHomeScreen from "../Containers/OwnHomeScreen";
import OwnsLandScreen from "../Containers/OwnsLandScreen";

import OwnHomeProofScreen from "../Containers/OwnHomeProofScreen";
import PrimaryResidenceScreen from "../Containers/PrimaryResidenceScreen";
import PrimaryResidenceProofScreen from "../Containers/PrimaryResidenceProofScreen";

import FloodWaterEnterHomeScreen from "../Containers/FloodWaterEnterHomeScreen";

import RentalInformationScreen from "../Containers/RentalInformationScreen";

import InsuranceScreen from "../Containers/InsuranceScreen";
import InsurancePolicy from "../Containers/InsurancePolicy";
import NoInsuranceScreen from "../Containers/NoInsuranceScreen";

import OtherFundsScreen from "../Containers/OtherFundsScreen";
import OtherFunds from "../Containers/OtherFunds";

import PoaDesigneeScreen from "../Containers/PoaDesigneeScreen";
import PoaScreen from "../Containers/PoaScreen";
import CommsDesigneeScreen from "../Containers/CommsDesigneeScreen";

import PreferredManagementSolutionScreen from "../Containers/PreferredManagementSolutionScreen";

import ConsentToShareDataScreen from "../Containers/ConsentToShareDataScreen";
import CertificationOfCitizenshipScreen from "../Containers/CertificationOfCitizenshipScreen";

import ApplicationChecklistScreen from "../Containers/ApplicationChecklistScreen";

import NextStepsScreen from "../Containers/NextStepsScreen";
import SatisfactionSurveyScreen from "../Containers/SatisfactionSurveyScreen";

import SyncStatusScreen from "../Containers/SyncStatusScreen";

import SelectMyCaseScreen from "../Containers/SelectMyCaseScreen";
import SubmitCaseLinkCodeScreen from "../Containers/SubmitCaseLinkCodeScreen";
import ApplicationStatusScreen from "../Containers/ApplicationStatusScreen";
import ApplicationStatusScreen1 from "../Containers/ApplicationStatusScreen1";
import ApplicationStatusScreen2 from "../Containers/ApplicationStatusScreen2";

const styles = {};

// const drawerStyles = {
//   drawer: { shadowColor: '#000000', shadowOpacity: 0.8, shadowRadius: 3 },
//   main: { paddingLeft: 3 }
// };

export default class Navigation extends Component {
  closeDrawer = () => {
    console.log("close drawer");
    this._drawer.close();
  };
  openDrawer = () => {
    this._drawer.open();
  };

  handleOnChange = e => {
    console.log("e", e);
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { history } = this.props;

    const drawerWidth = 300;

    return (
      <ConnectedRouter history={history}>
        <Drawer
          ref={ref => (this._drawer = ref)}
          content={<DrawerContents closeDrawer={this.closeDrawer} />}
        >
          {/* <Drawer
          ref={ref => (this._drawer = ref)}
          type="overlay"
          tapToClose={true}
          acceptPan={true}
          openDrawerOffset={viewport => viewport.width - drawerWidth}
          closedDrawerOffset={-3}
          elevation={2}
          content={<DrawerContents closeDrawer={this.closeDrawer} />}
          styles={{
            drawer: {
              shadowColor: '#000000',
              shadowOpacity: 0.8,
              shadowRadius: 3
            },
            main: { paddingLeft: 3 }
          }}
        > */}
          <SafeAera>
            <TopNav onToggleDrawer={this.openDrawer} />
            {/* your usual react-router v4 routing */}
            <Route exact path="/" component={withGaTracker(HomeScreen)} />
            <Route
              path="/LoginScreen/:changedPassword?"
              component={withGaTracker(LoginScreen)}
            />

            <Route
              path="/ForgotPasswordScreen"
              component={withGaTracker(ForgotPasswordScreen)}
            />
            <Route
              path="/ChangePasswordScreen"
              component={withGaTracker(ChangePasswordScreen)}
            />

            {/* <Route render={() => <View>Miss</View>} /> */}
            <Route
              path="/VerifyOtpScreen"
              component={withGaTracker(VerifyOtpScreen)}
            />
            <Route
              path="/CreateAccountScreen"
              component={withGaTracker(CreateAccountScreen)}
            />

            <Route
              path="/SelectApplicationScreen"
              component={withGaTracker(SelectApplicationScreen)}
            />

            <Route
              path="/GetStartedDocListScreen"
              component={withGaTracker(GetStartedDocListScreen)}
            />
            <Route
              path="/ChooseProgramScreen"
              component={withGaTracker(ChooseProgramScreen)}
            />
            <Route
              path="/DoIQualifyScreen"
              component={withGaTracker(DoIQualifyScreen)}
            />
            <Route
              path="/YouDontQualifyScreen"
              component={withGaTracker(YouDontQualifyScreen)}
            />
            <Route
              path="/WhyDontIQualifyScreen"
              component={withGaTracker(WhyDontIQualifyScreen)}
            />
            <Route
              exact
              path="/NotifyMeOfOtherProgramsScreen"
              component={NotifyMeOfOtherProgramsScreen}
            />
            <Route
              path="/NotifyMeOfOtherProgramsScreen/LoginScreen"
              component={LoginScreen}
            />
            <Route
              path="/NotifyMeOfOtherProgramsScreen/CreateAccountScreen"
              component={CreateAccountScreen}
            />
            <Route
              path="/NotifyMeOfOtherProgramsScreen/VerifyOtpScreen"
              component={VerifyOtpScreen}
            />

            <Route
              path="/ProgramReferralScreen"
              component={withGaTracker(ProgramReferralScreen)}
            />

            <Route
              exact
              path="/ApplicantIdentificationScreen"
              component={withGaTracker(ApplicantIdentificationScreen)}
            />
            <Route
              path="/ApplicantIdentificationScreen/LoginScreen"
              component={withGaTracker(LoginScreen)}
            />
            <Route
              path="/ApplicantIdentificationScreen/CreateAccountScreen"
              component={withGaTracker(CreateAccountScreen)}
            />
            <Route
              path="/ApplicantIdentificationScreen/VerifyOtpScreen"
              component={withGaTracker(VerifyOtpScreen)}
            />

            <Route
              path="/ApplicantInformationScreen"
              component={withGaTracker(ApplicantInformationScreen)}
            />
            <Route
              path="/ApplicantIncomeScreen"
              component={withGaTracker(ApplicantIncomeScreen)}
            />
            <Route
              path="/ApplicantTaxReturnScreen"
              component={withGaTracker(ApplicantTaxReturnScreen)}
            />
            <Route
              path="/CoapplicantExistsScreen"
              component={withGaTracker(CoapplicantExistsScreen)}
            />
            <Route
              path="/CoapplicantIdentificationScreen"
              component={withGaTracker(CoapplicantIdentificationScreen)}
            />
            <Route
              path="/CoapplicantInformationScreen"
              component={withGaTracker(CoapplicantInformationScreen)}
            />
            <Route
              path="/CoapplicantIncomeScreen"
              component={withGaTracker(CoapplicantIncomeScreen)}
            />
            <Route
              path="/CoapplicantTaxReturnScreen"
              component={withGaTracker(CoapplicantTaxReturnScreen)}
            />

            <Route
              path="/HouseholdMembersScreen"
              component={withGaTracker(HouseholdMembersScreen)}
            />

            <Route
              path="/HouseholdMember/:index/Identification"
              component={withGaTracker(HouseholdMembersIdentificationScreen)}
            />
            <Route
              path="/HouseholdMember/:index/Information"
              component={withGaTracker(HouseholdMemberInformationScreen)}
            />
            <Route
              path="/HouseholdMember/:index/Income"
              component={withGaTracker(HouseholdMemberIncomeScreen)}
            />
            <Route
              path="/HouseholdMember/:index/TaxReturn"
              component={withGaTracker(HouseholdMemberTaxReturnScreen)}
            />

            <Route
              path="/DisabilityProofScreen"
              component={withGaTracker(DisabilityProofScreen)}
            />

            <Route
              path="/TenantsScreen"
              component={withGaTracker(TenantsScreen)}
            />

            <Route
              path="/Tenant/:index/Information"
              component={withGaTracker(TenantInformationScreen)}
            />

            <Route
              path="/PropertyOwnersScreen"
              component={withGaTracker(PropertyOwnersScreen)}
            />
            <Route
              path="/PropertyOwner/:index/Information"
              component={withGaTracker(PropertyOwnerInformationScreen)}
            />

            <Route
              path="/DamagedAddressScreen"
              component={withGaTracker(DamagedAddressScreen)}
            />
            <Route
              path="/DamagedAddressImagesScreen"
              component={withGaTracker(DamagedAddressImagesScreen)}
            />
            <Route
              path="/LienOnPropertyScreen"
              component={withGaTracker(LienOnPropertyScreen)}
            />
            <Route
              path="/LienHolder/:index/Information"
              component={withGaTracker(LienHolderInformation)}
            />
            <Route
              path="/OwnHomeScreen"
              component={withGaTracker(OwnHomeScreen)}
            />
            <Route
              path="/OwnsLandScreen"
              component={withGaTracker(OwnsLandScreen)}
            />
            <Route
              path="/OwnHomeProofScreen"
              component={withGaTracker(OwnHomeProofScreen)}
            />
            <Route
              path="/PrimaryResidenceScreen"
              component={withGaTracker(PrimaryResidenceScreen)}
            />
            <Route
              path="/PrimaryResidenceProofScreen"
              component={withGaTracker(PrimaryResidenceProofScreen)}
            />
            <Route
              path="/FloodWaterEnterHomeScreen"
              component={withGaTracker(FloodWaterEnterHomeScreen)}
            />
            <Route
              path="/RentalInformationScreen"
              component={withGaTracker(RentalInformationScreen)}
            />
            <Route
              path="/InsuranceScreen"
              component={withGaTracker(InsuranceScreen)}
            />
            <Route
              path="/Insurance/:index/Information"
              component={withGaTracker(InsurancePolicy)}
            />
            <Route
              path="/NoInsuranceScreen"
              component={withGaTracker(NoInsuranceScreen)}
            />

            <Route
              path="/OtherFundsScreen"
              component={withGaTracker(OtherFundsScreen)}
            />
            <Route
              path="/OtherFunds/:index/Information"
              component={withGaTracker(OtherFunds)}
            />

            <Route
              path="/PoaDesigneeScreen"
              component={withGaTracker(PoaDesigneeScreen)}
            />
            <Route path="/PoaScreen" component={withGaTracker(PoaScreen)} />
            <Route
              path="/CommsDesigneeScreen"
              component={withGaTracker(CommsDesigneeScreen)}
            />

            <Route
              path="/PreferredManagementSolutionScreen"
              component={withGaTracker(PreferredManagementSolutionScreen)}
            />

            <Route
              path="/ConsentToShareDataScreen"
              component={withGaTracker(ConsentToShareDataScreen)}
            />
            <Route
              path="/CertificationOfCitizenshipScreen"
              component={withGaTracker(CertificationOfCitizenshipScreen)}
            />
            <Route
              path="/ApplicationChecklistScreen"
              component={withGaTracker(ApplicationChecklistScreen)}
            />

            <Route
              path="/NextStepsScreen"
              component={withGaTracker(NextStepsScreen)}
            />
            <Route
              path="/SatisfactionSurveyScreen"
              component={withGaTracker(SatisfactionSurveyScreen)}
            />

            <Route
              path="/SyncStatusScreen"
              component={withGaTracker(SyncStatusScreen)}
              exact
            />

            <Route
              path="/SyncStatusScreen/VerifyOtpScreen"
              component={withGaTracker(VerifyOtpScreen)}
            />

            {/* Status Tracker Screens */}
            <Route
              path="/SelectMyCaseScreen"
              exact
              component={withGaTracker(SelectMyCaseScreen)}
            />
            <Route
              path="/SelectMyCaseScreen/LoginScreen"
              component={withGaTracker(LoginScreen)}
            />
            <Route
              path="/SelectMyCaseScreen/CreateAccountScreen"
              component={withGaTracker(CreateAccountScreen)}
            />
            <Route
              path="/SelectMyCaseScreen/VerifyOtpScreen"
              component={withGaTracker(VerifyOtpScreen)}
            />
            <Route
              exact
              path="/SubmitCaseLinkCodeScreen"
              component={withGaTracker(SubmitCaseLinkCodeScreen)}
            />
            <Route
              path="/SubmitCaseLinkCodeScreen/LoginScreen"
              component={withGaTracker(LoginScreen)}
            />
            <Route
              path="/SubmitCaseLinkCodeScreen/CreateAccountScreen"
              component={withGaTracker(CreateAccountScreen)}
            />
            <Route
              path="/SubmitCaseLinkCodeScreen/VerifyOtpScreen"
              component={withGaTracker(VerifyOtpScreen)}
            />

            <Route
              path="/ApplicationStatusScreen"
              exact
              component={withGaTracker(ApplicationStatusScreen)}
            />

            <Route
              path="/ApplicationStatusScreen1"
              exact
              component={withGaTracker(ApplicationStatusScreen1)}
            />
            <Route
              path="/ApplicationStatusScreen2"
              exact
              component={withGaTracker(ApplicationStatusScreen2)}
            />
            {/* <Route path="/" component={withGaTracker(LaunchScreen} /> */}
            {/* <Route path="/camera" component={withGaTracker(CameraScreen} /> */}
          </SafeAera>
        </Drawer>
      </ConnectedRouter>
    );
  }
}
