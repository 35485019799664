import React, { Component } from 'react';
import {
  Platform,
  Text,
  Image,
  ScrollView,
  View,
  TouchableOpacity
} from 'react-native';
import { connect } from 'react-redux';
import { I18n, setLocale as setLocaleStore } from 'react-redux-i18n';

import { Grid, Row, Col } from '../Components/Grid';
import FullButton from '../Components/FullButton';
import FormattedMessage from '../Components/FormattedMessage';
import PersistActions from '../Redux/PersistRedux';
import DocumentActions from '../Redux/DocumentRedux';
import { isTablet } from '../Redux/AccountRedux';
import ApplicationActions from '../Redux/ApplicationRedux';

// Translations
import en from '../I18n/languages/en.json';
import es from '../I18n/languages/es.json';

// Styles
import styles from './Styles/ScreenStyles';
import { Images, Fonts, Metrics } from '../Themes/';
import Footer from '../Components/Footer';

const BASE = 'Home';

class HomeScreen extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      initilized,
      screen,
      history,
      fetching,
      error,
      application
    } = nextProps;

    if (!fetching && this.isCreatingApplication) {
      this.isCreatingApplication = false;
      if (application.id) {
        history.push('ChooseProgramScreen');
      } else if (error) {
        console.log(error);
      } else {
        console.log('unknown error');
      }
    }
  }

  handleChangeLocale = () => {
    if (this.props.locale === 'en') {
      this.props.setLocale('es');
    } else {
      this.props.setLocale('en');
    }
  };

  handleStartNewApplication = () => {
    const {
      setApplication,
      setDocuments,
      setOfflineDocuments,
      history
    } = this.props;
    setApplication(null);
    setDocuments([]);
    history.push('ChooseProgramScreen');
  };

  handleResumeApplication = () => {
    const {
      setApplication,
      setDocuments,
      setOfflineDocuments,
      history
    } = this.props;
    setApplication(null);
    setDocuments([]);

    history.push('SelectApplicationScreen');
  };

  handleLogout = () => {
    this.props.resetPersist();
  };

  render() {
    const { initilized, history, locale, isKiosk, account } = this.props;

    const userId = account && account.userId;

    // console.log(this.props);
    if (!initilized) return null;

    const kiosk = isKiosk;
    const tablet = isTablet();

    const thisLocale = locale || 'en';

    // Manually load translations from opposite locale
    const continueInSpanish =
      thisLocale === 'en'
        ? es.Home.continueInSpanish
        : en.Home.continueInSpanish;
    const continueInEnglish =
      thisLocale === 'en'
        ? es.Home.continueInEnglish
        : en.Home.continueInEnglish;

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <View
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <Image
                    source={Images.logo}
                    style={{
                      width: tablet ? 220 : 172,
                      height: tablet ? 220 : 172,
                      resizeMode: 'contain'
                    }}
                  />
                  {/* <SvgUri
                    width={tablet ? 220 : 172}
                    height={tablet ? 220 : 172}
                    source={Images.logo}
                  /> */}
                </View>

                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={
                    kiosk
                      ? `${BASE}.kioskSubtitle`
                      : userId
                      ? `${BASE}.loggedinSubtitle`
                      : `${BASE}.loggedoutSubtitle`
                  }
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: Metrics.doubleBaseMargin
                }}
              >
                {kiosk && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t(`${BASE}.NewApplication`)}
                    onPress={this.handleStartNewApplication}
                  />
                )}
                {!kiosk && userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t('buttons.ResumeApplication')}
                    onPress={this.handleResumeApplication}
                  />
                )}
                {!kiosk && userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t('buttons.NewApplication')}
                    onPress={this.handleStartNewApplication}
                  />
                )}

                {!kiosk && !userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t(`${BASE}.SignUp`)}
                    onPress={this.handleStartNewApplication}
                  />
                )}
                {!kiosk && !userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t(`${BASE}.Login`)}
                    onPress={() => history.push('LoginScreen')}
                  />
                )}
                {userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t('buttons.CheckApplicationStatus')}
                    onPress={() => history.push('/SelectMyCaseScreen')}
                  />
                )}
                {userId && (
                  <FullButton
                    styles={{ flex: 1, minWidth: 350 }}
                    text={I18n.t('buttons.Logout')}
                    onPress={this.handleLogout}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TouchableOpacity
                  style={{
                    marginTop: Metrics.doubleBaseMargin,
                    alignItems: 'center'
                  }}
                  onPress={this.handleChangeLocale}
                >
                  <Text style={[Fonts.style.subtitle, { marginTop: 0 }]}>
                    {thisLocale === 'en'
                      ? continueInSpanish
                      : continueInEnglish}
                  </Text>
                </TouchableOpacity>
              </Col>
            </Row>
          </Grid>
          <View style={{ marginTop: Metrics.doubleBaseMargin * 2 }} />
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { account, application, isKiosk, screen, token },
  application: { data, error, fetching },
  i18n: { locale }
}) => ({
  initilized,
  token,
  screen,
  data,
  error,
  fetching,
  application,
  account,
  isKiosk,
  locale
});

const mapDispatchToProps = dispatch => ({
  createApplication: () => dispatch(ApplicationActions.ApplicationCreate()),

  setApplication: application =>
    dispatch(PersistActions.PersistSetApplication(application)),
  setOfflineDocuments: documents =>
    dispatch(PersistActions.PersistSetOfflineDocuments(documents)),
  setDocuments: document => dispatch(DocumentActions.DocumentSuccess(document)),

  resetPersist: () => dispatch(PersistActions.PersistReset()),
  setLocale: locale => dispatch(setLocaleStore(locale))
});

HomeScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);

export default HomeScreen;
