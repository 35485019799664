import { takeLatest, all } from "redux-saga/effects";
import API from "../Services/Api";
import FixtureAPI from "../Services/FixtureApi";
import DebugConfig from "../Config/DebugConfig";

/* ------------- Types ------------- */

import { AppTypes } from "../Redux/AppRedux";
import { AccountTypes } from "../Redux/AccountRedux";
import { ApplicationTypes } from "../Redux/ApplicationRedux";
import { CaseTypes } from "../Redux/CaseRedux";
import { OptionsTypes } from "../Redux/OptionsRedux";
import { DocumentTypes } from "../Redux/DocumentRedux";
import { StartupTypes } from "../Redux/StartupRedux";

/* ------------- Sagas ------------- */

import { locationChange, applicationSync, sendLogs } from "./AppSagas";
import { startup, rehydrated } from "./StartupSagas";

import {
  login,
  createAccount,
  requestOtp,
  verifyOtp,
  forgotPassword,
  changePassword
} from "./AccountSagas";

import {
  getApplications,
  getApplication,
  createApplication,
  updateApplication,
  deleteRecord,
  createDocusign
} from "./ApplicationSagas";

import { getCases, linkCase } from "./CaseSagas";

import {
  // loadApplication,
  setApplication,
  setLocale
} from "./PersistSagas";

import {
  getProgramCodes,
  getOptions,
  getDocTypes,
  getScreeningQuestions
} from "./OptionsSagas";

import {
  getDocuments,
  uploadSignature,
  uploadDocuments,
  deleteDocuments
} from "./DocumentSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = DebugConfig.useFixtures ? FixtureAPI : API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Called at Startup or after redydration
    takeLatest(StartupTypes.STARTUP, startup, api),
    // Called once store has been rehydrated
    // takeLatest('persist/REHYDRATE', rehydrated, api),

    takeLatest(AccountTypes.ACCOUNT_LOGIN, login, api),
    takeLatest(AccountTypes.ACCOUNT_CREATE, createAccount, api),
    takeLatest(AccountTypes.ACCOUNT_REQUEST_OTP, requestOtp, api),
    takeLatest(AccountTypes.ACCOUNT_VERIFY_OTP, verifyOtp, api),
    takeLatest(AccountTypes.ACCOUNT_FORGOT_PASSWORD, forgotPassword, api),
    takeLatest(AccountTypes.ACCOUNT_CHANGE_PASSWORD, changePassword, api),

    takeLatest(ApplicationTypes.APPLICATIONS_GET, getApplications, api),
    takeLatest(ApplicationTypes.APPLICATION_GET, getApplication, api),
    takeLatest(ApplicationTypes.APPLICATION_CREATE, createApplication, api),
    takeLatest(ApplicationTypes.APPLICATION_UPDATE, updateApplication, api),
    takeLatest(ApplicationTypes.APPLICATION_SYNC, applicationSync, api),
    takeLatest(ApplicationTypes.APPLICATION_DELETE_RECORD, deleteRecord, api),
    takeLatest(
      ApplicationTypes.APPLICATION_CREATE_DOCUSIGN,
      createDocusign,
      api
    ),

    takeLatest(CaseTypes.CASES_GET, getCases, api),
    takeLatest(CaseTypes.CASE_LINK, linkCase, api),

    takeLatest(OptionsTypes.OPTIONS_LOAD_PROGRAM_CODES, getProgramCodes, api),
    takeLatest(OptionsTypes.OPTIONS_LOAD_OPTIONS, getOptions, api),
    takeLatest(OptionsTypes.OPTIONS_LOAD_DOC_TYPES, getDocTypes, api),
    takeLatest(
      OptionsTypes.OPTIONS_LOAD_SCREENING_QUESTIONS,
      getScreeningQuestions,
      api
    ),

    takeLatest(DocumentTypes.DOCUMENT_LOAD_DOCUMENTS, getDocuments, api),
    takeLatest(DocumentTypes.DOCUMENT_UPLOAD_SIGNATURE, uploadSignature, api),
    takeLatest(DocumentTypes.DOCUMENT_UPLOAD, uploadDocuments, api),
    takeLatest(DocumentTypes.DOCUMENT_DELETE, deleteDocuments, api),

    takeLatest(AppTypes.APP_SEND_LOGS, sendLogs, api),

    takeLatest("@@i18n/SET_LOCALE", setLocale, api),
    takeLatest("@@router/LOCATION_CHANGE", locationChange, api)
  ]);
}
