import React, { Component } from "react";
import * as R from "ramda";
import { TouchableOpacity, View, Text } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scrollview";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withFormik, Field } from "formik";
import * as yup from "yup";

// import Icon from 'react-native-vector-icons/dist/MaterialIcons';

import { Grid, Row, Col } from "../Components/Grid";

import FormattedMessage from "../Components/FormattedMessage";
import FormGroupText from "../Components/FormGroupText";
import FormGroupSelect from "../Components/FormGroupSelect";
import FormGroupRadio from "../Components/FormGroupRadio";
import FormGroupCheckbox from "../Components/FormGroupCheckbox";
import FullButton from "../Components/FullButton";
import AddressForm from "../Components/AddressForm";

import {
  YES_OPTION,
  NO_OPTION,
  RENTAL_PROGRAM_CODE,
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  TRUE_OPTION,
  FALSE_OPTION,
  MOBILE_HOME_CODE,
} from "../Config/ApplicationConfig";
import {
  getPersonId,
  hasDocument,
  isInteger,
  phoneFormat,
  isPhoneNumber,
} from "../Lib/Utils";

// Styles
import { Fonts, Colors, Metrics } from "../Themes";
import styles from "./Styles/ScreenStyles";
import formStyles from "../Components/Styles/FormStyles";

import { isRequired, isZipcode, isPastYear, zipcodeFormat } from "../Lib/Utils";

import ApplicationActions, {
  checkRentalProgram,
} from "../Redux/ApplicationRedux";

import DATA_STRUCTURE from "../Config/DataStructureConfig";
import Footer from "../Components/Footer";
import SelectUploadDocumentType from "../Components/SelectUploadDocumentType";
import { getDocumentType } from "../Redux/DocumentRedux";

const PROOF_OF_LAND_OWNERSHIP_DOCUMENT_TYPE = "proofOfLandOwnership";
const PROOF_OF_DAMAGE_DOCUMENT_TYPE = "proofOfPropertyDamage";
const TENANT_LEASE_DOCUMENT_TYPE = "tenantLeaseAgreement";

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE_LAND_OWNER = "landOwner";
const BASE_CONTACT = DATA_STRUCTURE.contact;
const APPLICANT_BASE = DATA_STRUCTURE.applicant;

export const DamagedAddressScreenComplete = (application, documents) => {
  return (
    R.pathOr("N/A", [BASE_DAMAGED_ADDRESS, "propertyTypeCode"], application) !==
    "N/A"
  );
};

class DamagedAddressScreen extends Component {
  static navigationOptions = {
    title: I18n.t("menu.DamagedAddress"),
    drawerLabel: I18n.t("menu.DamagedAddress"),
  };

  initilizedForm = false;
  updatingApplication = false;

  componentDidMount() {
    const { initilized, currentForm } = this.props;

    if (initilized && R.path(["values"], currentForm) && !this.formInitilized) {
      this.initilizeForm(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      application,
      values,
      isSubmitting,
      fetching,
      errorText,
      status,
      history,
      setStatus,
      setSubmitting,
    } = nextProps;

    const isRentalProgram = checkRentalProgram(application);

    if (isSubmitting && status === "updatingApplication" && !fetching) {
      setStatus("");
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        if (values.haveProofOfDamageCode === YES_OPTION) {
          history.push("/DamagedAddressImagesScreen");
        } else {
          if (isRentalProgram) {
            // history.push('/TenantsScreen');
            history.push("/RentalInformationScreen");
          } else {
            history.push("/LienOnPropertyScreen");
          }
        }
      }
    }
  }

  renderAddress(addressTypeCode) {
    const { fetching, values } = this.props;
    const copyCheckbox =
      values[BASE_DAMAGED_ADDRESS].copyCheckbox === TRUE_OPTION;
    // console.log(
    //   'copyCheckbox',
    //   values[BASE_DAMAGED_ADDRESS].copyCheckbox,
    //   values[BASE_DAMAGED_ADDRESS].copyCheckbox === TRUE_OPTION
    // );
    let addressHeader = null;
    switch (addressTypeCode) {
      default:
      case ADDRESS_TYPE_PO_BOX:
        addressHeader = (
          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={(ref) => {
                  this._boxNumber = ref;
                }}
                field={`${BASE_DAMAGED_ADDRESS}.boxNumber`}
                labelId="ContactInfo.boxNumber"
                placeholderId="ContactInfo.boxNumber_placeholder"
                autoCapitalize="words"
                returnKeyType="next"
                onSubmitEditing={() => this._City.focus()}
                required
                validate={isRequired}
                disabled={copyCheckbox || fetching}
              />
            </Col>
          </Row>
        );
        break;
      case ADDRESS_TYPE_RURAL_ROUTE:
      case ADDRESS_TYPE_HIGHWAY_CONTRACT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={(ref) => {
                    this._Address1 = ref;
                  }}
                  field={`${BASE_DAMAGED_ADDRESS}.RRHCNumber`}
                  labelId="ContactInfo.RRHCNumber"
                  placeholderId="ContactInfo.RRHCNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._boxNumber.focus()}
                  required
                  validate={isRequired}
                  disabled={copyCheckbox || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={(ref) => {
                    this._boxNumber = ref;
                  }}
                  field={`${BASE_DAMAGED_ADDRESS}.boxNumber`}
                  labelId="ContactInfo.boxNumber"
                  placeholderId="ContactInfo.boxNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  required
                  validate={isRequired}
                  disabled={copyCheckbox || fetching}
                />
              </Col>
            </Row>
          </View>
        );
        break;
      case ADDRESS_TYPE_RESIDENTIAL:
      case ADDRESS_TYPE_APARTMENT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={(ref) => {
                    this._Address = ref;
                  }}
                  field={`${BASE_DAMAGED_ADDRESS}.address`}
                  labelId="ContactInfo.address1"
                  placeholderId="ContactInfo.address1_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address2.focus()}
                  required
                  validate={isRequired}
                  disabled={copyCheckbox || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={(ref) => {
                    this._Address2 = ref;
                  }}
                  field={`${BASE_DAMAGED_ADDRESS}.address2`}
                  labelId="ContactInfo.address2"
                  placeholderId="ContactInfo.address2_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address3.focus()}
                  disabled={copyCheckbox || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={(ref) => {
                    this._Address3 = ref;
                  }}
                  field={`${BASE_DAMAGED_ADDRESS}.address3`}
                  labelId="ContactInfo.address3"
                  placeholderId="ContactInfo.address3_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  disabled={copyCheckbox || fetching}
                />
              </Col>
            </Row>
          </View>
        );
        break;
    }

    if (addressTypeCode)
      return (
        <View>
          {addressHeader}

          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={(ref) => {
                  this._City = ref;
                }}
                field={`${BASE_DAMAGED_ADDRESS}.city`}
                labelId="ContactInfo.city"
                placeholderId="ContactInfo.city_placeholder"
                required
                validate={isRequired}
                disabled={copyCheckbox || fetching}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroupSelect
                ref={(ref) => (this._State = ref)}
                field={`${BASE_DAMAGED_ADDRESS}.stateCode`}
                labelId="ContactInfo.state"
                placeholderId="ContactInfo.state_placeholder"
                optionsKey="state"
                required
                validate={isRequired}
                disabled={copyCheckbox || fetching}
              />
            </Col>
            <Col sm={6}>
              <FormGroupText
                ref={(ref) => {
                  this._Zip = ref;
                }}
                field={`${BASE_DAMAGED_ADDRESS}.zip`}
                labelId="ContactInfo.zipcode"
                placeholderId="ContactInfo.zipcode_placeholder"
                returnKeyType="next"
                maxLength={10}
                keyboardType="numeric"
                format={zipcodeFormat}
                required
                validate={isZipcode}
                disabled={copyCheckbox || fetching}
              />
            </Col>
          </Row>
        </View>
      );
    return null;
  }

  handleToggleCopy = () => {
    const { application, setFieldValue, values, options } = this.props;

    // console.log('copyCheckbox', values[BASE_DAMAGED_ADDRESS].copyCheckbox);

    const copyCheckbox =
      values[BASE_DAMAGED_ADDRESS].copyCheckbox === TRUE_OPTION;

    // setFieldValue(
    //   `${BASE_DAMAGED_ADDRESS}.copyCheckbox`,
    //   values[BASE_DAMAGED_ADDRESS].copyCheckbox
    // );

    const newState = !copyCheckbox;
    if (newState && application && application[BASE_CONTACT]) {
      console.log("copy");

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.addressTypeCode`,
        application[BASE_CONTACT].addressTypeCode
      );

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.boxNumber`,
        application[BASE_CONTACT].boxNumber
      );
      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.RRHCNumber`,
        application[BASE_CONTACT].RRHCNumber
      );

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.address`,
        application[BASE_CONTACT].address
      );
      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.address2`,
        application[BASE_CONTACT].address2
      );
      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.address3`,
        application[BASE_CONTACT].address3
      );

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.city`,
        application[BASE_CONTACT].city
      );

      let contactCityCode = "";
      // Search options for the city code
      Object.keys(options.city).forEach((cityCode) => {
        const city = options.city[cityCode];
        if (city.value === application[BASE_CONTACT].city) {
          contactCityCode = cityCode;
        }
      });
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.cityCode`, contactCityCode);

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.stateCode`,
        application[BASE_CONTACT].stateCode
      );

      setFieldValue(
        `${BASE_DAMAGED_ADDRESS}.zip`,
        application[BASE_CONTACT].zip
      );
    } else {
      console.log("clear");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.boxNumber`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.RRHCNumber`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.address`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.address2`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.address3`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.cityCode`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.city`, "");
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.stateCode`, DEFAULT_STATE_CODE);
      setFieldValue(`${BASE_DAMAGED_ADDRESS}.zip`, "");
    }

    // this.setState({ copyCheckbox: newState });
  };

  render() {
    const {
      initilized,
      handleSubmit,
      options,
      fetching,
      isValid,
      isSubmitting,
      dirty,
      values,
      errors,
      history,
      application,
    } = this.props;

    if (!initilized) return null;

    // const documentType = getDocumentType(application, PROOF_OF_DAMAGE_DOCUMENT_TYPE);
    const proofOfLandOwnershipDocumentType = getDocumentType(
      application,
      PROOF_OF_LAND_OWNERSHIP_DOCUMENT_TYPE
    );
    const tenantLeaseDocumentType = getDocumentType(
      application,
      TENANT_LEASE_DOCUMENT_TYPE
    );

    const copyCheckbox =
      values[BASE_DAMAGED_ADDRESS].copyCheckbox === TRUE_OPTION;

    const singleFamilyHomeOptions = options.is_single_family_home;
    const propertyTypeOptions = options.property_type;

    const disabled = isSubmitting;
    const isRentalProgram = checkRentalProgram(application);

    const addressTypeCode = R.path(
      [BASE_DAMAGED_ADDRESS, "addressTypeCode"],
      values
    );
    const ownLandCode = R.path(["ownLandCode"], values);

    const isUnderConstruction = R.path(
      [BASE_DAMAGED_ADDRESS, "isUnderConstruction"],
      values
    );
    const haveProofOfDamageCode = R.path(["haveProofOfDamageCode"], values);
    const didPropertySufferDamageFromEventCode = R.path(
      ["didPropertySufferDamageFromEventCode"],
      values
    );
    const rentalWereTenantsDisplacedAfterStormCode = R.path(
      ["rentalWereTenantsDisplacedAfterStormCode"],
      values
    );
    const rentalWereUnitsHUDAssistedCode = R.path(
      ["rentalWereUnitsHUDAssistedCode"],
      values
    );

    const landOwnerAddressValues = R.pathOr(
      {},
      [BASE_DAMAGED_ADDRESS, BASE_LAND_OWNER],
      values
    );
    const landOwnerAddressString = `${BASE_DAMAGED_ADDRESS}.${BASE_LAND_OWNER}`;

    // console.log(isValid, JSON.stringify(errors, null, 2));

    // console.log(JSON.stringify(values[BASE_DAMAGED_ADDRESS], null, 2));

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="DamagedAddress.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormGroupCheckbox
                  labelId="DamagedAddress.SameAddress"
                  field={`${BASE_DAMAGED_ADDRESS}.copyCheckbox`}
                  onPress={this.handleToggleCopy}
                />
              </Col>
            </Row>

            <AddressForm
              addressString={BASE_DAMAGED_ADDRESS}
              addressValues={values[BASE_DAMAGED_ADDRESS]}
              disabled={fetching}
            />

            {isRentalProgram && (
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`ownPropertyCode`}
                    labelId="DamagedAddress.ownPropertyCode"
                    optionsKey="yes_no"
                    reverse
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`didOwnPropertyDuringEventCode`}
                  labelId="DamagedAddress.didOwnPropertyDuringEventCode"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            {isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`ownLandCode`}
                      labelId="DamagedAddress.ownLandCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormattedMessage
                      id="DamagedAddress.provideProofOfLandOwnership"
                      style={Fonts.style.subtitle}
                    />
                    <SelectUploadDocumentType
                      base={APPLICANT_BASE}
                      documentType={proofOfLandOwnershipDocumentType.code}
                      buttonLabelId=""
                      hideButton
                      hideDocTypes
                      multiple
                      horiz
                    />
                  </Col>
                </Row>

                {ownLandCode === NO_OPTION && (
                  <View>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={12}>
                            <FormattedMessage
                              id={`DamagedAddress.landOwner.mailingAddress`}
                              style={Fonts.style.sectionHeader}
                            />
                          </Col>
                        </Row>
                        <AddressForm
                          addressString={landOwnerAddressString}
                          addressValues={landOwnerAddressValues}
                          disabled={fetching}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          inputRef={(ref) => {
                            this._firstName = ref;
                          }}
                          field={`landOwner.firstName`}
                          labelId="DamagedAddress.landOwner.firstName"
                          autoCapitalize="words"
                          placeholderId="ContactInfo.firstname_placeholder"
                          returnKeyType="next"
                          required
                          validate={isRequired}
                          onSubmitEditing={(e) => {
                            this._middleName.focus();
                          }}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          inputRef={(ref) => {
                            this._middleName = ref;
                          }}
                          field={`landOwner.middleName`}
                          labelId="DamagedAddress.landOwner.middleName"
                          autoCapitalize="words"
                          placeholderId="ContactInfo.middlename_placeholder"
                          returnKeyType="next"
                          onSubmitEditing={(e) => {
                            this._lastName.focus();
                          }}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          inputRef={(ref) => {
                            this._lastName = ref;
                          }}
                          field={`landOwner.lastName`}
                          labelId="DamagedAddress.landOwner.lastName"
                          autoCapitalize="words"
                          placeholderId="ContactInfo.lastname_placeholder"
                          required
                          validate={isRequired}
                          returnKeyType="next"
                          onSubmitEditing={(e) => {
                            this._lastName.focus();
                          }}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          inputRef={(ref) => {
                            this._PrimaryPhone = ref;
                          }}
                          field={`landOwner.primaryPhone`}
                          labelId="DamagedAddress.landOwner.primaryPhone"
                          placeholderId="ContactInfo.PrimaryPhone_placeholder"
                          maxLength={12}
                          onMaxLength={() => this._Email && this._Email.focus()}
                          keyboardType="phone-pad"
                          format={phoneFormat}
                          validate={isPhoneNumber}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupCheckbox
                          labelId="DamagedAddress.landOwner.primaryPhoneIsMobile"
                          field={`landOwner.primaryPhoneIsMobile`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          inputRef={(ref) => {
                            this._Email = ref;
                          }}
                          field={`landOwner.email`}
                          labelId="DamagedAddress.landOwner.email"
                          placeholderId="ContactInfo.Email_placeholder"
                          maxLength={12}
                          keyboardType="phone-pad"
                          format={phoneFormat}
                          validate={isPhoneNumber}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  </View>
                )}
              </View>
            )}

            {!isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`didLiveAtPropertyDuringEventCode`}
                      labelId="DamagedAddress.didLiveAtPropertyDuringEventCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`didTenantsOccupyDuringEventCode`}
                      labelId="DamagedAddress.didTenantsOccupyDuringEventCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`doTenantsOccupyNowCode`}
                      labelId="DamagedAddress.doTenantsOccupyNowCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </View>
            )}

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${BASE_DAMAGED_ADDRESS}.propertyTypeCode`}
                  labelId="DamagedAddress.PropertyType"
                  options={propertyTypeOptions}
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            {values[BASE_DAMAGED_ADDRESS].propertyTypeCode ===
              MOBILE_HOME_CODE && (
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`mobileHomeLandCode`}
                    labelId="DamagedAddress.OwnLand"
                    optionsKey="mobile_home_land"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <FormGroupText
                  field={`${BASE_DAMAGED_ADDRESS}.yearBuilt`}
                  labelId="DamagedAddress.YearBuilt"
                  placeholderId="DamagedAddress.YearBuilt_placeholder"
                  keyboardType="numeric"
                  validate={isPastYear}
                  maxLength={4}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`isHandicapAccessibilityRequiredCode`}
                  labelId="DamagedAddress.AdaRequired"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`havePropertyRepairsBeenMadeCode`}
                  labelId="DamagedAddress.RepairsMade"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            {!isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`isHomeStillDamagedCode`}
                      labelId="DamagedAddress.isHomeStillDamagedCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {values.isHomeStillDamagedCode === YES_OPTION && (
                  <Row>
                    <Col sm={12}>
                      <FormGroupRadio
                        field={`isHomeStillDamagedTypeCode`}
                        labelId="DamagedAddress.isHomeStillDamagedTypeCode"
                        optionsKey="is_home_still_damaged_type"
                        required
                        validate={isRequired}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                )}
              </View>
            )}
            {isRentalProgram && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`didPropertySufferDamageFromEventCode`}
                      labelId="DamagedAddress.didPropertySufferDamageFromEventCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {didPropertySufferDamageFromEventCode === YES_OPTION && (
                  <Row>
                    <Col sm={12}>
                      <FormGroupRadio
                        field={`haveProofOfDamageCode`}
                        labelId="DamagedAddress.haveProofOfDamageCode"
                        optionsKey="yes_no"
                        reverse
                        required
                        validate={isRequired}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                )}
                {/* {haveProofOfDamageCode === YES_OPTION && (
                  <Row>
                    <Col sm={12}>
                      <FormattedMessage
                        id="DamagedAddress.provideBeforeAndAfter"
                        style={Fonts.style.subtitle}
                      />
                      <SelectUploadDocumentType
                        base={APPLICANT_BASE}
                        documentType={PROOF_OF_DAMAGE_DOCUMENT_TYPE}
                        buttonLabelId=""
                        hideButton
                        hideDocTypes
                        multiple
                        horiz
                      />
                    </Col>
                  </Row>
                )} */}

                {/* <Row>
                  <Col sm={12}>
                    <FormGroupText
                      field={`${BASE_DAMAGED_ADDRESS}.yearBuilt`}
                      labelId="DamagedAddress.YearBuilt"
                      placeholderId="DamagedAddress.YearBuilt_placeholder"
                      keyboardType="numeric"
                      validate={isPastYear}
                      onSubmitEditing={e => {
                        this._numFloors.focus();
                      }}
                      maxLength={4}
                      disabled={disabled}
                    />
                  </Col>
                </Row> */}

                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._numFloors = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.numFloors`}
                      labelId="DamagedAddress.numFloors"
                      placeholderId="DamagedAddress.number_placeholder"
                      returnKeyType="next"
                      required
                      keyboardType="numeric"
                      validate={isInteger}
                      onSubmitEditing={(e) => {
                        this._numUnits.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._numUnits = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.numUnits`}
                      labelId="DamagedAddress.numUnits"
                      placeholderId="DamagedAddress.number_placeholder"
                      returnKeyType="next"
                      required
                      keyboardType="numeric"
                      validate={isInteger}
                      onSubmitEditing={(e) => {
                        this._numUnitsDamagedByEvent.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._numUnitsDamagedByEvent = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.numUnitsDamagedByEvent`}
                      labelId="DamagedAddress.numUnitsDamagedByEvent"
                      placeholderId="DamagedAddress.number_placeholder"
                      returnKeyType="next"
                      required
                      keyboardType="numeric"
                      validate={isInteger}
                      onSubmitEditing={(e) => {
                        this._numUnitsOccupiedDuringEvent.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._numUnitsOccupiedDuringEvent = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.numUnitsOccupiedDuringEvent`}
                      labelId="DamagedAddress.numUnitsOccupiedDuringEvent"
                      placeholderId="DamagedAddress.number_placeholder"
                      returnKeyType="next"
                      required
                      keyboardType="numeric"
                      validate={isInteger}
                      onSubmitEditing={(e) => {
                        this._middleName.focus();
                      }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`${BASE_DAMAGED_ADDRESS}.isHandicapAccessibleCode`}
                      labelId="DamagedAddress.isHandicapAccessibleCode"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`${BASE_DAMAGED_ADDRESS}.isUnderConstructionCode`}
                      labelId="DamagedAddress.isUnderConstruction"
                      optionsKey="yes_no"
                      reverse
                      required
                      validate={isRequired}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {isUnderConstruction === YES_OPTION && (
                  <Row>
                    <Col sm={12}>
                      <FormGroupText
                        inputRef={(ref) => {
                          this._isUnderConstructionRepairsBeingDone = ref;
                        }}
                        field={`${BASE_DAMAGED_ADDRESS}.isUnderConstructionRepairsBeingDone`}
                        labelId="DamagedAddress.isUnderConstructionRepairsBeingDone"
                        placeholderId="DamagedAddress.isUnderConstructionRepairsBeingDone_placeholder"
                        returnKeyType="next"
                        multiline
                        numberOfLines={3}
                        // onSubmitEditing={e => {
                        //   this._middleName.focus();
                        // }}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._remainingScopeOfWork = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.remainingScopeOfWork`}
                      labelId="DamagedAddress.remainingScopeOfWork"
                      placeholderId="DamagedAddress.remainingScopeOfWork_placeholder"
                      returnKeyType="next"
                      multiline
                      numberOfLines={3}
                      // onSubmitEditing={e => {
                      //   this._middleName.focus();
                      // }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={(ref) => {
                        this._securityMeasuresTakenPriorToEvent = ref;
                      }}
                      field={`${BASE_DAMAGED_ADDRESS}.securityMeasuresTakenPriorToEvent`}
                      labelId="DamagedAddress.securityMeasuresTakenPriorToEvent"
                      placeholderId="DamagedAddress.securityMeasuresTakenPriorToEvent_placeholder"
                      returnKeyType="next"
                      multiline
                      numberOfLines={3}
                      // onSubmitEditing={e => {
                      //   this._middleName.focus();
                      // }}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </View>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t("form.continueNextStep")}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   damagedAddress: yup.object().shape({
//     address1: yup.string().required('error.required'),
//     city: yup.string().required('error.required'),
//     stateCode: yup.string().required('error.required'),
//     zip: yup.string().required('error.required'),
//     singleFamilyHome: yup.string().required('error.required'),
//     repairsMadeCode: yup.string().required('error.required')
//   })
// });

DamagedAddressScreen = withFormik({
  mapPropsToValues: (props) => {
    const { application, options } = props;

    const initialApplication = JSON.parse(JSON.stringify(application));
    if (!initialApplication[BASE_DAMAGED_ADDRESS]) {
      initialApplication[BASE_DAMAGED_ADDRESS] = {};
    }
    if (!initialApplication[BASE_DAMAGED_ADDRESS].stateCode) {
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode = DEFAULT_STATE_CODE;
    }

    // Default to questions to Yes
    if (!initialApplication.didOwnPropertyDuringEventCode) {
      initialApplication.didOwnPropertyDuringEventCode = YES_OPTION;
    }
    if (!initialApplication.didLiveAtPropertyDuringEventCode) {
      initialApplication.didLiveAtPropertyDuringEventCode = YES_OPTION;
    }
    if (!initialApplication.didPropertySufferDamageFromEventCode) {
      initialApplication.didPropertySufferDamageFromEventCode = YES_OPTION;
    }

    if (
      !R.path([BASE_DAMAGED_ADDRESS, "address"], initialApplication) &&
      initialApplication[BASE_CONTACT]
    ) {
      // Start as Blank
      initialApplication[BASE_DAMAGED_ADDRESS].copyCheckbox = FALSE_OPTION;

      initialApplication[BASE_DAMAGED_ADDRESS].addressTypeCode = "";
      initialApplication[BASE_DAMAGED_ADDRESS].boxNumber = "";
      initialApplication[BASE_DAMAGED_ADDRESS].RRHCNumber = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address2 = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address3 = "";
      initialApplication[BASE_DAMAGED_ADDRESS].city = "";
      initialApplication[BASE_DAMAGED_ADDRESS].cityCode = "";
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode = DEFAULT_STATE_CODE;
      initialApplication[BASE_DAMAGED_ADDRESS].zip = "";
      // console.log('COPY FROM CONTACT');

      // initialApplication[BASE_DAMAGED_ADDRESS].copyCheckbox = TRUE_OPTION;
      // initialApplication[BASE_DAMAGED_ADDRESS].addressTypeCode =
      //   initialApplication[BASE_CONTACT].addressTypeCode;
      // initialApplication[BASE_DAMAGED_ADDRESS].boxNumber =
      //   initialApplication[BASE_CONTACT].boxNumber;
      // initialApplication[BASE_DAMAGED_ADDRESS].RRHCNumber =
      //   initialApplication[BASE_CONTACT].RRHCNumber;
      // initialApplication[BASE_DAMAGED_ADDRESS].address =
      //   initialApplication[BASE_CONTACT].address;
      // initialApplication[BASE_DAMAGED_ADDRESS].address2 =
      //   initialApplication[BASE_CONTACT].address2;
      // initialApplication[BASE_DAMAGED_ADDRESS].address3 =
      //   initialApplication[BASE_CONTACT].address3;
      // initialApplication[BASE_DAMAGED_ADDRESS].city =
      //   initialApplication[BASE_CONTACT].city;
      // initialApplication[BASE_DAMAGED_ADDRESS].cityCode =
      //   initialApplication[BASE_CONTACT].cityCode;
      // initialApplication[BASE_DAMAGED_ADDRESS].stateCode =
      //   initialApplication[BASE_CONTACT].stateCode;
      // initialApplication[BASE_DAMAGED_ADDRESS].zip =
      //   initialApplication[BASE_CONTACT].zip;
    } else if (!initialApplication[BASE_DAMAGED_ADDRESS]) {
      initialApplication[BASE_DAMAGED_ADDRESS].copyCheckbox = FALSE_OPTION;

      initialApplication[BASE_DAMAGED_ADDRESS].addressTypeCode = "";
      initialApplication[BASE_DAMAGED_ADDRESS].boxNumber = "";
      initialApplication[BASE_DAMAGED_ADDRESS].RRHCNumber = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address2 = "";
      initialApplication[BASE_DAMAGED_ADDRESS].address3 = "";
      initialApplication[BASE_DAMAGED_ADDRESS].city = "";
      initialApplication[BASE_DAMAGED_ADDRESS].cityCode = "";
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode = DEFAULT_STATE_CODE;
      initialApplication[BASE_DAMAGED_ADDRESS].zip = "";
    } else if (
      initialApplication[BASE_CONTACT] &&
      initialApplication[BASE_DAMAGED_ADDRESS] &&
      initialApplication[BASE_DAMAGED_ADDRESS].addressTypeCode ===
        initialApplication[BASE_CONTACT].addressTypeCode &&
      initialApplication[BASE_DAMAGED_ADDRESS].boxNumber ===
        initialApplication[BASE_CONTACT].boxNumber &&
      initialApplication[BASE_DAMAGED_ADDRESS].RRHCNumber ===
        initialApplication[BASE_CONTACT].RRHCNumber &&
      initialApplication[BASE_DAMAGED_ADDRESS].address2 ===
        initialApplication[BASE_CONTACT].address2 &&
      initialApplication[BASE_DAMAGED_ADDRESS].address3 ===
        initialApplication[BASE_CONTACT].address3 &&
      initialApplication[BASE_DAMAGED_ADDRESS].city ===
        initialApplication[BASE_CONTACT].city &&
      initialApplication[BASE_DAMAGED_ADDRESS].cityCode ===
        initialApplication[BASE_CONTACT].cityCode &&
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode ===
        initialApplication[BASE_CONTACT].stateCode &&
      initialApplication[BASE_DAMAGED_ADDRESS].zip ===
        initialApplication[BASE_CONTACT].zip
    ) {
      initialApplication[BASE_DAMAGED_ADDRESS].copyCheckbox = TRUE_OPTION;
    } else {
      initialApplication[BASE_DAMAGED_ADDRESS].copyCheckbox = FALSE_OPTION;
    }

    // Copy City String to City Code
    if (
      initialApplication[BASE_DAMAGED_ADDRESS].stateCode ===
        DEFAULT_STATE_CODE &&
      initialApplication[BASE_DAMAGED_ADDRESS].city &&
      options &&
      options.city
    ) {
      Object.keys(options.city).forEach((cityCode) => {
        const city = options.city[cityCode];
        if (city.value === initialApplication[BASE_DAMAGED_ADDRESS].city) {
          initialApplication[BASE_DAMAGED_ADDRESS].cityCode = cityCode;
        }
      });
    }

    console.log(
      "setting initialApplication",
      JSON.stringify(initialApplication, null, 2)
    );
    return initialApplication;
  },
  isInitialValid: () => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication, options } = props;

    const newValues = JSON.parse(JSON.stringify(values));

    // Copy cityCode to city if DEFAULT_STATE_CODE
    if (newValues[BASE_DAMAGED_ADDRESS].stateCode === DEFAULT_STATE_CODE) {
      newValues[BASE_DAMAGED_ADDRESS].city = R.pathOr(
        "",
        ["city", newValues[BASE_DAMAGED_ADDRESS].cityCode, "value"],
        options
      );
    }

    console.log("updateApplication", newValues);
    updateApplication(newValues);
    setStatus("updatingApplication");
  },
})(DamagedAddressScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, errorText, data },
  persist: { options, application },
}) => ({
  options,
  initilized,
  fetching,
  errorText,
  data,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  updateApplication: (application) =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DamagedAddressScreen);
