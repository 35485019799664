import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';

// import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from '../Components/Grid';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  zipcodeFormat,
  getPersonId,
  getBase,
  getBaseString,
  floatFormat,
  formatCurrency
} from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentType } from '../Redux/DocumentRedux';
import { isKiosk } from '../Redux/AccountRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import UploadDocumentType from '../Components/UploadDocumentType';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';
import formStyles from '../Components/Styles/FormStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import { YES_OPTION, NO_OPTION } from '../Config/ApplicationConfig';
import Footer from '../Components/Footer';

const PROOF_OF_INSURANCE_DOCUMENT_TYPE = 'proofOfInsurance';
const PROOF_OF_INSURANCE_KEYWORD_VALUE = ''; // Proof of Insurance

const PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE = 'proofOfPropertyDamage';
const PROOF_OF_PROPERTY_DAMAGE_KEYWORD = 'proofOfInsurancePayout';

const BASE = DATA_STRUCTURE.insurance;

class InsurancePolicy extends Component {
  state = {
    editingIndex: null,
    documentErrorText: null,
    errorText: null
  };

  formInitilized = false;
  formInitilized = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;
  savingApplication = false;

  // componentDidMount() {
  //   const { initilized, currentForm } = this.props;

  //   if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
  //     this.formInitilized = true;
  //     this.initilizeForm(this.props);
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('/InsuranceScreen');
      }
    }
  }

  render() {
    const {
      base,
      match: {
        params: { index }
      },
      initilized,
      handleSubmit,
      isValid,
      fetching,
      application,
      documents,
      documentsFetching,
      values,
      isSubmitting,
      errors
    } = this.props;

    const appBase = getBase(values, BASE, index);
    const baseString = getBaseString(BASE, index);

    const { documentErrorText, errorText } = this.state;

    if (!initilized) return null;

    const personId = getPersonId(application, base, index);
    const proofOfInsuranceDocumentType = getDocumentType(
      application,
      PROOF_OF_INSURANCE_DOCUMENT_TYPE
    );
    const proofOfPropertyDamageDocumentType = getDocumentType(
      application,
      PROOF_OF_PROPERTY_DAMAGE_DOCUMENT_TYPE
    );
    const insurancePayoutKeywordValue =
      proofOfPropertyDamageDocumentType.keywords[
        PROOF_OF_PROPERTY_DAMAGE_KEYWORD
      ];

    // const photos = [];
    // documents &&
    //   documents.forEach(ele => {
    //     if (ele) {
    //       if (ele.personId === personId && ele.documentType === DOCUMENT_TYPE) {
    //         photos.push(ele.fileDownloadURL);
    //       }
    //     }
    //   });
    // const driversLicense = { photos };

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    const documentErrorView = documentErrorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage
          id="error.submissionError"
          style={styles.documentErrorText}
        />
        <Text style={styles.documentErrorText}>{documentErrorText}</Text>
      </View>
    );

    const disabled = isSubmitting;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="InsurancePolicy.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="InsurancePolicy.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>{errorView}</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._companyName = ref;
                  }}
                  field={`${baseString}.companyName`}
                  labelId="DamagedAddress.InsuranceCarrierName"
                  placeholderId="DamagedAddress.InsuranceCarrierName_placeholder"
                  returnKeyType="next"
                  onSubmitEditing={() => this._policyNumber.focus()}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._policyNumber = ref;
                  }}
                  field={`${baseString}.policyNumber`}
                  labelId="DamagedAddress.PolicyNumber"
                  placeholderId="DamagedAddress.PolicyNumber_placeholder"
                  returnKeyType="next"
                  onSubmitEditing={() => this._companyPhone.focus()}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._companyPhone = ref;
                  }}
                  field={`${baseString}.companyPhone`}
                  labelId="DamagedAddress.InsurancePhoneNumber"
                  maxLength={12}
                  placeholderId="DamagedAddress.InsurancePhoneNumber_placeholder"
                  keyboardType="phone-pad"
                  format={phoneFormat}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.typeCode`}
                  labelId="InsurancePolicy.InsuranceType"
                  optionsKey="insurance_type"
                  disabled={fetching}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.isPolicyInEffectCode`}
                  labelId="InsurancePolicy.isPolicyInEffectCode"
                  optionsKey="yes_no"
                  reverse
                  disabled={fetching}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col xs={12}>
                    <FormattedMessage
                      id="InsurancePolicy.dateOfExpiration"
                      style={Fonts.style.sectionHeader}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <FormGroupText
                      inputRef={ref => {
                        this._DateOfBirthMonth = ref;
                      }}
                      field={`tmp.Month`}
                      labelId="ContactInfo.dateofbirthmonth"
                      placeholderId="ContactInfo.dateofbirthmonth_placeholder"
                      keyboardType="numeric"
                      returnKeyType="next"
                      maxLength={2}
                      onMaxLength={() => this._DateOfBirthDay.focus()}
                      onSubmitEditing={() => this._DateOfBirthDay.focus()}
                      validate={isMonth}
                      disabled={disabled}
                    />
                  </Col>
                  <Col xs={4}>
                    <FormGroupText
                      inputRef={ref => {
                        this._DateOfBirthDay = ref;
                      }}
                      field={`tmp.Day`}
                      labelId="ContactInfo.dateofbirthday"
                      placeholderId="ContactInfo.dateofbirthday_placeholder"
                      maxLength={2}
                      keyboardType="numeric"
                      returnKeyType="next"
                      onMaxLength={() => this._DateOfBirthYear.focus()}
                      onSubmitEditing={() => this._DateOfBirthYear.focus()}
                      validate={isDay}
                      disabled={disabled}
                    />
                  </Col>
                  <Col xs={4}>
                    <FormGroupText
                      inputRef={ref => {
                        this._DateOfBirthYear = ref;
                      }}
                      field={`tmp.Year`}
                      labelId="ContactInfo.dateofbirthyear"
                      placeholderId="ContactInfo.dateofbirthyear_placeholder"
                      maxLength={4}
                      keyboardType="numeric"
                      returnKeyType="next"
                      validate={isYear}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
                {!R.path(['tmp', 'Day'], errors) &&
                  !R.path(['tmp', 'Month'], errors) &&
                  !R.path(['tmp', 'Year'], errors) &&
                  errors.dateOfExpiration && (
                    <Row>
                      <Col xs={12}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <Icon
                            name="md-alert"
                            size={20}
                            style={{
                              color: Colors.error,
                              margin: Metrics.baseMargin
                            }}
                          />
                          <FormattedMessage
                            id={errors.dateOfExpiration}
                            style={[
                              Fonts.type.normal,
                              {
                                color: Colors.error,
                                paddingRight: Metrics.baseMargin
                              }
                            ]}
                          />
                        </View>
                      </Col>
                    </Row>
                  )}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.wasClaimFiledCode`}
                  labelId="InsurancePolicy.wasClaimFiledCode"
                  optionsKey="yes_no"
                  reverse
                  disabled={fetching}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            {appBase.wasClaimFiledCode === YES_OPTION && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`${baseString}.wasClaimApprovedCode`}
                      labelId="InsurancePolicy.wasClaimApprovedCode"
                      optionsKey="yes_no"
                      reverse
                      disabled={fetching}
                      required
                      validate={isRequired}
                    />
                  </Col>
                </Row>
                {appBase.wasClaimApprovedCode === YES_OPTION && (
                  <View>
                    <Row>
                      <Col sm={12}>
                        <FormGroupText
                          field={`${baseString}.claimPurpose`}
                          labelId="InsurancePolicy.claimPurpose"
                          // placeholderId="InsurancePolicy.claimPurpose_placeholder"
                          placeholderId=""
                          disabled={fetching}
                          required
                          validate={isRequired}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <FormGroupRadio
                          field={`${baseString}.didReceiveFundsCode`}
                          labelId="InsurancePolicy.didReceiveFundsCode"
                          optionsKey="yes_no"
                          reverse
                          disabled={fetching}
                          required
                          validate={isRequired}
                        />
                      </Col>
                    </Row>
                  </View>
                )}
              </View>
            )}
            {appBase.didReceiveFundsCode === YES_OPTION && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      field={`${baseString}.amount`}
                      labelId="InsurancePolicy.FundsAmount"
                      placeholderId="InsurancePolicy.FundsAmount_placeholder"
                      disabled={fetching}
                      keyboardType="numeric"
                      format={floatFormat}
                      required
                      validate={isRequired}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormattedMessage
                      id="InsurancePolicy.submitProofOfPayment"
                      style={Fonts.style.sectionHeader}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <UploadDocumentType
                      personId={personId}
                      base={BASE}
                      index={index}
                      documentType={proofOfInsuranceDocumentType.code}
                      documentKeywordValue={PROOF_OF_INSURANCE_KEYWORD_VALUE}
                      disabled={documentsFetching}
                      multiple
                    />
                  </Col>
                </Row>
              </View>
            )}

            <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`${baseString}.isInvolvedInAppealOrLawsuitCode`}
                  labelId="InsurancePolicy.isInvolvedInAppealOrLawsuitCode"
                  optionsKey="yes_no"
                  reverse
                  disabled={fetching}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="InsurancePolicy.UploadDocuments"
                  style={Fonts.style.sectionHeader}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <UploadDocumentType
                  personId={personId}
                  base={BASE}
                  index={index}
                  documentType={proofOfPropertyDamageDocumentType.code}
                  documentKeywordValue={insurancePayoutKeywordValue}
                  disabled={documentsFetching}
                  multiple
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

InsurancePolicy = withFormik({
  mapPropsToValues: props => {
    const {
      application,
      match: {
        params: { index }
      }
    } = props;
    const initialApplication = JSON.parse(JSON.stringify(application));
    const appBase = getBase(application, BASE, index);

    // Format Amount as Currency
    if (appBase.amount) {
      initialApplication[BASE][index].amount = formatCurrency(appBase.amount);

      initialApplication[BASE][index].receivedFunds = YES_OPTION;
    } else if (R.path([BASE, index, 'phoneNumber'], application)) {
      // Existing Insurance Policy
      initialApplication[BASE][index].receivedFunds = NO_OPTION;
      // change(`${baseString}.receivedFunds`, '1');
    }

    if (appBase && appBase.dateOfExpiration) {
      const dob = moment(appBase.dateOfExpiration, 'M-D-YYYY');
      initialApplication.tmp = {
        Month: dob.format('M'),
        Day: dob.format('D'),
        Year: dob.format('YYYY')
      };
      // console.log('LOADING', initialApplication.tmp);
    } else {
      initialApplication.tmp = {
        Month: '',
        Day: '',
        Year: ''
      };
    }

    return initialApplication;
  },
  isInitialValid: props => {
    const {
      application,
      match: {
        params: { index }
      }
    } = props;
    return application[BASE][index].typeCode;
  },

  validate: (values, props) => {
    const errors = {};

    const { Month, Day, Year } = values.tmp;
    if (Month && Day && Year) {
      const dob = `${Month}-${Day}-${Year}`;
      const dateOfExpiration = moment(dob, 'M-D-YYYY');
      if (!dateOfExpiration.isValid()) {
        errors.dateOfExpiration = 'error.invalidDate';
      }
    }

    return errors;
  },
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      updateApplication,
      match: {
        params: { index }
      }
    } = props;

    let newValues = JSON.parse(JSON.stringify(values));

    // Remove Non Digits
    if (newValues[BASE][index].amount) {
      newValues[BASE][index].amount = newValues[BASE][index].amount.replace(
        /[^\d.-]/g,
        ''
      );
    }

    const {
      tmp: { Month, Day, Year }
    } = values;

    const dob = `${Month}-${Day}-${Year}`;
    // console.log('SAVING ', dob);

    newValues[BASE][index].dateOfExpiration = dob;

    // Remove Temp Values
    delete newValues.tmp;

    // console.log('updatingApplication', newValues);
    updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(InsurancePolicy);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application },
  document,
  form
}) => ({
  options,
  data,
  initilized,
  fetching,
  errorText: error,

  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,

  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsurancePolicy);
