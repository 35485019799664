import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import { isKiosk } from '../Redux/AccountRedux';
import ApplicationActions, {
  checkRentalProgram
} from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
// import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId
} from '../Lib/Utils';

// Styles
import { Fonts, Metrics, Colors } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  DEFAULT_STATE_CODE,
  RENTAL_PROGRAM_CODE
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';

const BASE = DATA_STRUCTURE['coApplicant'];

class CoapplicantExistsScreen extends Component {
  state = {
    errorText: null
  };

  componentDidMount() {
    const { initilized, currentForm } = this.props;

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(this.props);
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    const isRentalProgram = checkRentalProgram(application);

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(nextProps);
    // }
    // console.log('status', status);
    // console.log('isSubmitting', isSubmitting);
    // console.log('fetching', fetching);

    if (isSubmitting && status === 'creatingCoApplicant' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('CoapplicantIdentificationScreen');
      }
    }

    if (isSubmitting && status === 'deletingCoApplicant' && !fetching) {
      setStatus('');
      setSubmitting(false);
      console.log('back from deleting coapplicant');
      if (isRentalProgram) {
        // history.push('TenantsScreen');
        history.push('/DamagedAddressScreen');
      } else {
        history.push('/HouseholdMembersScreen');
      }
    }

    // if (this.updatingApplication && !fetching) {
    //   this.updatingApplication = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //     // Scroll to top
    //     this._scroll.scrollTo({ x: 0, y: 0 });
    //   } else {
    //     console.log('onComplete', data);
    //     // onComplete(data);
    //   }
    // }
  }

  render() {
    const {
      options,
      errors,
      base,
      index,
      application,
      initilized,
      handleSubmit,
      documents,
      isValid,

      fetching,
      values
    } = this.props;

    // console.log('isValid', isValid);
    // console.log('errors', JSON.stringify(errors, null, 2));
    // console.log(BASE, JSON.stringify(application[BASE], null, 2));

    const { errorText } = this.state;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id={`${BASE}.title`}
                style={Fonts.style.title}
              />
              <FormattedMessage
                id={`${BASE}.subtitle`}
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field={`YesNo`}
                labelId={`${BASE}.isThereCoHomeowner`}
                optionsKey="yes_no"
                reverse
                // validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={() => {
                  // this.props.updateApplication(this.props.application);
                  console.log('handleSubmit');
                  handleSubmit();
                }}
                disabled={!isValid || fetching}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
        <View style={{ flex: 1 }} />
        <View style={{ flex: 1 }} />
        <Footer />
      </KeyboardAwareScrollView>
    );
  }
}

const validationSchema = yup.object().shape({
  YesNo: yup.string().required('error.required')
});

CoapplicantExistsScreen = withFormik({
  mapPropsToValues: props => {
    return { YesNo: props.application[BASE] ? '2' : undefined };
  },
  isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      base,
      index,
      history,
      application,
      updateApplication,
      deleteRecord
    } = props;
    // console.log('handleSubmit', JSON.stringify(values, null, 2));

    const isRentalProgram = checkRentalProgram(application);

    const newValues = Object.assign({}, application);

    if (values.YesNo && values.YesNo === '2') {
      // Yes
      if (!newValues[BASE]) {
        // Create CoApplicant
        newValues[BASE] = { id: '-1' };
        updateApplication(newValues);
        setStatus('creatingCoApplicant');
      } else {
        history.push('CoapplicantIdentificationScreen');
      }
    } else if (values.YesNo && values.YesNo === '1') {
      // No
      if (newValues[BASE] && newValues[BASE].id) {
        // Delete CoApplicant
        // this.deletingCoApplicant = true;
        console.log('deleteRecord', BASE, newValues[BASE].id);
        deleteRecord(newValues[BASE].id);
        setStatus('deletingCoApplicant');
      } else {
        if (isRentalProgram) {
          // history.push('TenantsScreen');
          history.push('/DamagedAddressScreen');
        } else {
          history.push('/HouseholdMembersScreen');
        }
      }
    }
  }
})(CoapplicantExistsScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId }
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: memberId =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoapplicantExistsScreen);
