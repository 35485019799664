import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Grid, Row, Col } from '../../Components/Grid';
import ApplicationActions from '../../Redux/ApplicationRedux';
import DocumentActions, {
  getDocumentURL,
  getDocumentType
} from '../../Redux/DocumentRedux';

import FormGroupRadio from '../../Components/FormGroupRadio';
import FormattedMessage from '../../Components/FormattedMessage';
import FullButton from '../../Components/FullButton';
// import CaptureUploadDocuments from '../../Components/CaptureUploadDocuments';
import UploadDocumentType from '../../Components/UploadDocumentType';
import { getBase, getBaseString, getPersonId } from '../../Lib/Utils';
import { isRequired } from '../../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../../Themes/';
import styles from '../Styles/ScreenStyles';
import DOCUMENT_TYPES from '../../Config/DocumentTypesConfig';
import {
  YES_OPTION,
  TRUE_OPTION,
  FALSE_OPTION,
  NO_OPTION
} from '../../Config/ApplicationConfig';
import ErrorMessage from '../../Components/ErrorMessage';
import Footer from '../../Components/Footer';

const FEDERAL_DOCUMENT_TYPE = 'federalTaxReturn';
const STATE_DOCUMENT_TYPE = 'stateTaxReturn';
// const DOCUMENT_KEYWORD_VALUE = DOCUMENT_TYPES.proofOfIncome.keywords.TaxReturn;

class UploadTaxReturn extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired
  };

  state = {
    loading: true,
    uploading: false
  };
  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
      values
    } = nextProps;

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(nextProps);
    // }
    // console.log('status', status);
    // console.log('isSubmitting', isSubmitting);
    // console.log('fetching', fetching);

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        const { federalOptions } = values;

        if (federalOptions === '2') {
          onComplete('IRS4506T');
        } else {
          onComplete('OtherIncome');
        }
      }
    }
  }
  render() {
    const {
      application,
      base,
      index,
      initilized,
      documents,
      fetching,
      values,
      onComplete,
      handleSubmit,
      onCancel,
      onSkip
    } = this.props;
    const { errorText } = this.state;

    if (!initilized) return null;

    const personId = getPersonId(application, base, index);
    const stateDocumentType = getDocumentType(application, STATE_DOCUMENT_TYPE);
    const federalDocumentType = getDocumentType(
      application,
      FEDERAL_DOCUMENT_TYPE
    );
    const baseString = getBaseString(base, index);
    const appBase = getBase(values, base, index);

    const federalOptions = {
      '1': { code: '1', value: I18n.t('UploadTaxReturn.fileFederalRadio1') },
      '2': { code: '2', value: I18n.t('UploadTaxReturn.fileFederalRadio2') },
      '3': { code: '3', value: I18n.t('UploadTaxReturn.fileFederalRadio3') },
      '4': { code: '4', value: I18n.t('UploadTaxReturn.fileFederalRadio4') }
    };

    const showFederalUpload = values.federalOptions === '1';

    // const fileFederal =
    //   appBase.didYouFileFederalIncomeTaxReturnCode === YES_OPTION;
    const fileState = appBase.didYouFileStateIncomeTaxReturnCode === YES_OPTION;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
          ref="scroll"
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="UploadTaxReturn.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="UploadTaxReturn.subtitle"
                  style={Fonts.style.subtitle}
                />
                <ErrorMessage errorText={errorText} />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroupRadio
                  field={`federalOptions`}
                  labelId="UploadTaxReturn.fileFederalTaxReturn"
                  options={federalOptions}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            {showFederalUpload && (
              <UploadDocumentType
                base={base}
                index={index}
                documentType={federalDocumentType.code}
                // documentKeywordValue={DOCUMENT_KEYWORD_VALUE}
              />
            )}

            <Row>
              <Col>
                <FormGroupRadio
                  field={`${baseString}.didYouFileStateIncomeTaxReturnCode`}
                  labelId="UploadTaxReturn.fileStateTaxReturn"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>
            {fileState && (
              <UploadDocumentType
                base={base}
                index={index}
                documentType={stateDocumentType.code}
                // documentKeywordValue={DOCUMENT_KEYWORD_VALUE}
              />
            )}
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={fetching}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={6}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  // disabled={photos.length === 0 || fetching}
                />
              </Col>
              <Col sm={6}>
                <FullButton
                  text={I18n.t('buttons.IDontHaveMyTaxReturn')}
                  onPress={onCancel}
                  type="small"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('buttons.SkipIncome')}
                  type="link"
                  onPress={onSkip}
                />
              </Col>
            </Row> */}
          </Grid>
          <View style={{ marginTop: Metrics.doubleBaseMargin }} />
          <View style={{ flex: 1 }} />
          <Footer />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

UploadTaxReturn = withFormik({
  mapPropsToValues: props => {
    const { base, index, application, change, token, account } = props;

    const newApplication = Object.assign({}, application);
    if (index >= 0) {
      if (
        R.path([base, index, 'isTaxReturn4506TRequested'], newApplication) ===
        TRUE_OPTION
      ) {
        newApplication.federalOptions = '2';
      } else if (
        R.path(
          [base, index, 'didYouFileFederalIncomeTaxReturnCode'],
          newApplication
        ) === NO_OPTION
      ) {
        newApplication.federalOptions = '4';
      }
    } else {
      if (
        R.path([base, 'isTaxReturn4506TRequested'], newApplication) ===
        TRUE_OPTION
      ) {
        newApplication.federalOptions = '2';
      } else if (
        R.path(
          [base, 'didYouFileFederalIncomeTaxReturnCode'],
          newApplication
        ) === NO_OPTION
      ) {
        newApplication.federalOptions = '4';
      }
    }

    return newApplication;
  },

  // validate: (values, props) => {
  //   // console.log('validating', values);
  //   const errors = {};
  //   // if (
  //   //   R.path(['contact', 'preferredContactMethod'], values) &&
  //   //   values.contact.preferredContactMethod === '3' &&
  //   //   !R.path(['contact', 'email'], values)
  //   // ) {
  //   //   errors[CONTACT_BASE] = {};
  //   //   errors[CONTACT_BASE].email = 'emailRequiredMethod';
  //   // }
  //   const { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear } = values.tmp;
  //   if (DateOfBirthMonth && DateOfBirthDay && DateOfBirthYear) {
  //     const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
  //     const dateOfBirth = moment(dob, 'M-D-YYYY');
  //     if (!dateOfBirth.isValid()) {
  //       errors.DateOfBirth = 'error.invalidDate';
  //     }
  //   }

  //   return errors;
  // },

  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { base, index } = props;
    console.log('handleSubmit', JSON.stringify(values, null, 2));

    // let newValues = Immutable.asMutable(values) // Does not work
    const newValues = JSON.parse(JSON.stringify(values));

    // Yes and I authorize the program to request it from the IRS
    if (newValues.federalOptions === '2') {
      if (index >= 0) {
        newValues[base][index].isTaxReturn4506TRequested = TRUE_OPTION;
      } else {
        newValues[base].isTaxReturn4506TRequested = TRUE_OPTION;
      }
    } else {
      if (index >= 0) {
        newValues[base][index].isTaxReturn4506TRequested = FALSE_OPTION;
      } else {
        newValues[base].isTaxReturn4506TRequested = FALSE_OPTION;
      }
    }

    // No
    if (newValues.federalOptions === '4') {
      if (index >= 0) {
        newValues[base][index].didYouFileFederalIncomeTaxReturnCode = NO_OPTION;
      } else {
        newValues[base].didYouFileFederalIncomeTaxReturnCode = NO_OPTION;
      }
    } else {
      if (index >= 0) {
        newValues[base][
          index
        ].didYouFileFederalIncomeTaxReturnCode = YES_OPTION;
      } else {
        newValues[base].didYouFileFederalIncomeTaxReturnCode = YES_OPTION;
      }
    }
    delete newValues.federalOptions;

    console.log('updateApplication', JSON.stringify(newValues, null, 2));
    props.updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(UploadTaxReturn);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId }
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTaxReturn);
