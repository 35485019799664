import React, { Component } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';
import { withFormik, Field } from 'formik';

import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from '../Components/Grid';

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentType } from '../Redux/DocumentRedux';

// import TopNav from '../Components/TopNav';
import FullButton from '../Components/FullButton';

import {
  getBase,
  getPersonId,
  hasDocument,
  personHasIncome
} from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes';

import {
  RENTAL_PROGRAM_CODE,
  YES_OPTION,
  NO_OPTION,
  TRUE_OPTION
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import Footer from '../Components/Footer';

const BASE = DATA_STRUCTURE.propertyOwner;

const DOCUMENT_TYPE = 'proofOfIdentification';
const DRIVERS_LICENSE_KEYWORD_VALUE = 'DriversLicense';

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const PropertyOwnersScreenComplete = (application, documents) => {
  let propertyOwnersGood = true;

  if (application[BASE]) {
    let totalPercent = 0;
    application[BASE].forEach(
      ele => (totalPercent += parseFloat(ele.percentOwnership))
    );
    if (totalPercent !== 100) propertyOwnersGood = false;
  }

  return propertyOwnersGood;
};

class PropertyOwnersScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null
  };

  creatingPropertyOwner = false;
  deletingPropertyOwner = false;

  componentDidMount() {
    // this.props.loadDocuments(DOCUMENT_TYPE);
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'creatingPropertyOwner' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        console.log('DONE');
        // history.push('CoapplicantIdentificationScreen');
        const lastIndex = application[BASE].length - 1;
        this.props.history.push(`/PropertyOwner/${lastIndex}/Information`);
      }
    }

    // if (this.creatingPropertyOwner && !fetching) {
    //   this.creatingPropertyOwner = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //   } else if (application && application[BASE]) {
    //     console.log(application);
    //     this.setState({ editingIndex: application[BASE].length - 1 });
    //   } else {
    //     this.setState({ errorText: 'Error Adding Household Member' });
    //   }
    // } else if (this.deletingPropertyOwner && !fetching) {
    //   this.deletingPropertyOwner = false;
    // }
  }

  getNextIndex() {
    const { application } = this.props;

    if (application[BASE] && Object.keys(application[BASE]).length) {
      console.log('next', Object.keys(application[BASE]).length);
      return Object.keys(application[BASE]).length;
    }
    return 0;
  }

  // handleCompleteScan = () => {
  //   this.setState({ currentScreen: 'ContactInfo' });
  // };

  // onComplete = () => {
  //   console.log('onComplete');
  //   this.setState({ editingIndex: null, currentScreen: null });
  // };
  // onCancel = () => {
  //   this.setState({ currentScreen: 'RetrieveTaxReturn' });
  // };

  // onScanComplete = () => {
  //   console.log('onScanComplete');
  // };

  // handleGoToScreen = (currentScreen) => {
  //   this.setState({ currentScreen });
  // };

  // handleContactInfoComplete = () => {
  //   const { application } = this.props;
  //   const { editingIndex } = this.state;
  //   // Check to See if we need to go to Income

  //   const householdMember = getBase(application, BASE, editingIndex);
  //   const dateOfBirth = moment(householdMember.dateOfBirth, 'M/D/YYYY');
  //   const age = householdMember.dateOfBirth ? moment().diff(dateOfBirth, 'years') : 0;

  //   if (age < 18 || application.programCode.toString() === RENTAL_PROGRAM_CODE.toString()) {
  //     this.setState({ editingIndex: null, currentScreen: null });
  //   } else {
  //     this.setState({ currentScreen: 'UploadTaxReturn' });
  //   }
  // };

  handleAddMember = () => {
    const {
      application,
      updateApplication,
      setStatus,
      setSubmitting
    } = this.props;

    this.creatingPropertyOwner = true;
    const newApplication = JSON.parse(JSON.stringify(application));

    if (!newApplication[BASE]) {
      newApplication[BASE] = [];
    }
    newApplication[BASE].push({ id: '-1' });
    console.log('handleAddMember', JSON.stringify(newApplication));
    updateApplication(newApplication);
    setStatus('creatingPropertyOwner');
    setSubmitting(true);
  };

  handleEdit(editingIndex) {
    console.log('editingIndex', editingIndex, typeof editingIndex);

    this.props.history.push(`/PropertyOwner/${editingIndex}/Information`); // Identification / Information

    // // Force Int
    // if (typeof editingIndex === 'string') {
    //   this.setState({ editingIndex: parseInt(editingIndex) });
    // } else {
    //   this.setState({ editingIndex });
    // }
  }

  handleDelete(personId, key) {
    const { deleteRecord } = this.props;
    console.log(personId, key);
    // Remove application.BASE[idx]
    this.deletingPropertyOwner = true;
    deleteRecord(personId, key);
  }

  renderPropertyOwners = () => {
    const { application, documents } = this.props;
    const documentType = getDocumentType(application, DOCUMENT_TYPE);
    let idx = 1;
    const members = [];
    const iconStyle = {
      color: 'red',
      marginHorizontal: Metrics.baseMargin
    };

    Object.keys(application[BASE]).forEach(key => {
      if (isNumeric(key)) {
        const member = application[BASE][key] || {};

        members.push(
          <View
            key={`member_${key}`}
            style={{
              flex: 1,
              flexDirection: 'column',
              borderWidth: 1,
              padding: Metrics.baseMargin,
              marginHorizontal: 0,
              borderColor: Colors.lightGrey,
              marginBottom: Metrics.baseMargin
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Text
                style={[Fonts.style.normal, { fontWeight: 'bold', flex: 1 }]}
              >
                {member.isCompany === TRUE_OPTION
                  ? `${idx}. ${member.companyName} (${
                      member.percentOwnership
                    }%)`
                  : `${idx}. ${member.firstName} ${member.lastName} (${
                      member.percentOwnership
                    }%)`}
              </Text>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa'
                }}
                onPress={this.handleEdit.bind(this, key)}
              >
                <Icon name="md-create" size={20} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa'
                }}
                onPress={this.handleDelete.bind(this, member.id, key)}
              >
                <Icon name="md-trash" size={20} />
              </TouchableOpacity>
            </View>
          </View>
        );
        idx++;
      }
    });

    return members;
  };

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      handleSubmit
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    if (!initilized) return null;

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );
    let totalPercent = 0;
    application[BASE] &&
      application[BASE].forEach(
        ele => (totalPercent += parseFloat(ele.percentOwnership))
      );
    console.log('TCL: render -> totalPercent', totalPercent);

    const percentError = totalPercent !== 100 && (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Icon
          name="md-close-circle"
          size={20}
          style={{
            color: 'red',
            marginHorizontal: Metrics.baseMargin
          }}
        />
        <FormattedMessage
          id={`${BASE}.percentError`}
          style={Fonts.style.normal}
        />
      </View>
    );

    if (
      application &&
      application[BASE] &&
      Object.keys(application[BASE]).length > 0
    ) {
      return (
        <View style={styles.flex}>
          <ScrollView
            ref={scroll => {
              this._scroll = scroll;
            }}
            style={styles.scrollViewContainer}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <Grid>
              <Row>
                <Col>
                  <FormattedMessage
                    id={`${BASE}.title`}
                    style={Fonts.style.title}
                  />
                  {/* <FormattedMessage
                    id={`${BASE}.addAnotherSubtitle`}
                    style={Fonts.style.subtitle}
                  /> */}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {errorView}
                  {this.renderPropertyOwners()}
                  {percentError}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t(`${BASE}.addAnother`)}
                    onPress={this.handleAddMember}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t('form.continueNextStep')}
                    onPress={() => history.push('/LienOnPropertyScreen')} // TenantsScreen
                  />
                </Col>
              </Row>
            </Grid>
            <View style={{ flex: 1 }} />
            <Footer />
          </ScrollView>
        </View>
      );
    }

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="YesNo"
                  labelId={`${BASE}.haveOwnership`}
                  optionsKey="yes_no"
                  reverse
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
          <View style={{ flex: 1 }} />
          <Footer />
        </ScrollView>
      </View>
    );
  }
}

PropertyOwnersScreen = withFormik({
  mapPropsToValues: props => {
    return {
      YesNo:
        props.application[BASE] && props.application[BASE].length > 0
          ? YES_OPTION
          : undefined
    };
  },
  isInitialValid: props => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { application, history, updateApplication } = props;
    if (values.YesNo && values.YesNo === NO_OPTION) {
      // No

      const newApplication = JSON.parse(JSON.stringify(application));

      if (!newApplication[BASE]) {
        newApplication[BASE] = [];
      }
      newApplication[BASE].push({ id: '-1' });
      console.log('handleAddMember', JSON.stringify(newApplication));
      updateApplication(newApplication);
      setStatus('creatingPropertyOwner');
    } else {
      // No
      history.push('/LienOnPropertyScreen'); // TenantsScreen
    }
  }
})(PropertyOwnersScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching
});

const mapDispatchToProps = dispatch => ({
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  deleteHouseholdMember: memberId =>
    dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyOwnersScreen);
